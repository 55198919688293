.container3 {
  padding: 20px;
  padding-bottom: 15px;
  height: auto;
  background: #1222;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-top: 0;
  width: 100%;
  float: left;
  margin-bottom: 1%;
}

.container3 .bx {
  width: 35%;
  float: left;
  margin-top: 1px;
}

.bx input {
  float: left;
}

.container3 .box1 {
  width: 35%;
  float: left;
  margin: 0 auto;
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  padding: 10px 35px;
}

.container3 .box1 h6 {
  top: 306px;
  left: 545px;
  height: auto;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  margin-top: -10px;
  font-weight: 500;
}

.container3 .box1 span {
  color: #828282;
  line-height: 11px;
  font-size: 12px;
  opacity: 0.5;
  margin-top: 7%;
}

.active {
  margin-left: 20px;
  width: 74px;
  height: 21px;
  background: #4AC0B0 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  color: white;
  font-size: 12px;
  border: navajowhite;

}

/* .box h6 {
  top: 306px;
  left: 232px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
}

.box {
  margin-top: -10px;
}

.box span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box p {
  top: 366px;
  margin-top: 10px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.box2 {
  margin-left: 640px;
  margin-top: -60px;

}

.box2 h6 {
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box2 span {
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box2 p {
  margin-top: 12px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.box3 {
  margin-left: 860px;
  margin-top: -100px;
  color: #eb951f;
}

.box3 i {
  padding: 10px;
}

.box3 p {
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  margin-left: 110px;
  margin-top: 30px;
}

.pages {
  margin-top: 10px;
  float: left;
}

.pages p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-left: 140px;
  margin-top: -22px;
}

.pages span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;


}

.pages button {
  margin-left: 20px;
  color: black;
  width: 56px;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.pages1 {

  float: left;
  padding-left: 33px;
}

.pages1 button {
  color: black;
  border-radius: 5px;
  height: 33px;
  width: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.pages1 i {
  padding: 15px;
  /* color: black; */
  /* opacity: 1;
}

.pages1 p {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
} */ */

.footer.user_footer {
  width: 100%;
  float: left;
  margin-left: 0;
}

/* ticket design*/
/* .content1 {
  margin-left: 220px;
  padding-right: 40px;
} */

/* .div1 {
  padding: 20px;
  margin-top: 20px;
  width: 40%;
  height: 100px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

.div1 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div2 {
  margin-top: 10px;
  padding: 20px;
  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div2 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div3 {
  margin-top: 10px;
  padding: 20px;

  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div3 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 300px;
  margin-top: -32px;
  font-weight: 500;
} */

/* .div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
} */

/* ul#menu {

  list-style-type: none;
  margin-top: 0;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
  margin-bottom: 3%;
}

ul#menu li {
  float: left;
}

ul#menu li a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-align: left;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: grey;
  opacity: 1;
  padding-bottom: 14px;
}

ul#menu li a:hover {
  color: #eb951f;
}

ul#menu2 {

  list-style-type: none;
  margin-top: 30px;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;

}

ul#menu2 li {
  float: left;
}

ul#menu2 li a {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: black;
  opacity: 1;

}

ul#menu2 li a:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

.div4 .box p {
  margin-left: 300px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}

.div4 .box .bt button i {
  margin: 5px;
}

.div5 {
  float: left;
}

.div5 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 {
  float: right;
}

.div6 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
}

.div6 p i {
  padding-right: 8px;
}

.div6 img {
  width: 340px;
  height: 280px;
  margin-top: -5px;
} */


/*manage users*/
.list {
  margin-top: 32px;
  /* margin-left: 220px; */
  padding-right: 30px;


}

.list i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
}

.list input {
  height: 34px;
  width: 215px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background: transparent;
  margin-left: -15px;
  padding-left: 3%;
  font-size: 14px;
  font-weight: 500;
}

.list button {
  height: 30px;
  float: right;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  width: 130px;
  font-size: 14px;
}

.colrow.user {
  overflow-y: scroll;
  margin-top: 20px;
  height: 386px;
  overflow-x: hidden;
  width: 100%;
  float: left
}

.colrow.user .row {
  display: inline-block;
  width: 100%;
  /* float: left; */
  margin: 10px 0;
  height: auto;
}

.colrow.user .row .col {
  margin: 0;
  padding: 0;
  width: 20%;
  float: left
}

.colrow.user .row .col:nth-child(2) {
  width: 18%;
}

.manage-user-main .colrow.user .row .col:nth-child(3) {
  width: 30%;
  max-width: 30%;
}

.colrow.user .row .col:nth-child(4) {
  width: 14%;
}

.manage-user-main .colrow.user .row .col:nth-child(5) {
  width: 10%;
}

.colrow.user .row .col p {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  overflow-wrap: break-word;
  padding-right: 20%;
}

.colrow.user .row .col p:nth-child(2) {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5%;
}

.colrow.user .row .col:nth-child(3) p {
  text-transform: inherit;
}

.colrow.user .row .col-sm {
  padding: 0;
}

.colrow .row .col-sm {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
}

.colrow .row .col p {

  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
  padding-top: 32px;
}

.colrow .row .col span {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.colrow .row .col h6 {
  margin-left: 120px;
  color: #eb951f;
  font-size: 14px;
  margin-top: -19px;

}

.colrow .row .col .dropdown {
  margin-top: -40px;
  margin-left: 70px;
  border: none;
}

.dropdown-menu .dropdown-item i {
  padding: 12px;
}

.w-25 {
  background-color: #eee;
  margin-left: 200px;
}

.t1 {
  font-size: 32px;
  font: normal normal medium 32px/43px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 320px;
}

.searchbar {
  border: none;
  margin-top: 32px;
  padding-right: 30px;
}

.searchbar input {
  height: 34px;
  width: 250px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background-color: #eee;
}

.cols {
  font-size: 16px;
  font-weight: 800;
  margin-left: 30px;
  width: 350px;
}

.cols .row .col p {
  font-size: 14px;
  font-weight: 400;
}

.cols .row .col span {
  font-size: 14px;
  font-weight: 100;
}

.right {
  position: absolute;
  margin-top: -170px;
  width: 75%;
  background: white;
  margin-left: -295px;
}

.modc #close_add {
  width: 28%;
  margin-top: 3%;
}

.modc #close_add i {
  color: #fff;
}

.modal-header .close_edit {
  position: relative;
  float: left;
  margin-left: 0;
  width: 50%;
  text-align: center;
  margin-top: 15px;
}

.modal-header .close_edit .fas.fa-times {
  color: #fff;
}

.label {
  margin-top: 30px;
  margin-left: 40px;
}

.label p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.label input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;


}

.labels1 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labels1 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.labels2 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  /* color: #828282; */
  opacity: 1;
}

.labels2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.la2 {
  margin-left: 400px;
  margin-top: -258px;
}

.la2i {
  margin-left: -30px;
}

.labeel2 {
  margin-left: 400px;
  margin-top: -262px;
}

.labeel2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeel2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeel2 ::placeholder {
  color: black;
  background: transparent !important;
}

.la2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.la2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.la2 ::placeholder {
  color: black;
}

.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;

}

.labl1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labl1 i {
  margin-left: -30px;
}

.labl1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl1 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labeels1 i {
  margin-left: -30px;
}

.labeels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels1 select {
  margin-top: -20px;
  width: 300px;
  height: 60px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labl2 {
  margin-left: 400px;
  margin-top: 30px
}

.labl2 i {
  margin-left: -30px;
}

.labl2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl2 select {
  margin-top: -20px;
  width: 300px;
  height: 57x;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels2 ::placeholder {
  color: black;
}

.labeels2 {
  margin-left: 400px;
  margin-top: 30px
}

.labeels2 i {
  margin-left: -30px;
}

.labeels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels2 select {
  margin-top: -20px;
  width: 300px;
  height: 50px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}


.addbtn {
  margin-top: 50px !important;
}

.addbtn button {
  height: 30px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 50px;
  width: 130px;
  font-size: 14px;
}

.assign_dropdown {
  width: 100%;
  float: left;
}

.assign_dropdown .fbox {
  margin-left: 0;
  margin-top: 30px;
  float: left;
}

.assign_dropdown .fbox .dropdown {
  width: 100%;
}

.assign_dropdown .fbox .dropdown,
.assign_dropdown .sbox .dropdown,
.assign_dropdown .rbox1 .dropdown {
  border: none;
}

.fbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown .fbox select {
  width: 100%;
  height: 32px;
  background: #000;
  border: 1px solid #a1a1a1;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
  margin-left: -5px;
  margin-top: 5px;
}

.assign_dropdown .sbox {
  margin-left: 2%;
  margin-top: 30px;
  float: left;
}

.sbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown .sbox select {
  width: 100%;
  height: 32px;
  background: #000 !important;
  border: 1px solid #e6e6e6;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
  font-size: 14px;
  margin-left: -5px;
  margin-top: 5px;
}

.nbox {
  margin-top: -55px;
  margin-left: 680px
}

.nbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.nbox select {
  width: 195px;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #747474;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
  margin-left: -5px;
  margin-top: 5px;
}

.assign_dropdown .rbox1 {
  margin-top: 15px !important;
  margin-left:2%;
  float: left;
}

.rbox1 span {
  text-align: left;
  font: normal normal medium 24px/32px Roboto;
  letter-spacing: 0px;
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

.rbox1 span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown.rbox1 select {
  width: 100%;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

/* .pbox {
  margin-left: 0;
  float: right;
  margin-right: 1%;
  margin-top: 7%;
} */

/* .pbox button {
  color: white;
  background-color: #eb951f;
  border-radius: 20px;
  width: 130px;
  height: 35px;
  border: none;
} */

.modc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 760px;
  margin-left: 53% !important;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  height: 686px !important;
  margin-top: 65px;
  background-color: #121212 !important;
}

.show {
  display: block;
}

.hide {
  display: none;
}

#content_assign,
#content_assign .wrapper {
  width: 100%;
  float: left;
}

#content_assign .counts .c {
  margin-top: 2%;
  padding: 0;
}

#content_assign .counts {
  margin-bottom: 1%;
}

.counts {
  width: 100%;
  float: left;
  margin: 0;
  margin-bottom: 3%;
}

.c2 {
  display: none;
  float: right;
  margin-top: 36px;
  width: 24%;
  text-align: right;
}

.c2 button {

  color: #fff;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
  background: #000;
  width: 18%;
}

.c2 i {
  padding: 9px;
  color: #fff;
  opacity: 1;
}

.c2 p {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin: 0 auto;
}

.c,
.c1 {
  margin-top: 40px;
  width: 25%;
  float: left;
  padding-left: 0.5%;
}

.c {
  display: none;
}

.c input {
  width: 18px;
  height: 18px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  opacity: 1;
}

.c p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: -23px;
  margin-left: 11%;
}

.c1 button {

  color: #eb951f;

  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.c1 p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: -20px;
  margin-left: 64px;
}

.bx h6 {
  float: left;
  color: #fff;
  margin-left: 17px;
  margin-top: -2px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
}

.bx {
  margin-top: -10px;
}

.bx span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.bx p {
  top: 366px;
  margin-top: 15px !important;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-bottom: 0;
  float: left;
  width: 100%;
}

.row {
  margin-right: 0px !important;
}

.customrow {
  border-bottom: 1px solid #595959;
  width: 98%;
  height: 50px;
}

.a {
  color: #828282 !important;
}

#root .container3 .bx span {
  color: #fff;
  font-weight: 500;
  float: left;
  line-height: 25px;
  width: 100%;
  float: left;
}

.menucolor2 {
  background: #292013;
  color: #eb951f !important;
}

.menucolor2 .fa-exclamation-circle,
.menucolor2 span {
  color: #eb951f !important;
}

.dropbtn1111 {
  width: 100%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.errorpd {
  padding-left: 5px;
}

.dropdown-wrap .errorShow {
  margin-top: 4%;
  margin-bottom: 0;
}

.more8 button {
  color: #FFFFFF;
  text-align: center;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  background-color: #eb951f;
  border-radius: 20px;
  width: 162px;
  height: 35px;
  border: none;
  margin-top: 50px;
  margin-left: 30px;
}

.mg-left {
  margin-left: 25px !important;
  margin-right: 25px !important;
  margin-bottom: 25px !important;
}

.lableClass {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  width: 100%;
  float: left;
}

.modal-header {
  border-bottom: none !important
}

.fas fa-times {
  color: #fff !important;
}

.form-control {
  border-radius: 0px !important;
  background-color: #121212 !important;
  color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
}

.form-row.mg-left {
  padding: 0 5%;
  margin: 0 auto !important;
  padding: 6px 0;
  width: 88%;
}

.form-row.mg-left .mb-4,
.my-4 {
  max-width: 38%;
  width: 43%;
}

.form-row.mg-left .mb-4:nth-child(even),
.form-row.mg-left .my-4:nth-child(even) {
  padding-left: 7%;
}

.add_user.form-row.mg-left .mb-4:nth-child(2) input {
  margin-left: 0;
}

.dropdown.user_dropdown .dropdown-menu {
  min-width: 9rem !important;
  background-color: #000 !important;
  transform: translate3d(-17%, -55%, 5px) !important;
  top: 0px;
  left: 25% !important;
}

.dropdown-menu .dropdown-item {
  padding: 0% 0%;
  color: #fff;
}
.dropdown-menu .dropdown-item:hover {
  padding: 0% 0%;
  color: rgb(0, 0, 0) !important;
}

.form-row.mg-left.add_user .col-md-6.mb-4 input {
  width: 100%;
  margin-left: 0;
}

.form-row.mg-left.add_user .col-md-6.mb-4 p.company_name {
  height: auto;
  border-bottom: 1px solid #ccc;
  text-align: left;
  letter-spacing: 0px;
  color: #828282;
  font-size: 9px;
  padding-bottom: 13px;
  transform: translateY(7px);
  opacity: 1;
}

.form-row.mg-left.add_user .addbtn button {
  margin-left: 6%;
}

.form-control:focus {
  box-shadow: none !important;
}

#viewModal {
  background: rgba(0, 0, 0, 0.5);
}

.fade:not(.show) {
  opacity: 1 !important;
}

.manage-user-main .colrow .row.customrow:first-child .col-sm {
  width: 20%;
  max-width: 20%;
}

.manage-user-main .colrow .row.customrow:first-child .col-sm:nth-child(3) {
  width: 30%;
  max-width: 30%;
}

.manage-user-main .colrow .row.customrow .col {
  width: 20%;
  max-width: 20%;
}

.manage-user-main .colrow .row.customrow .col .dropdown .btn-link {
  padding-right: 20px;
}

.manage-user-main .list input {
  outline: none;
  box-shadow: none;
}

/* .manage-user-main .list .add-user-btn,
.assign-user-component .pbox .assign-manage-btn {
  width: 149px;
  height: 45px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  outline: none;
} */

.manage-user-main .colrow.user {
  height: 350px;
}

.list-btn-wrap {
  display: inline-block;
}

.list-btn-wrap .add-user-btn-input {
  background: transparent;
  border-bottom: 1px solid grey;
  background: transparent;
  border-radius: 0;
  height: 34px;
  width: 50px;
  outline: none;
}

.list-btn-wrap #user_search {
  margin-left: 0px;
  padding-left: 0;
  margin-right: 0;

}

.list-btn-wrap #user_search::placeholder{
  color:wheat

}

/* .list-btn-wrap #user_search:focus{
  border-bottom: 1px solid grey;
} */

.assign-user-component .modf1 {
  position: fixed;
  left: 70%;
  top: 4em;
}

.modf1 {
  background: #121212 !important;
  position: relative;
  display: inline-block;
  /* float: left; */
  width: 37%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
  height: 700px;
  margin: 0;
  /* float: right; */
}

.manage-user-main .modal-dialog {
  position: fixed;
  left: 80%;
  top: 9%;
  margin: 0;
}

.manage-user-main .modal-dialog .modc {
  width: 650px;
  margin-top: 0;
}

.manage-user-main .modal-dialog .modc #close_add {
  width: 5%;
  margin-top: 3%;
  margin-right: 5%;
}

.manage-user-main .modal-dialog .modc .form-row.mg-left .mb-4 {
  max-width: 48%;
}

.assign-user-component .modal-dialog .modf1 {
  position: fixed;
  margin-top: 0;
  left: 70%;
  height: 600px;
}

#exampleModal {
  background: rgba(0, 0, 0, 0.5);
}

#content_assign .container3 .box1 h6 {
  margin-top: 10px;
}

#root .modc .modal-header.edit_user {
  width: 88%;
  margin: 0 auto;
  padding: 1rem 0;
}

#root .modc .modal-header.edit_user .list2 {
  width: 43%;
  max-width: 48%;
  margin: 0;
}

#root .modc .modal-header.edit_user .close_edit {
  width: 43%;
  margin: 0 auto;
  max-width: 48%;
  text-align: right;

  padding-left: 7%;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  line-height: 22px !important;
  height: 22px !important;
}

.rs-picker input.rs-picker-toggle-textbox {
  width: 100%;
  margin: 0 auto;
}

#content_assign .wrapper .container3 .box1 h6 {
  margin: 0;
  font-size: 14px;
  margin-bottom: 15px;
}

#content_assign .wrapper .container3 .box1 span {
  display: block;
  font-size: 12px !important;
  font-weight: 400;
  line-height: inherit;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  height: auto;
}

.footer.user_footer .pages1 {
  padding-left: 0;
}

.assign_dropdown .dropdown {
  background: none;
}




/* Select search css */
.assign-user-component .select-user-dropdown,
.assign-user-component .dropdown-wrap-problem {
  max-height: 32px;
  width: 80%;
  
}

.assign-user-component .css-1s2u09g-control,
.assign-user-component .css-1pahdxg-control,
.assign-user-component .css-1s2u09g-control,
.assign-user-component .css-1pahdxg-control {
  width: 100%;
  height: 32px;
  padding: 0 5px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  max-height: 32px;
  min-height: 32px;
}

.assign-user-component .css-1s2u09g-control:hover,
.assign-user-component .css-1pahdxg-control:hover,
.assign-user-component .css-1s2u09g-control:hover,
.assign-user-component .css-1pahdxg-control:hover {
  border: 1px solid #595959;
}

.assign-user-component .css-1s2u09g-control .css-319lph-ValueContainer,
.assign-user-component .css-319lph-ValueContainer {
  padding: 0 8px;
}

.assign-user-component .css-1s2u09g-control .css-319lph-ValueContainer .css-14el2xx-placeholder,
.assign-user-component .css-14el2xx-placeholder {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.assign-user-component .css-1pndypt-Input,
.assign-user-component .css-ackcql {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.assign-user-component .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.assign-user-component .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.assign-user-component .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.assign-user-component .css-1s2u09g-control .css-319lph-ValueContainer .css-6j8wv5-Input,
.assign-user-component .css-6j8wv5-Input {
  margin: 0;
}

.assign-user-component .css-1pahdxg-control .css-319lph-ValueContainer .css-6j8wv5-Input {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.assign-user-component .css-1pahdxg-control,
.content-leaderboard .css-1pahdxg-control {
  box-shadow: none;
}

.content-leaderboard .css-6j8wv5-Input,
.assign-user-component .css-6j8wv5-Input {
  color: #fff;
  font-weight: 500;
}

.assign-user-component .css-319lph-ValueContainer {
  padding: 0 8px;
}

.assign-user-component .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .right-grid  .dropdown-wrap-courier .css-tlfecz-indicatorContainer{
  height: 28px;
  color: #fff;
} */
.assign-user-component .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.assign-user-component .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .css-ackcql{
  grid-area: none !important;
} */
.assign-user-component .css-6j8wv5-Input input,
.assign-user-component .css-6j8wv5-Input input {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;

}

.assign-user-component .css-319lph-ValueContainer .css-14el2xx-placeholder,
.assign-user-component .css-14el2xx-placeholder {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
  font-size: 14px;

}

.assign-user-component .css-26l3qy-menu,
.assign-user-component .css-26l3qy-menu {
  background: #000;
  color: #fff;
  border-radius: 10px;
}

.assign-user-component .css-1gtu0rj-indicatorContainer {
  color: #ffffff !important;
}

.assign-user-component .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
.assign-user-component .css-1n7v3ny-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
  background: #FA9F221A 0% 0% no-repeat padding-box;
  color: #FA9F22;
}

.assign-user-component .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:active,
.assign-user-component .css-1n7v3ny-option:active {
  background: #FA9F221A 0% 0% no-repeat padding-box;
}

.assign-user-component .css-yt9ioa-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
}

.css-1gtu0rj-indicatorContainer svg {
  color: #fff;
}

.assign-user-component .loader {
  position: inherit;
  left: 35%;
  /* margin-top: 40%; */
  margin-top: 7%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.css-vwja0k {
  color: #FFFFFF !important;
}

.assign-user-component .css-qc6sy-singleValue {
  color: #FFFFFF !important;

}
.assign-user-component #content_assign .wrapper{
  border: none;
}
.list  input::placeholder {
  color: #fff !important;
  background: none !important;
}

.rbox1 .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(255, 255, 255) !important;
}

.selectProductDropdown ::placeholder {
  color: rgb(136, 136, 136) !important;
  margin-top: 2px !important;
  
}
