.search input::placeholder {
  color: #fff !important;
  background: none !important;
}
.refundAmm .modalHeader {
  text-align: center;
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #000;
  width: 100%;
  float: left;
  padding-bottom: 10px;
  color: #fff;

}

.refundAmm .modalHeader #rModal_head {
  color: #000;
  font-size: 18px !important;
  text-align: center;
  padding: 0;
  float: none;
}

.refundAmm .modalHeader #close_rModal {
  position: absolute;
  cursor: pointer;
  top: 40%;
  right: 10px;
  background: #fff;
}

.refundAmm .content {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 13px;
  border: none;
}

.refundAmm .modalHeader #close_rModal .fa-times {
  color: #000;
}

#InputrefundAmm::placeholder {
  background: #fff !important;
  color: #ccc !important;
}

#InputrefundAmm {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  text-indent: 0;
  width: 90%;
  float: left;
  margin: 20px 5%;
}

.refundAmm .otp-btn-wrapper {
  width: 50%;
  float: left;
  padding: 0 10%;
}

.refundAmm .rended-otp-button {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
  box-shadow: none;
  margin: 0;
}

.refund .modalHeader {

  background-color: #eb951f;
  text-align: center;
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #000;
  width: 100%;
  float: left;
  padding-bottom: 10px;
  color: #fff;

}

.refund .modalHeader #rModal_head {
  color: #000;
  font-size: 18px !important;
  text-align: center;
  padding: 0;
  float: none;
}

.refund .modalHeader #close_rModal {
  position: absolute;
  cursor: pointer;
  top: 40%;
  right: 10px;
  background: #fff;
}

.refund .content {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 13px;
  border: none;
}

.refund .modalHeader #close_rModal .fa-times {
  color: #000;
}

#inputAmount::placeholder,
#inputRefundNote::placeholder {
  background: #fff !important;
  color: #ccc !important;
}

#inputAmount,
#inputRefundNote {
  background: transparent;
  /* border: none; */
  border-style: solid;
  border-width: 2px;
  height: 33px;
  border-bottom: 1px solid #ccc;
  text-indent: 0;
  width: 90%;
  float: left;
  margin-top: 35px;
  margin-right: 5%;
  margin-bottom: 10px;
  margin-left: 5%;
}

.refund .otp-btn-wrapper {
  width: 50%;
  float: left;
  padding: 0 10%;
}

.refund .rended-otp-button {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
  box-shadow: none;
  margin: 0;
}

.refund #send_rModal {
  background-color: #eb951f;
  color: white;
  border-radius: 70px;
  border: none;
  height: 40px;
  width: 200px;
  margin-top: 20px;
  margin-left: 100px;
}

.financeApproved {
  float: right;
  font-weight: 500;
  margin-bottom: 2%;
  width: 28%;
  margin-top: 0.5%;
}

.financeApproved .transferButton {
  float: right;
  width: 46%;
}

.financeApproved .transferButton:nth-child(2) {
  margin-right: 5%;
}

.selectInput {
  float: left;
}

#downloadButton {
  background: #000;
  color: #fff;
  height: 34px;
  border: 1px solid gray;
  border-radius: 100px;
  opacity: 1;
  margin-left: 0;
  width: 11%;
  font-size: 14px;
  float: left;
  margin-top: 54px;
}

.list input::placeholder {
  color: #fff;
  background: none;
}

.gcNew_wrapper{
  width:100%;
  float:left;
  padding-top:8%
}