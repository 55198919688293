.container3 {
  padding: 20px;
  padding-bottom: 15px;
  height: auto;
  background: #1222;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-top: 0;
  width: 100%;
  float: left;
  margin-bottom: 1%;
}

.container3 .bx {
  width: 35%;
  float: left;
  margin-top: 1px;
}

.bx input {
  float: left;
}

.container3 .box1 {
  width: 35%;
  float: left;
  margin: 0 auto;
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  padding: 10px 35px;
}

.container3 .box1 h6 {
  top: 306px;
  left: 545px;
  height: auto;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  margin-top: -10px;
  font-weight: 500;
}

.container3 .box1 span {
  color: #828282;
  line-height: 11px;
  font-size: 12px;
  opacity: 0.5;
  margin-top: 7%;
}

.pages {
  margin-top: 10px;
  float: left;
}

.pages p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-left: 140px;
  margin-top: -22px;
}

.pages span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;


}

.pages button {
  margin-left: 20px;
  color: black;
  width: 56px;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.pages1 {

  float: left;
  padding-left: 33px;
}

.pages1 button {
  color: black;
  border-radius: 5px;
  height: 33px;
  width: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.pages1 i {
  padding: 15px;
  /* color: black; */
  opacity: 1;
}

.pages1 p {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.footer.user_footer {
  width: 100%;
  float: left;
  margin-left: 0;
}

/* ticket design*/
.content1 {
  margin-left: 220px;
  padding-right: 40px;
}

.div1 {
  padding: 20px;
  margin-top: 20px;
  width: 40%;
  height: 100px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

.div1 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div2 {
  margin-top: 10px;
  padding: 20px;
  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div2 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div3 {
  margin-top: 10px;
  padding: 20px;

  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div3 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 300px;
  margin-top: -32px;
  font-weight: 500;
}

.div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

ul#menu {

  list-style-type: none;
  margin-top: 0;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
  margin-bottom: 3%;

}

ul#menu li {
  float: left;
}

ul#menu li a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-align: left;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: grey;
  opacity: 1;
  padding-bottom: 14px;
}

ul#menu li a:hover {
  color: #eb951f;
}

ul#menu2 {

  list-style-type: none;
  margin-top: 30px;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;

}

ul#menu2 li {
  float: left;
}

ul#menu2 li a {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: black;
  opacity: 1;

}

ul#menu2 li a:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

.div4 .box p {
  margin-left: 300px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}

.div4 .box .bt button i {
  margin: 5px;
}

.div5 {
  float: left;
}

.div5 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 {
  float: right;
}

.div6 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
}

.div6 p i {
  padding-right: 8px;
}

.div6 img,
.div5.p_image img {
  width: 100%;
  height: auto;
  float: left;
  margin-top: -5px;
}

/*manage users*/
.list {
  margin-top: 32px;
  /* margin-left: 220px; */
  padding-right: 30px;


}

.list i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
}

.list input {
  height: 34px;
  width: 215px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background: transparent;
  margin-left: -15px;
  padding-left: 3%;
  font-size: 14px;
  font-weight: 500;
}

.list button {
  height: 30px;
  float: right;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  width: 130px;
  font-size: 14px;
}

.colrow.user {
  overflow-y: scroll;
  margin-top: 20px;
  height: 386px !important;
  overflow-x: hidden;
  width: 100%;
  float: left
}

.colrow.user .row {
  display: inline-block;
  width: 100%;
  /* float: left; */
  margin: 10px 0;
  height: auto;
}

.colrow.user .row .col {
  margin: 0;
  padding: 0;
  width: 20%;
  float: left
}

.colrow.user .row .col:nth-child(2) {
  width: 18%;
}

.colrow.user .row .col:nth-child(3) {
  width: 28%;
}

.colrow.user .row .col:nth-child(4) {
  width: 14%;
}

.colrow.user .row .col:nth-child(5) {
  width: 15%;
}

.colrow.user .row .col p {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  overflow-wrap: break-word;
  padding-right: 20%;
}

.colrow.user .row .col p:nth-child(2) {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5%;
}

.colrow.user .row .col:nth-child(3) p {
  text-transform: inherit;
}

.colrow.user .row .col-sm {
  padding: 0;
}

.colrow .row .col-sm {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}

.colrow .row .col p {

  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
  padding-top: 32px;
}

.colrow .row .col span {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.colrow .row .col h6 {
  margin-left: 120px;
  color: #eb951f;
  font-size: 14px;
  margin-top: -19px;

}

.colrow .row .col .dropdown {
  margin-top: -40px;
  margin-left: 70px;
  border: none;
}

.service-center-wrapper .list-row .dropdown-menu.menuServiceCenter {
  position: absolute !important;
  transform: none !important;
  top: -68px !important;
  left: -122px !important;
  background: #000 !important;
}

.service-center-wrapper .list-row .dropdown-menu.menuServiceCenter a {
  color: #fff;
}

.service-center-wrapper .list-row .dropdown-menu.menuServiceCenter a:hover {
  background: #fff;
  color: #000;
  cursor: pointer;
}

.dropdown-menu .dropdown-item i {
  padding: 12px;
}

.dropdown-menu .dropdown-item i.fa-repeat {
  padding-left: 0;
  padding-right: 17px;
}

.t1 {
  font-size: 32px;
  font: normal normal medium 32px/43px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 320px;
}

.searchbar {
  border: none;
  margin-top: 32px;
  padding-right: 30px;
}

.searchbar input {
  height: 34px;
  width: 250px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background-color: #eee;
}

.cols {
  font-size: 16px;
  font-weight: 800;
  margin-left: 30px;
  width: 350px;
}

.cols .row .col p {
  font-size: 14px;
  font-weight: 400;
}

.cols .row .col span {
  font-size: 14px;
  font-weight: 100;
}

.right {
  position: absolute;
  margin-top: -170px;
  width: 75%;
  background: white;
  margin-left: -295px;
}

.modc #close_add {
  width: 28%;
  margin-top: 3%;
}

.modc #close_add i {
  color: #fff;
}

.modal-header .close_edit {
  position: relative;
  float: left;
  margin-left: 0;
  width: 50%;
  text-align: center;
  margin-top: 15px;
}

.modal-header .close_edit .fas.fa-times {
  color: #fff;
}

.label {
  margin-top: 30px;
  margin-left: 40px;
}

.label p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.label input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;


}

.labels1 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labels1 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.labels2 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  /* color: #828282; */
  opacity: 1;
}

.labels2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.la2 {
  margin-left: 400px;
  margin-top: -258px;
}

.la2i {
  margin-left: -30px;
}

.labeel2 {
  margin-left: 400px;
  margin-top: -262px;
}

.labeel2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeel2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeel2 ::placeholder {
  color: black;
}

.la2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.la2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.la2 ::placeholder {
  color: black;
}

.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;

}

.labl1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labl1 i {
  margin-left: -30px;
}

.labl1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl1 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labeels1 i {
  margin-left: -30px;
}

.labeels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels1 select {
  margin-top: -20px;
  width: 300px;
  height: 60px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labl2 {
  margin-left: 400px;
  margin-top: 30px
}

.labl2 i {
  margin-left: -30px;
}

.labl2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl2 select {
  margin-top: -20px;
  width: 300px;
  height: 57x;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels2 ::placeholder {
  color: black;
}

.labeels2 {
  margin-left: 400px;
  margin-top: 30px
}

.labeels2 i {
  margin-left: -30px;
}

.labeels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels2 select {
  margin-top: -20px;
  width: 300px;
  height: 50px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}


.addbtn {
  margin-top: 50px !important;
}

.addbtn button {
  height: 30px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 50px;
  width: 130px;
  font-size: 14px;
  font-weight: 500;
}

.assign_dropdown {
  width: 100%;
  float: left;
}

.assign_dropdown .fbox {
  margin-left: 0;
  margin-top: 30px;
  float: left;
}

.assign_dropdown .fbox .dropdown {
  width: 100%;
}

.assign_dropdown .fbox .dropdown,
.assign_dropdown .sbox .dropdown,
.assign_dropdown .rbox .dropdown {
  border: none;
}

.fbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown .fbox select {
  width: 100%;
  height: 32px;
  background: #000;
  border: 1px solid #8d8d8d;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.assign_dropdown .sbox {
  margin-left: 2%;
  margin-top: 30px;
  float: left;
}

.sbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown .sbox select {
  width: 100%;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #6b6b6b;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
  font-size: 14px;
}

.nbox {
  margin-top: -55px;
  margin-left: 680px
}

.nbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.nbox select {
  width: 195px;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.assign_dropdown .rbox {
  margin-top: 30px !important;
  margin-left: 5%;
  float: left;
}

.rbox span {
  text-align: left;
  font: normal normal medium 24px/32px Roboto;
  letter-spacing: 0px;
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

.rbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.assign_dropdown.rbox select {
  width: 100%;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #858585;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.pbox {
  margin-left: 0;
  float: right;
  margin-right: 1%;
  margin-top: 4%;
}

.pbox button {
  color: white;
  background-color: #eb951f;
  border-radius: 20px;
  width: 130px;
  height: 35px;
  border: none;
}

.modc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 760px;
  margin-left: 53% !important;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  height: 686px !important;
  margin-top: 65px;
  background-color: #121212 !important;
}

.show {
  display: block;
}

.hide {
  display: none;
}

#content_assign,
#content_assign .wrapper,
.download-wrapper {
  width: 100%;
  float: left;
}

.counts {
  width: 100%;
  float: left;
  margin: 0;
  margin-bottom: 3%;
}

.c2 {
  display: none;
  float: right;
  margin-top: 36px;
  width: 24%;
  text-align: right;
}

.c2 button {

  color: #fff;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
  background: #000;
  width: 18%;
}

.c2 i {
  padding: 9px;
  color: #fff;
  opacity: 1;
}

.c2 p {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin: 0 auto;
}

.c,
.c1 {
  margin-top: 40px;
  width: 25%;
  float: left;
  padding-left: 0.5%;
}

.c {
  display: none;
}

.c input {
  width: 18px;
  height: 18px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  opacity: 1;
}

.c p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: -23px;
  margin-left: 11%;
}

.c1 button {

  color: #eb951f;

  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.c1 p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: -20px;
  margin-left: 64px;
}

.bx h6 {
  float: left;
  color: #fff;
  margin-left: 17px;
  margin-top: -2px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
}

.bx {
  margin-top: -10px;
}

.bx span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.bx p {
  top: 366px;
  margin-top: -5px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-bottom: 0;
  float: left;
  width: 100%;
}

.row {
  margin-right: 0px !important;
}

.customrow {
  border-bottom: 1px solid #595959;
  width: 98%;
  height: 50px;
}

.a {
  color: #828282 !important;
}

#root .container3 .bx span {
  color: #fff;
  font-weight: 500;
  float: left;
  line-height: 25px;
  width: 100%;
  float: left;
}

.menucolor2 {
  background: #292013;
  color: #eb951f !important;
}

.menucolor2 .fa-exclamation-circle,
.menucolor2 span {
  color: #eb951f !important;
}

.dropbtn1111 {
  width: 100%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.errorpd {
  padding-left: 5px;
}

.errorpd.accessLevel {
  margin-top: 5%;
}

.errorpd.accessLevel .errorShow {
  line-height: 18px;
}

.errorShow {
  font-size: 12px !important;
  color: red !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.edit_profile_modal .errorShow {
  filter: invert(1)
}

.dropdown-wrap .errorShow {
  margin-top: 4%;
  margin-bottom: 0;
}

.more8 button {
  color: #FFFFFF;
  text-align: center;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  background-color: #eb951f;
  border-radius: 20px;
  width: 162px;
  height: 35px;
  border: none;
  margin-top: 50px;
  margin-left: 30px;
}

.mg-left {
  margin-left: 25px !important;
  margin-right: 25px !important;
  margin-bottom: 25px !important;
}

.lableClass {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  width: 100%;
  float: left;
}

.modal-header {
  border-bottom: none !important
}

.fas fa-times {
  color: #fff !important;
}

.form-control {
  border-radius: 0px !important;
  background-color: #121212 !important;
  color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 0px 0px 5px 5px !important;
}

.form-row.mg-left {
  padding: 0 5%;
  margin: 0 auto !important;
  padding: 6px 0;
  width: 88%;
}

.form-row.mg-left .mb-4,
.my-4 {
  max-width: 38%;
  width: 43%;
}

.form-row.mg-left .mb-4:nth-child(even),
.form-row.mg-left .my-4:nth-child(even) {
  padding-left: 7%;
}

.add_user.form-row.mg-left .mb-4:nth-child(2) input {
  margin-left: 0;
}

.dropdown.user_dropdown .dropdown-menu {
  min-width: 9rem !important;
  background-color: #000 !important;
  transform: translate3d(-17%, -55%, 5px) !important;
  top: 0px;
  left: 25% !important;
}

.dropdown-menu .dropdown-item {
  padding: 0% 0%;
  color: #fff 
}
.dropdown-menu .dropdown-item:hover {
  padding: 0% 0%;
  color: rgb(0, 0, 0);
}

.form-row.mg-left.add_user .col-md-6.mb-4 input {
  width: 100%;
  margin-left: 0;
}

.form-row.mg-left.add_user .col-md-6.mb-4 p.company_name {
  height: auto;
  border-bottom: 1px solid #ccc;
  text-align: left;
  letter-spacing: 0px;
  color: #828282;
  font-size: 9px;
  padding-bottom: 13px;
  transform: translateY(7px);
  opacity: 1;
}

.form-row.mg-left.add_user .addbtn button {
  margin-left: 6%;
}

.form-control:focus {
  box-shadow: none !important;
}

#viewModal {
  background: rgba(0, 0, 0, 0.5);
}

.fade:not(.show) {
  opacity: 1 !important;
}

.manage-user-main .colrow .row.customrow:first-child .col-sm {
  width: 20%;
  max-width: 20%;
}

.manage-user-main .colrow .row.customrow .col {
  width: 20%;
  max-width: 20%;
}

.manage-user-main .colrow .row.customrow .col .dropdown .btn-link {
  padding-right: 20px;
}

.manage-user-main .list input {
  outline: none;
  box-shadow: none;
}

.manage-user-main .list .add-user-btn,
.assign-user-component .pbox .assign-manage-btn {
  width: 149px;
  height: 45px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  outline: none;
}

.manage-user-main .colrow.user {
  height: 350px;
}

.list-btn-wrap {
  display: inline-block;
}

.list-btn-wrap .add-user-btn-input {
  background: transparent;
  border-bottom: 1px solid grey;
  background: transparent;
  border-radius: 0;
  height: 34px;
  width: 50px;
  outline: none;
}

.list-btn-wrap #user_search {
  margin-left: 0px;
  padding-left: 0;
  margin-right: 0;

}

/* .list-btn-wrap #user_search:focus{
  border-bottom: 1px solid grey;
} */
.edit {
  float: left;
}

.edit .label {
  color: #ccc;
  margin-left: 0;
  margin: 0 auto;
  margin-bottom: 0;
  font-size: 13px;
}

.edit .label_password.label {
  float: left;
}

.edit .change {
  float: left;
  color: #eb951f !important;
  line-height: 20px;
  font-size: 11px !important;
  margin-left: 4%;
  height: auto;
}

.edit .change:hover {
  cursor: pointer;
}

.edit .user_info {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  margin: 3px 0 10%;
  float: left;
}

.edit-user-btn {
  background: transparent;
  color: #eb951f;
  border: 1px solid #eb951f;
  width: 35%;
  padding: 2% 0;
  border-radius: 40px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
}

#edit_userInfo {
  text-align: right;
  padding-right: 0;
}

#notification {
  width: 40%;
  float: right;
  background: #000;
  right: 0;
  margin-left: 60%;
  margin-top: 5%;
  padding: 0 3%;
}

#notification p {
  color: #fff;
  font-size: 26px !important;
  font-weight: 500;
  float: left;
}

#notification .closeModal {
  width: 6%;
  margin-top: 2%;
  float: right;
  text-align: center;
  margin-right: 6%;
}

#notification .active {
  color: #eb951f;
}

.password_wrapper,
.password_wrapper .div,
.password_wrapper .div .div20 label {
  width: 100%;
  float: left;
}

.password_wrapper .div label {
  color: #828282;
  font: normal normal normal 10px/13px Roboto;
  height: auto;
  line-height: inherit;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.password_wrapper .div20 label {
  color: #828282;
  font: normal normal normal 10px/13px Roboto;
  height: auto;
  line-height: inherit;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.password_wrapper .div .input_password {
  width: 65%;
  font: normal normal normal 14px/19px Roboto;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 1%;
  padding-left: 0;
}

.password_wrapper .div20 .input_password {
  width: 65%;
  font: normal normal normal 14px/19px Roboto;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 1%;
  padding-left: 0;
}

.password_wrapper #rModal_head {
  margin-bottom: 10%;
}

.password_wrapper .div {
  margin-bottom: 10%;
}

.password_wrapper #send_rModal {
  margin-top: 40%;
}

.password_wrapper .div label sup {
  color: red;
}

.password_wrapper .password-icon {
  color: #fff;
  margin-left: -4%;
}

.colrow.user.profile_wrapper {
  height: 500px;
  margin-top: 0% !important;
}

.service-center-wrapper {
  width: 100%;
  float: left;
  margin-top: 0;
}

.service-center-wrapper .service-center {
  width: 100%;
  float: left;
  padding: 0;
  height: 450px
}

.service-center-wrapper .service-center .header-row .col-sm,
.service-center-wrapper .service-center .list-row .col-sm {
  padding-left: 15px;
}

.service-center-wrapper .service-center .list-row .col-sm {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  overflow-wrap: break-word;
}

.service-center-wrapper .service-center .header-row .col-sm:first-child,
.service-center-wrapper .service-center .list-row .col-sm:first-child {
  width: 15%;
  float: left;
}

.service-center-wrapper .service-center .header-row .col-sm:nth-child(2),
.service-center-wrapper .service-center .list-row .col-sm:nth-child(2) {
  width: 25%;
  min-width: 25%;
  float: left;
}

.service-center-wrapper .service-center .header-row .col-sm:nth-child(3),
.service-center-wrapper .service-center .list-row .col-sm:nth-child(3) {
  width: 15%;
  float: left;
}

.service-center-wrapper .service-center .header-row .col-sm:nth-child(4),
.service-center-wrapper .service-center .header-row .col-sm:nth-child(5),
.service-center-wrapper .service-center .list-row .col-sm:nth-child(4),
.service-center-wrapper .service-center .list-row .col-sm:nth-child(5) {
  width: 20%;
  float: left;
}

.service-center-wrapper .service-center .header-row .col-sm:nth-child(6),
.service-center-wrapper .service-center .list-row .col-sm:nth-child(6) {
  width: 10%;
  float: left;
  position: relative;
}

.colrow.service-center .list-row,
.colrow.service-center .header-row {
  display: inline-block;
  width: 100%;
  /* float: left; */
  border-bottom: 1px solid grey;
  padding-top: 1%;
}

.service-center-wrapper .service-center .list-row .col-sm p {
  margin: 0 !important;
}

.service-center-wrapper .service-center .list-row .col-sm #dropdownMenuButton,
.service-center-wrapper .service-center .list-row .icon_wrapper2 #dropdownMenuButton1 {
  padding: 0;
}

.service-center-wrapper .service-center .list-row .col-sm #dropdownMenuButtonCall {
  margin-right: 15%;

}

.dropdownCall {
  display: none;
  top: -52px;
  position: absolute;
  background: #000;
  left: -109px;
  padding: 10px 0;

}

.dropdownCall.show {
  display: block
}

.service-center-wrapper .service-center .list-row .col-sm.icon_wrapper {
  padding: 0;
  float: left;
  width: 3%;
}

.service-center-wrapper .service-center .list-row .col-sm.icon_wrapper2 .menuServiceCenter {
  padding: 0.5rem 16px;
}

.service-center-wrapper .service-center .list-row .col-sm.icon_wrapper2 {
  margin-right: 2%;
}

.service-center-wrapper .service-center .list-row .col-sm.icon_wrapper2 a {
  font-size: 12px;
  margin-bottom: 7px;
}

.modal-header .Et.service_center span {
  padding-left: 10px;
}

.service_center.dropbtn1111 {
  background: #000 !important;
}

#updateServiceCenter {
  width: 32%;
  height: 45px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  margin-left: 5%;
  margin-top: 7%;
  margin-bottom: 3%;
}

.edit_profile_modal .div:first-child {
  width: 110%;
  margin-bottom: 5%;
}

.edit_profile_modal .div {
  width: 42%;
  float: left;
  margin-right: 8%;
  margin-bottom: 5%;
}

.edit_profile_modal .div label {
  display: inline-block;
  color: #828282;
  font-size: 12px;
  margin-bottom: 0;
  height: auto;
}

.edit_profile_modal .divc input {
  width: 100%;
  height: 40px;
  background-color: #000 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.edit_profile_modal .divc {
  width: 54%;
  float: left;
  margin-right: 8%;
  margin-bottom: 5%;
}

.edit_profile_modal .divc label {
  display: inline-block;
  color: #828282;
  font-size: 12px;
  margin-bottom: 0;
  height: auto;
}

.edit_profile_modal .div20 label {
  /* display: inline-block; */
  color: #828282;
  font-size: 12px;
  margin-bottom: 0;
  height: auto;
  width: 100%;
  float: left;
}

.edit_profile_modal .div input {
  width: 100%;
  height: 40px;
  background-color: #000 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.edit_profile_modal .div20 input {
  width: 100%;
  height: 40px;
  background-color: #000 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.edit_profile_modal .div:last-child {
  width: 100%;
}

.edit_profile_modal .div:last-child {
  width: 100%;
}

.edit_profile_modal .addbtn {
  float: left;
  position: fixed;
}

.edit_profile_modal .addbtn button {
  height: 35px;
  margin: 0;
  margin-top: -30%;
  color: #000;
}

.info_wrapper .lbl input {
  padding-bottom: 1%;
  }


.info_wrapper .wrapper {
  width: 100%;
  float: left;
}

.pagination_center {
  padding: 0;
  margin-top: 3%;
}

.info_wrapper.service_center {
  width: 90%;
  float: left;
  margin: 0 auto;
}

.info_wrapper.service_center .wrapper {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.info_wrapper.service_center .lbl.adress {
  padding-left: 5%;
  width: 94% !important;
  margin-bottom: 6%;
}

.info_wrapper.service_center .wrapper .lbl {
  width: 45% !important;
  float: left;
  margin-bottom: 4%;
}

.info_wrapper.service_center .wrapper .lbl .dropbtn1111 {
  width: 100%
}

.report_download {
  width: 30% !important;
  float: left;
  padding: 10px;
  border: 1px solid white;
  padding-left: 1%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 2.5%;
}


.report_download.downloadReport {
  padding-left: 0;
  margin-top: 0%;

}

.report_download .edit-user-btn {
  padding: 0% 0 0 1%;
  width: 60%;
  height: 43px;
  margin-left: 20%;
}


.report_download .edit-user-btn mtext {
  margin-top: 2%;
}

.setBorder {
  height: 165px;

}

.readonlySidebar {
  width: 100%;
  float: left;
}

.readonly-user-main.content {
  margin-top: 0
}

.readOnly-dashboard {
  width: 16%;
  float: left;
  text-align: right;
  padding-right: 2%;
  margin-top: 1.8%
}

.readOnly-dashboard a {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

.readOnly-dashboard a:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}

.fa-angle-left {
  text-decoration: underline;
}

.removeValue {
  color: transparent !important;
}

.distributor-dashboard {
  width: 23%;
  float: left;
  text-align: right;
  padding-right: 0%;
  margin-top: 1.8%;
  margin-bottom: 3%;
}

.distributor-dashboard a {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.distributor-dashboard a:hover {
  color: #fff;
  text-decoration: none;
}
.settingDate .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(238, 238, 238) !important;
}
.settingDate{
  display: flex;
  justify-content: center;
}

.download-wrapper .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(255, 255, 255) !important;
}

.lbl #centerName::placeholder{
  color: rgb(255, 255, 255) !important;
  background: black !important;
}

.input-wrapper #fname::placeholder{
  color: rgb(223, 223, 223) !important;
  background: #1a1a1a !important;
}

.input-wrapper #mobile::placeholder{
  color: white !important;
  background: #1a1a1a !important;
}

.input-wrapper #phone::placeholder{
  color: white !important;
  background: #1a1a1a !important;
}

.input-wrapper #email::placeholder{
  color: white !important;
  background: #1a1a1a !important;
  font-size: 14px;
}