#root {
  background: #121212;
  width: 100%;
  float: left;
}

.sidebar {
  width: 17%;
  float: left;
  height: auto;
  top: auto;
  left: auto;
  margin-top: 6%;
  background: #121212;
}

.sidebar img {
  margin: 15px;
  width: 150px;
  margin-left: 20px;
}

.sidebar-container {
  margin: 15px 20px;
  text-align: left;
  opacity: 1;
  background: #121212;
}

.sidebar-container a {
  display: block;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0 12px;
}

.sidebar-container span {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 15px;
  height: 18px;
  text-align: left;
  font-size: 13px;
  line-height: 50px;
  letter-spacing: 0px;
  color: #8e8e8e;
  opacity: 1;
  font-weight: 500;
}

.sidebar-container i {
  color: #8e8e8e;
  width: 20px;
}

.nav {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  /* float: left; */
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  display: inline-block;
  /* float: left; */
  margin: 0 auto;
  margin-top: 2%;
}

.nav.dashboard {
  width: 100%;
  display: inline-block;
  /* float: left; */
  margin: 0 auto;
  margin-top: 2%;
}

.nav span {
  margin-left: 78%;
  color: white;
  float: right;
}

.nav .nav-logo {
  margin: 0;
  float: left;
  width: 10%;
  padding-left: 2%;
}

.nav .nav-logo img {
  width: 100%;
}

.nav span i {
  font-size: 19px;
  padding-right: 40px;
}

.content {
  border-top: 1px solid #595959;
  margin-top: 6%;
  width: 83%;
  padding: 1% 0 0 1.5%;
  float: right;
  border-left: 1px solid #595959;
  border-top-left-radius: 10px;
  padding-right: 3%;
  margin-bottom: 2%;
}

.content span {
  height: 43px;
  text-align: left;
  font-size: 32px;
  line-height: 43px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content .complaint-head {
  color: #fff;
  font-size: 26px !important;
  font-weight: 500;
}

ul#menu {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
  margin-bottom: 2% !important;
}

ul#menu li {
  display: block;
  text-align: center;
  padding: 20px 9px 32px;
  text-decoration: none;
  height: 19px;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #999999;
  opacity: 1;
  font-weight: 500;
  float: left;
}

ul#menu li:hover {
  cursor: pointer;
}

ul#lim li {
  display: block;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  height: 19px;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #999999;
  opacity: 1;
}

ul#lim li:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

ul#lim {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  background: none;
  width: 390px;
  border-bottom: 1px solid grey;
}

ul#lim li {
  float: left;
}

.selectedMenu {
  color: #eb951f !important;
  border-bottom: 2px solid #eb951f;
}

.sidebar p {
  margin-left: 130px;
}

.dropdown h6 {
  top: 211px;
  left: 220px;
  width: 103px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

#st-box {
  float: left;
  width: 180px;
  height: auto;
  margin-right: 30px;
  margin-bottom: 3%;
}

/* #nd-box {
  float: left;
  width: 195px;
  height: 32px;
  margin-right: 15px;
  margin-bottom: 3%;
} */

#nd-box button {
  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;
}

#nd-box i {
  margin-left: 110px;
  font-size: 12px;
}

#rd-box {
  float: left;
  width: 250px;
  height: auto;
  margin-bottom: 3%;
}

#rd-box input {
  border: none;
}

#rd-box button {
  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;
}

#rd-box i {
  font-size: 12px;
  margin-left: 100px;
}

/* #dd-box button {
  background: #000;
  color: #fff;
  height: 34px;
  border: 1px solid gray;
  border-radius: 100px;
  opacity: 1;
  margin-left: 0;
  width: 90%;
  font-size: 14px;
} */

#dd-box button .fa-filter {
  margin-left: 12%;
  color: #fff;
}

#st-box h6,
#nd-box h6,
#rd-box h6 {
  color: #999999;
  font-size: 14px;
}

.dropbtn {
  top: 232px;
  left: 220px;
  width: 195px;
  height: 32px;
  padding: 0 15px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
}

.dropbtn i {
  padding-left: 40px;
}

.dropdown .dropdown {
  padding: 100px;
  margin-left: 120px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.dropdown h6 {
  margin-top: 30px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 198px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.search {
  float: right;
  margin-top: 0;
  line-height: 40px;
  width: auto;
  border-bottom: 1px solid grey;
  margin-left: 0;
  margin-right: auto;
}

.search .fa-search {
  color: #fff;
  font-size: 12px;
}

.search input {
  border: none;
  width: auto;
  font-size: 12px;
  color: #fff;
  margin-left: 0;
  background: #121212;
  float: left;
}

.search input::placeholder {
  color: #fff;
  background: none;
}

.list-container {
  width: 100%;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 360px;
  padding-right: 2%;
  margin-right: 0;
}

.container6 {
  padding: 12px;
  margin: 10px 0;
  left: 220px;
  width: 100%;
  height: auto;
  float: left;
  background: #121212;
  border: 1px solid #595959;
  border-radius: 10px;
  opacity: 1;
}

#root .container6 .box .handclass span,
#root .container6 .box .box1 span,
#root .container6 .box .box2 span {
  font-size: 14px !important;
}

.container7 {
  padding: 20px;
  margin: 10px;
  left: 220px;
  width: 100%;
  height: 115px;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  background: #121212;
  margin-left: 2px;
}

.container7:first-child {
  margin-top: 0;
}

.container7.activeColor {
  border: 1px solid #eb951f !important;
  background: #000 !important;
}

.container1 {
  padding: 20px;
  left: 220px;
  width: 1050px;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-left: -15px;
  margin-top: 20px;
}

.container2 {
  padding: 20px;
  left: 220px;
  width: 1060px;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-left: -15px;
  margin-top: 10px;
}

.Fno-result-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-result-text p {
  color: #fff;
}

.active {
  margin-left: 0;
  top: 306px;
  left: 342px;
  width: 74px;
  height: 21px;
  border-radius: 100px;
  opacity: 1;
  color: #4ac0b1;
  font-size: 12px;
  border: #999;
  background: transparent;
}

.Et .active {
  margin-left: 0;
  float: left;
  padding-left: 0;
  left: 0;
  width: auto;
}

.box h6 {
  top: 306px;
  left: 232px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #fff;
}

.box {
  width: 100%;
  margin: 0 auto;
  float: left;
}

.box span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box p {
  top: 366px;
  left: 232px;
  margin-top: 7px;
  height: 16px;
  text-align: left;
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  font-weight: 300;
  margin-bottom: 0;
}

.box1 {
  width: 25%;
  float: left;
  margin: 0 auto;
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  padding: 0 10px 10px 30px !important;
}

.box1 h6 {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  font-weight: 500;
  height: inherit;
}

.box .box1 span {
  top: 335px;
  left: 545px;
  height: 38px;
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 12px !important;
  font-weight: 300;
}

.box2 {
  width: 25%;
  float: left;
  margin: 0 auto;
  padding-left: 35px;
}

.box2 h6 {
  top: 306px;
  left: 922px;
  height: 19px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box2 span {
  top: 335px;
  left: 923px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box2 p {
  top: 366px;
  left: 922px;
  height: 16px;
  text-align: left;
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1;
}

.box3 {
  width: 25%;
  float: left;
  margin: 0 auto;
  color: #eb951f;
  text-align: right;
  padding-right: 2%;
}

.box3 i {
  padding: 10px;
  padding-top: 0;
  font-size: 13px;
}

.box3 p {
  top: 363px;
  left: 1239px;
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  margin-left: 110px;
  margin-top: 30px;
  text-align: right;
}

.pages {
  margin-top: 10px;
  float: left;
}

.pages p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-left: 140px;
  margin-top: -22px;
}

.pages span {
  margin-left: 10px;

  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.pages select {
  margin-left: 20px;
  color: black;
  width: 56px;
  height: 33px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
}

.pages1 {
  float: left;
  padding-left: 33px;
}

.pages1 button {
  color: black;
  border-radius: 5px;
  height: 33px;
  width: 33px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
}

.pages1 i {
  padding: 15px;
  color: #fff;
  opacity: 1;
}

.pages1 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.footer {
  margin-top: 40px;
}

.footers {
  margin-top: 0px;
  width: 100%;
  float: left;
}

.service-center-wrapper .footers {
  margin-top: 0;
}

#root .footers .pages .dropdown span {
  font-size: 14px !important;
}

/* edit ticket */
.mod {
  position: relative;
  display: inline-block;
  /* float: right; */
  width: 760px;
  pointer-events: auto;
  background-clip: padding-box;
  background: #121212;
  border-radius: 0.3rem;
  outline: 0;
  height: 1300px;
  margin-top: 65px;
  margin-right: 0;
}

.Et {
  margin-top: 10px;
  margin-left: 20px;
  width: 100%;
  float: left;
}

.modal-header {
  margin-bottom: 5%;
}

.modal-header .Et span {
  color: #fff;
  padding-right: 3%;
  float: left;
  height: auto;
  line-height: 30px;
  font-size: 30px !important;
  font-weight: 500;
}

.close_assign {
  margin-right: 18%;
  margin-top: 5%;
}

.modal-header .Et span.s {
  padding-left: 3%;
  float: left;
  height: auto;
  border-right: none;
  border-left: 1px solid #fff;
}

.lbl,
.lbl2,
.lbl3,
.lbl4,
.lbl5,
.lbl6,
.lbl7,
.lbl8,
.lbl9,
.lbl10,
.lbl11,
.lbl12 {
  width: 47%;
  margin: 0;
  float: left;
  padding-left: 5%;
  margin-bottom: 5%;
}

.lbl3,
.lbl6,
.lbl7 {
  width: 94%;
}

.lbl p,
.lbl2 p,
.lbl3 p,
.lbl4 p,
.lbl5 p,
.lbl6 p,
.lbl7 p,
.lbl8 p,
.lbl9 p,
.lbl10 p,
.lbl11 p,
.lbl12 p {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  width: 72px;
  opacity: 1;
  margin-left: 0;
  padding-top: 15px;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.lbl7 p.second-child {
  margin-top: 5%;
}

.lbl input,
.lbl2 input,
.lbl3 input,
.lbl4 input,
.lbl5 input,
.lbl6 input,
.lbl7 input,
.lbl8 input,
.lbl9 input,
.lbl10 input,
.lbl11 input,
.lbl12 input {
  width: 100%;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #2d2e2d;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 3%;
  padding-left: 0;
}

.lbl6 input {
  width: 46%;
}

.lbl3 input,
.lbl6 input,
.lbl7 input {
  padding-bottom: 1.5%;
}

.lbl7 input::placeholder,
.lbl8 input::placeholder,
.lbl9 input::placeholder,
.lbl10 input::placeholder,
.lbl11 input::placeholder {
  color: #fff;
}

.lbl i,
.lbl2 i,
.lbl9 i,
.lbl10 i {
  margin-left: -40px;
  color: black;
}

.lbl12 {
  width: 100%;
  float: left;
  text-align: left;
}

.lbl13 {
  margin-left: 36px;
  margin-top: 40px;
  width: 100%;
  float: left;
  text-align: left;
}

.lbl13 button {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
}

/* more design */

.modf {
  background: #121212 !important;
  position: relative;
  display: inline-block;
  /* float: left; */
  width: 380px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 700px;
  margin: 0;
  /* float: right; */
}

.mofd {
  border-radius: 20px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 460px;
  background-color: white;
  margin-left: 420px;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  height: 700px;
  margin-top: 65px;
}

.more4 {
  margin-top: 30px;
  margin-left: 30px;
  width: 100%;
  float: left;
}

.more4 h6 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.more4 button {
  border-radius: 50px;
  border: 1px solid black;
  width: 198px;
  height: 32px;
}

.more4 i {
  margin-left: 130px;
  font-size: 12px;
}

.more5 {
  margin-left: 30px;
  width: 100%;
  float: left;
}

.more5 .dropdown,
.more4 .dropdown {
  border: none;
}

.more5 h6 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.more5 button {
  border-radius: 50px;
  border: 1px solid black;
  width: 198px;
  height: 32px;
}

.more5 i {
  margin-left: 130px;
  font-size: 12px;
}

.more6 {
  margin-top: 40%;
  width: 100%;
  float: left;
}

.more6 button {
  width: 36% !important;
  height: 38px !important;
  color: white;
  background-color: #eb951f !important;
  font-size: 12px;
}

.warranty {
  margin-top: 9%;
  margin-left: 30px;
  width: 100%;
  float: left;
}

.warranty h6 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

#close_filters {
  margin-right: 10%;
}

.cont {
  margin-left: 40px;
  margin-top: 30px;
}

.cont h6 {
  color: grey;
}

.b1 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #d1d5df80;
  border-radius: 10px;
}

.b1 p {
  color: #000000;
}

.b1 .p {
  margin-top: -12px;
  color: grey;
}

.b1 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

.b2 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #d1d5df80;
  margin-top: 30px;
  border-radius: 10px;
}

.b2 p {
  color: #000000;
}

.b2 .p {
  margin-top: -12px;
  color: grey;
}

.b2 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

.cont1 {
  margin-top: 30px;
}

.cont1 h6 {
  color: grey;
}

.b3 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #d1d5df80;
  border-radius: 10px;
}

.b3 p {
  color: #000000;
}

.b3 .p {
  color: grey;
  margin-top: -12px;
}

.b3 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

/* Attachment design*/
.content1 {
  margin-left: 220px;
  padding-right: 40px;
}

.div1 {
  padding: 20px;
  margin-top: 20px;
  width: 40%;
  height: 100px;
  background: #f2fffd 0% 0% no-repeat padding-box;
  border: 1px solid #4ac0b0;
  border-radius: 10px;
  opacity: 1;
}

.div1 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div2 {
  margin-top: 10px;
  padding: 20px;
  width: 40%;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div2 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div3 {
  margin-top: 10px;
  padding: 20px;

  width: 40%;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div3 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 300px;
  margin-top: -32px;
  font-weight: 500;
}

/* .main-container {
margin-top: 10px;
width: 100%;
height: 400px;
overflow-y: scroll;
float: left;
} */

.left-container {
  float: left;
  width: 37%;
}

.right-container {
  padding: 20px;
  float: right;
  width: 60%;
  margin-top: 0;
  min-height: 100%;
  height: auto;
  background: #000;
  border-radius: 10px;
  opacity: 1;
  border: 1px solid #eb951f;
}

.buton {
  margin-top: -35px;
}

.col {
  margin-right: 3%;
  width: 100%;
  float: left;
  padding: 0;
  margin-top: -6%;
}

.dsc {
  margin-left: 84%;
  border: none;
  border-radius: 20px;
  height: 30px;
  background: #eb951f;
  width: 100px;
  color: #fff;
  font-size: 14px;
}

.dsc i {
  padding-right: 10px;
}

.bbts {
  margin-left: 84%;
  border: none;
  border-radius: 20px;
  height: 30px;
  background: #eb951f;
  width: 100px;
  color: #fff;
  font-size: 14px;
}

.bbts i {
  padding-right: 10px;
}

.ppt {
  margin-left: 170px;
  margin-top: -25px;
}

.ppti {
  float: right;
  margin-top: -35px;
}

.div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #f2fffd 0% 0% no-repeat padding-box;
  border: 1px solid #4ac0b0;
  border-radius: 10px;
  opacity: 1;
}

ul#menu2 {
  margin-top: 30px;
  list-style-type: none;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}

.mitem {
  margin-top: -5%;
}

ul#menu2 li {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 11px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #fff;
  opacity: 1;
}

ul#menu2 li:hover {
  cursor: pointer;
}

.div4 h6 i {
  margin-left: 400px;
  font-size: 20px;
}

.div4 .box p {
  margin-left: 200px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}

.div4 .box .bt button i {
  margin: 5px;
}

.div5 {
  width: 50%;
  float: left;
}

.div5.p_image {
  padding-right: 5%;
}

.div5.p_image h6 {
  margin-top: 5px;
  text-align: left;
  font-size: 11px;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1;
}

.div6 {
  width: 50%;
  overflow-y: auto;
  float: right;
}

.div6.invoice h6 {
  margin-top: 5px;
  text-align: left;
  letter-spacing: 0px;
  float: left;
  color: #ccc;
  opacity: 1;
  font-size: 11px;
  margin-left: 0;
  margin-bottom: 0.8rem;
}

.div6 span a {
  color: #eb951f;
  font-size: 11px;
  float: right;
  line-height: initial;
  margin-bottom: 0.8rem;
  margin-top: 5px;
  line-height: initial;
}

.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
  width: 43%;
  float: right;
}

.div6 p i {
  padding-right: 8px;
}

.div6 img {
  width: 340px;
  height: 280px;
  margin-top: -5px;
}

/* comment */
.message {
  width: 100%;
  height: auto;
  float: left;
}

.immo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  margin-top: 65px;
}

.imgs img {
  width: 500px;
}

.heads1 {
  overflow-y: auto;
  max-height: 265px;
  height: auto;
  width: 100%;
  float: left;
  background: #000;
}

.foots {
  width: 100%;
  float: left;
  margin-top: 3%;
}

.foots .ic p {
  margin-top: -20px;
  margin-left: 40px;
}

/* history */
.cm1 {
  border-bottom: 1px solid #999;
  margin-top: 3%;
}

.cm1 h1 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
}

.cm1 p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.cm2 h1 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.cm2 p {
  font-weight: 600;
  font-size: 14px;
}

.abc {
  cursor: pointer;
  width: 3%;
  height: 100%;
  z-index: 99999;
}

.abc .fa-times {
  color: #fff;
}

.setwidth {
  width: 60%;
}

.handclass {
  cursor: pointer;
  width: 25%;
  float: left;
}

.wrapperClassName {
  border: 1px solid rgb(198, 189, 189) !important;
  background-color: #ffffff !important;
}

.content span {
  font-size: 14px !important;
}

.error {
  display: none;
}

#user_search {
  background: transparent;
  margin-left: -15px;
  padding-left: 3%;
  font-size: 14px;
  font-weight: 500;
}

#user_search:focus,
#user_search:-internal-autofill-selected {
  box-shadow: 0 !important;
  outline: none;
}

.user_search_icon {
  font-size: 13px;
  color: grey;
}

.container6 .box3 .mod .modal-header #close-editTicket {
  margin-right: 3%;
  margin-top: 2%;
}

.content .main-container .right-container .details h6 {
  margin-bottom: 0;
}

.content .main-container .right-container .details span {
  font-size: 12px !important;
  font-weight: 300 !important;
}

.content .main-container .right-container .col.show .dropdown-menu {
  background: #000;
  height: 160px;
  padding: 15px;
  overflow-y: scroll;
  border: 1px solid #eb951f;
  top: 40% !important;
  left: -5% !important;
}

.content
  .main-container
  .right-container
  .col.show
  .dropdown-menu
  .dropdown-item {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5%;
  padding: 5px;
}

.content
  .main-container
  .right-container
  .col.show
  .dropdown-menu
  .dropdown-item:hover
  > img {
  filter: invert(1);
}

.content
  .main-container
  .right-container
  .col.show
  .dropdown-menu
  .dropdown-item
  img {
  width: 15%;
  margin-right: 10%;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid #60606052;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.rs-picker-toolbar-ranges {
  display: none;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

/* Select search css */
.content-dashboard .problem-product-courier,
.content-dashboard .dropdown-wrap-problem {
  max-height: 32px;
  width: 100%;
}

.content-dashboard .css-1s2u09g-control,
.content-dashboard .css-1pahdxg-control,
.content-dashboard .css-1s2u09g-control,
.content-dashboard .css-1pahdxg-control {
  width: 100%;
  height: 32px;
  padding: 0 5px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  max-height: 32px;
  min-height: 32px;
}

.content-dashboard .css-1s2u09g-control:hover,
.content-dashboard .css-1pahdxg-control:hover,
.content-dashboard .css-1s2u09g-control:hover,
.content-dashboard .css-1pahdxg-control:hover {
  border: 1px solid #595959;
}

.content-dashboard .css-1s2u09g-control .css-319lph-ValueContainer,
.content-dashboard .css-319lph-ValueContainer {
  padding: 0 8px;
}

.content-dashboard
  .css-1s2u09g-control
  .css-319lph-ValueContainer
  .css-14el2xx-placeholder,
.content-dashboard .css-14el2xx-placeholder {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: "Roboto";
  font-weight: 500;
}

.content-dashboard .css-1pndypt-Input,
.content-dashboard .css-ackcql {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-dashboard .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-dashboard .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-dashboard .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-dashboard
  .css-1s2u09g-control
  .css-319lph-ValueContainer
  .css-6j8wv5-Input,
.content-dashboard .css-6j8wv5-Input {
  margin: 0;
}

.content-dashboard
  .css-1pahdxg-control
  .css-319lph-ValueContainer
  .css-6j8wv5-Input {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: "Roboto";
  font-weight: 500;
}

.content-dashboard .css-1pahdxg-control,
.content-leaderboard .css-1pahdxg-control {
  box-shadow: none;
}

.content-leaderboard .css-6j8wv5-Input,
.content-dashboard .css-6j8wv5-Input {
  color: #fff;
  font-weight: 500;
}

.content-dashboard .css-319lph-ValueContainer {
  padding: 0 8px;
}

.content-dashboard .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .right-grid  .dropdown-wrap-courier .css-tlfecz-indicatorContainer{
  height: 28px;
  color: #fff;
} */
.content-dashboard .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.content-dashboard .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.content-dashboard .css-6j8wv5-Input input,
.content-dashboard .css-6j8wv5-Input input {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
}

.content-dashboard .css-319lph-ValueContainer .css-14el2xx-placeholder,
.content-dashboard .css-14el2xx-placeholder {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
  font-size: 14px;
}

.content-dashboard .css-26l3qy-menu,
.content-dashboard .css-26l3qy-menu {
  background: #000;
  color: #fff;
  border-radius: 10px;
}

.content-dashboard .css-1gtu0rj-indicatorContainer {
  color: #ffffff !important;
}

.content-dashboard .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
.content-dashboard .css-1n7v3ny-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
  background: #fa9f221a 0% 0% no-repeat padding-box;
  color: #fa9f22;
}

.content-dashboard
  .css-26l3qy-menu
  .css-4ljt47-MenuList
  .css-1n7v3ny-option:active,
.content-dashboard .css-1n7v3ny-option:active {
  background: #fa9f221a 0% 0% no-repeat padding-box;
}

.content-dashboard .css-yt9ioa-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
}

.css-1gtu0rj-indicatorContainer svg {
  color: #fff;
}

.content-dashboard .loader {
  position: inherit;
  left: 35%;
  /* margin-top: 40%; */
  margin-top: 7%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.css-vwja0k {
  color: #ffffff !important;
}

/* .rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: #eb951f;
}

.rs-calendar-table-cell-content:hover{
  background-color: #FA9F2233 !important;
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content, .rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: #FA9F2233 ;
} */

/* .rs-picker-menu{
background-color: #000000;
} */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-btn-primary {
  background: #eb951f;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #fa9f2233;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #eb951f;
}

.rs-anim-fade.rs-anim-in,
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  background: #000;
  color: #fff;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-picker-daterange-header,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  color: #fff;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: none;
  margin-right: -31%;
  margin-top: -1px;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
  background-color: transparent !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward
  svg,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward
  svg {
  color: #fff;
  background-color: transparent !important;
}

.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
  float: left;
  color: #fff !important;
  background: none !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar {
  padding: 0 !important;
}

.rs-calendar-month-dropdown-year-active,
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: #eb951f;
  background-color: transparent;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-btn-primary {
  color: #eb951f !important;
  background: none !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  color: #eb951f;
}

.rs-btn-subtle:focus,
.rs-btn-subtle:hover {
  background-color: #000;
}

.damaged_product {
  background: #fff;
  padding: 2%;
  margin-bottom: 0;
  /* padding-top: 5%; */
  width: 100%;
  float: left;
  text-align: center;
}

.damaged_product table {
  width: 100%;
  float: left;
  background: #fff;
  margin: 0 auto;
  text-align: center;
}

.damaged_product #rModal_head {
  /* margin-bottom: 1%; */
  padding: 0;
  color: #000;
  width: 100%;
  float: left;
  font-weight: 800;
  font-size: 26px !important;
}

.damaged_product .print_cta {
  background-color: #fff;
  padding-bottom: 3%;
  padding-left: 0%;
  /* margin-top: -1%; */
  padding-top: 5%;
  width: 100%;
  float: left;
}

.damaged_product svg {
  transform: scale(1.5) translate(0, 10px);
  margin: 0 auto;
  /* margin-bottom: 10px; */
}

.damaged_product p {
  font-size: 24px;
}

.print_cta {
  background-color: #fff;
  padding-bottom: 3%;
  padding-left: 2%;
  /* margin-top: -1%; */
  padding-top: 2%;
}

.ticket_wrapper {
  width: 100%;
  float: left;
}

@media only screen and (min-device-width: 1900px) {
  .lbl,
  .lbl2,
  .lbl3,
  .lbl4,
  .lbl5,
  .lbl6,
  .lbl7,
  .lbl8,
  .lbl9,
  .lbl10,
  .lbl11,
  .lbl12,
  .prefix {
    width: 50%;
  }

  .select-product-css.select-product-css.dropbtn1111 {
    display: inline-block;
    height: 18px;
    line-height: 10px;
  }
}
.content-dashboard .lbtn {
  display: inline-block;
  margin-right: 5%;
  width: 22%;
}
.list input::placeholder {
  color: #fff;
  background: none;
}

.content-title {
  display: flex;
  justify-content: space-between;
}

.content-title .dropdown-wrap {
  padding: 0px !important;
}

.drop-down-wid {
  width: 100% !important;
}

#ticketID b {
  font-size: 20px !important;
  font-weight: 800 !important;
}
