.form-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  align-items: start;
  align-content: start; /* Ensures all items start at the same position */
}

/* Make it single column on small screens */
@media (max-width: 768px) {
  .form-container {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 1rem; /* Reduce gap for better spacing */
  }
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Reduce the gap slightly */
  align-items: stretch; /* Ensures alignment across columns */
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 84px; /* Ensures all fields align properly */
}

.form-group label {
  font-weight: 500;
  font-size: 1rem;
  min-height: 22px; /* Make sure labels take consistent space */
  display: flex;
  align-items: center; /* Align text vertically */
}

/* Ensure uniform height and consistent sizing */
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 1rem;
  height: 45px;
  box-sizing: border-box; /* Prevents padding from affecting height */
}

/* Adjust file input height */
.form-group input[type="file"] {
  height: auto;
  padding: 6px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Make sure all form rows take equal height */
.form-group select,
.form-group input {
  display: flex;
  align-items: center;
}
.serial-number-images-popup {
  position: fixed; /* Covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dark background blur */
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: 1000; /* Ensures it's above other content */
}

.serial-number-images-popup-inner-wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 70vw; /* 70% width */
  height: 70vh; /* 70% height */
  max-width: 700px; /* Prevent it from getting too large */
  max-height: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out text and images */
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-header h2 {
  margin: 0;
  font-size: 25px;
}

.popup-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.serial-number-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between images */
  flex-wrap: wrap;
  overflow-y: auto; /* Allows scrolling if needed */
}

.serial-image {
  max-width: 48%; /* Images fit nicely */
  height: auto;
  border-radius: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
