.header {
  /* width: 1440px;
    height: 60px; */
  margin: 0 0 20px;
  padding: 13px 24px 13px 12px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
}

.subHeader {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 5px;
}

.col-width {
  max-width: 11% !important;
  flex-basis: 11% !important;
  display: flex;
  justify-content: center;
}

.col-width2 {
  /* max-width:11% !important; */
  /* flex-basis:11% !important; */
  display: flex;
  justify-content: center;
}

.active-line {
  position: absolute;
  top: 30px;
  width: 75%;
  /* width: 125px; */
  height: 2px;
  background-color: #4ac0b0;
}

.logo {
  text-align: center;
}

.header-new .logo {
  margin: 0;
  float: left;
  width: 11%;
  padding-left: 3%;
}

.header-new .logo span,
.header-new .logo span img {
  width: 100%;
  float: left;
}

.CRM {
  width: 30px;
  height: 16px;
  /* margin: 3px 105px 15px 8.9px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}

.down_icon {
  height: 10px;
}

.menu {
  position: absolute;
  width: 140px;
  /* height: 98px; */
  margin: 13px 0 0 62px;
  border-radius: 5px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.buttonView {
  padding: 16px 0 10px 1px;
  background-color: #fff;
  border: 0.1px solid rgba(0, 0, 0, 0.1);
}

.icon {
  width: 15px;
  height: 15px;
  margin: 0px 12px 0 20px;
  object-fit: contain;
}

.logoutText {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f15a5d;
}

.profileText {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.logout_button {
  width: 75px;
  position: absolute;
  top: 30px;
  z-index: 1000;
  left: 57%;
}

.dashboard-text {
  width: 68px;
  height: 16px;
  margin: 2px 0 2px 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e6e6e;
}

.dashboard-layer {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  object-fit: contain;
}

.complaint-text {
  width: 72px;
  height: 16px;
  margin: 2px 0 2px 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e6e6e;
}

.prospective-text {
  width: 75px;
  height: 16px;
  margin: 3px 0 2px 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e6e6e;
}

.prospective-text-small {
  width: 75px;
  height: 16px;
  margin: 3px 0 2px 12px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e6e6e;
}

.profile {
  align-items: flex-end;
}

.profile-name {
  width: 81px;
  height: 16px;
  margin: 0 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}

.profile-image {
  width: 34px;
  height: 34px;
  /* margin: 0 12px 0 0; */
  object-fit: cover;
  border-radius: 15px;
}

/* dashboard-css */

.sidebar {
  margin-top: 0 !important;
}

.nav-highlight {
  background: #FA9F221A 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

/* .sidebar .sidebar-container a .menucolor2 {
  color: #eb951f!important;
}
.sidebar .sidebar-container a .menucolor2 .menucolor{
  color: #FA9F22!important;
  font-weight: 400!important;
} */
.content-dashboard {
  border-top: 1px solid #595959;
  margin-top: 6%;
  width: 83%;
  padding: 2%;
  float: right;
  border-left: 1px solid #595959;
  border-top-left-radius: 10px;
}

.content-dashboard h3 {
  line-height: 1;
  font-weight: 500;
  color: #fff;
  font-family: 'Roboto';
}

.dropdown-wrap {
  padding: 1% 0 !important;
}

.dropdown-bar h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.date-wrap {
  width: 260px;
  height: 32px;
  border-radius: 30px;
  padding: 5px;
  background: #000;
  border: 1px solid #595959;
  color: #fff;
  font: normal normal medium 14px/19px Roboto;
}

.date-wrap::-webkit-calendar-picker-indicator {
  color: #fff;
  filter: invert(1);
}

.main-wrap {
  padding: 1% 0;
}

.left-grid {
  display: inline-block;
  width: 50%;
  padding-right: 1%;
}

.left-grid .complaint-wrapper {
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 3%;
}

.left-grid .complaint-wrapper .complaint-wrap .grid {
  display: inline-block;
  width: 33.33%;
  border-right: 1px solid #595959;
  padding-left: 5%;
}

.left-grid .complaint-wrapper .complaint-wrap .grid:first-child {
  padding-left: 0;
}

/* .left-grid .complaint-wrapper .complaint-wrap .grid .total-color{
color: #FA9F22;
} */
.left-grid .complaint-wrapper .complaint-wrap .grid-pad {
  padding-left: 5%;
}

.left-grid .complaint-wrapper .complaint-wrap .grid:last-child {
  border-right: none;
}

.left-grid .complaint-wrapper .complaint-wrap .grid h6 {
  font: normal normal 600 14px/19px Roboto;
  color: #fff;
  opacity: 0.8;
  font-weight: 100;
}

.left-grid .complaint-wrapper .complaint-wrap .grid h2 {
  font: normal normal bold 26px/36px Roboto;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.left-grid .complaint-wrapper .complaint-wrap .grid:first-child h2 {
  color: #FA9F22;
}

.right-grid .complaint-wrapper {
  border: 1px solid #595959;
  border-radius: 10px;
}

.right-grid {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-left: 1%;
}

.right-grid .complaint-wrapper {
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 3%;
}

.right-grid .complaint-wrapper .courier-wrap {
  padding-bottom: 3px;
}

.right-grid .complaint-wrapper .courier-wrap .grid {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.right-grid .complaint-wrapper .courier-wrap .grid h6,
.progress-wrap .dropdown-wrap-problem h6 {
  font: normal normal 600 14px/19px Roboto;
  color: #fff;
  opacity: 0.8;
  font-weight: 100;
}

.right-grid .complaint-wrapper .courier-wrap .grid h2 {
  font: normal normal bold 26px/36px Roboto;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.right-grid .complaint-wrapper .courier-wrap .grid .courier-color {
  color: #03DAC6;
}

.right-grid .complaint-wrapper .courier-wrap .grid .dropdown-wrap-courier .dropbtn {
  width: 100%;
  font-weight: 500;
}

.left-grid .progress-wrap {
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 3%;
  margin-top: 4%;
}

.left-grid .progress-wrap .title-wrap {
  padding-bottom: 20px;
  height: 40px;
}

.left-grid .progress-wrap .title-wrap h3 {
  font-size: 20px;
  margin-bottom: 0;

}

.left-grid .progress-wrap .progress-inner-wrap {
  height: 310px;
  overflow-y: scroll;
}

.left-grid .progress-wrap .progress-inner-wrap .progress-wrap-main {
  padding: 2% 0;
}

.left-grid .progress-wrap .progress-inner-wrap .progress-wrap-main p {
  margin-bottom: 5px;
  color: #ffffff;
  font: normal normal normal 16px/21px Roboto;
  font-weight: 100;
}

.left-grid .progress-wrap .progress-inner-wrap .progress-wrap-main p span {
  float: right;
  font: normal normal bold 16px/21px Roboto;
}

.left-grid .progress-wrap .progress-inner-wrap .progress-wrap-main .progress {
  width: 100%;
  height: 10px;
  position: relative;
  background: #FA9F221A !important;
  border-radius: 20px !important;
}

.left-grid .progress-wrap .progress-inner-wrap .progress-wrap-main .progress .progress_inner {
  position: absolute;
  width: 75%;
  height: 100%;
  background-color: #FA9F22 !important;
  background: -webkit-linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background: linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 5.25s;
  animation-duration: 5.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
  border-radius: 10px;
}

.right-grid .progress-wrap {
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 3%;
  margin-top: 4%;
}

.right-grid .progress-wrap .title-wrap {
  padding-bottom: 20px;
  height: 40px;
  margin-bottom: 7%;
}

.right-grid .progress-wrap .title-wrap h3 {
  font-size: 20px;
  display: inline-block;
  width: 50%;
  margin-bottom: 0;

}

.right-grid .progress-wrap .title-wrap .grid {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem,
.right-grid .dropdown-wrap-courier {
  max-height: 32px;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1s2u09g-control,
.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1pahdxg-control,
.right-grid .dropdown-wrap-courier .css-1s2u09g-control,
.right-grid .dropdown-wrap-courier .css-1pahdxg-control {
  width: 100%;
  height: 32px;
  padding: 0 5px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  max-height: 32px;
  min-height: 32px;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1s2u09g-control .css-319lph-ValueContainer,
.right-grid .dropdown-wrap-courier .css-319lph-ValueContainer {
  padding: 0 8px;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1s2u09g-control .css-319lph-ValueContainer .css-14el2xx-placeholder,
.right-grid .dropdown-wrap-courier .css-14el2xx-placeholder {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.right-grid .dropdown-wrap-courier .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.right-grid .dropdown-wrap-courier .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1s2u09g-control .css-319lph-ValueContainer .css-6j8wv5-Input,
.right-grid .dropdown-wrap-courier .css-6j8wv5-Input {
  margin: 0;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1pahdxg-control .css-319lph-ValueContainer .css-6j8wv5-Input {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1pahdxg-control,
.right-grid .dropdown-wrap-courier .css-1pahdxg-control {
  box-shadow: none;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-6j8wv5-Input,
.right-grid .dropdown-wrap-courier .css-6j8wv5-Input {
  color: #fff;
  font-weight: 500;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-319lph-ValueContainer {
  padding: 0 8px;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .right-grid  .dropdown-wrap-courier .css-tlfecz-indicatorContainer{
  height: 28px;
  color: #fff;
} */
.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.right-grid .dropdown-wrap-courier .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-6j8wv5-Input input,
.right-grid .dropdown-wrap-courier .css-6j8wv5-Input input {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;

}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-319lph-ValueContainer .css-14el2xx-placeholder,
.right-grid .dropdown-wrap-courier .css-14el2xx-placeholder {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
  font-size: 14px;

}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-26l3qy-menu,
.right-grid .dropdown-wrap-courier .css-26l3qy-menu {
  background: #000;
  color: #fff;
  border-radius: 10px;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-1gtu0rj-indicatorContainer {
  color: #ffffff !important;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
.right-grid .dropdown-wrap-courier .css-1n7v3ny-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
  background: #FA9F221A 0% 0% no-repeat padding-box;
  color: #FA9F22;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:active,
.right-grid .dropdown-wrap-courier .css-1n7v3ny-option:active {
  background: #FA9F221A 0% 0% no-repeat padding-box;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option,
.right-grid .dropdown-wrap-courier .css-yt9ioa-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
}

.right-grid .css-1gtu0rj-indicatorContainer svg {
  color: #fff;
}

.right-grid .progress-wrap .title-wrap .grid .dropdown-wrap-problem .dropbtn {
  top: 232px;
  left: 220px;
  width: 100%;
  height: 32px;
  padding: 0 15px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
}

.right-grid .progress-wrap .progress-inner-wrap {
  height: 310px;
  overflow-y: scroll;
}

.right-grid .progress-wrap .progress-inner-wrap .progress-wrap-main {
  padding: 2% 0;
}

.right-grid .progress-wrap .progress-inner-wrap .progress-wrap-main p {
  margin-bottom: 5px;
  color: #ffffff;
  font: normal normal normal 16px/21px Roboto;
  font-weight: 100;
}

.right-grid .progress-wrap .progress-inner-wrap .progress-wrap-main p span {
  float: right;
  font: normal normal bold 16px/21px Roboto;
}

.right-grid .progress-wrap .progress-inner-wrap .progress-wrap-main .progress {
  width: 100%;
  height: 10px;
  position: relative;
  background: #FA9F221A !important;
  border-radius: 20px !important;
}

.right-grid .progress-wrap .progress-inner-wrap .progress-wrap-main .progress .progress_inner {
  position: absolute;
  width: 75%;
  height: 100%;
  background-color: #FA9F22 !important;
  background: -webkit-linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background: linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 5.25s;
  animation-duration: 5.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
  border-radius: 10px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: 100% 0
  }

  100% {
    background-position: -100% 0
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0
  }

  100% {
    background-position: -100% 0
  }
}

.rs-picker-toggle-wrapper {
  width: 230px !important;
  border-radius: 20px;
  background: #000000 0% 0% no-repeat padding-box !important;
  border: 1px solid #FFFFFF4D !important;
}

.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-top: 1px !important;
  padding-bottom: 4px !important;
  border-radius: 20px;
  z-index: 0;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-radius: 20px;
  background: #000000 0% 0% no-repeat padding-box !important;
  border: none !important;
  box-shadow: none !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 3px !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #fff !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder::placeholder {
  color: #fff !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder:-ms-input-placeholder {
  color: #fff !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder::-ms-input-placeholder {
  color: #fff !important;
}

.rs-picker:not(.rs-picker-disabled):hover {
  border-color: #FFFFFF4D !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  color: #fff !important;
}

.rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
  border-color: #FFFFFF4D !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #FFFFFF4D !important;
}

.dropdown-bar .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(224, 224, 224) !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn.rs-btn-close {
  display: none !important;
}

 .rs-picker-toggle-textbox,
::placeholder {
  background: #000 !important;
  color: #fff !important;
} 


/* .rs-picker-daterange-header{
  border-bottom: 1px solid #FFFFFF4D !important;
    color: #fff !important;
} */
/* .rs-picker-menu{
  border-radius: 10px !important;
  background-color: antiquewhite !important;
}

.rs-btn-subtle {
  color: #fff !important;
}
.rs-btn-subtle:focus, .rs-btn-subtle:hover{
  background: #FA9F2233 0% 0% no-repeat padding-box;;
} */