.serviceCenter {
  background-color: #F5FBFB;
  font-family: Arial, Helvetica, sans-serif;
  /* padding-bottom: 125px; */
}

.sc__main-content {
  display: flex;

}

/* .clearfix {
    overflow: auto;
} */

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mainContent {
  padding-left: 65px;
  width: 100%
}

.heading {
  color: #1A202D;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 24px;
}

#newEmpBtn {
  background: none;
  border: 1px solid #3e80e27a;
  color: #3e7fe2;
  border-style: dashed;
  border-radius: 8px;
  height: 140px;
  padding: 20px;
  width: 100%;
}

#empBtnIcon {
  margin-bottom: 0;
}

#empBtnText {
  font-size: 18px;
  font-weight: 700;
}

.complaits_container {

  float: left;
  width: 100%;
  height: 90%;
  left: 418px;
  top: 180px;
}

.complaits_container .ticket-wrapper {
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 15px;
  float: left;
  padding: 12px;
  gap: 12px;
  width: 295px;
  height: 156px;
  left: 418px;
  top: 144px;
  background: #FFFFFF;
  border: 1px solid rgba(38, 37, 44, 0.1);
  box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
  border-radius: 8px;
}

.complaits_container .ticket-wrapper #header1 {
  width: 198px;
  height: 24px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.complaits_container .ticket-wrapper #insideline {
  margin-top: 3px;
  width: 100%;
  height: 0px;
  border: 1px solid rgba(38, 37, 44, 0.1);
  border-top: none;
  border-left: none;
  border-right: none;

}

.product_details {
  padding: 0px;
  gap: 4px;
  width: 100%;
  height: 85px;
}

.products_info .productname {

  width: 110px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.products_info #nm1 {
  overflow: hidden;
  text-align: right;
  margin-top: 1px;
  width: 141px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.products_info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 244px;
  height: 11px;
}

.user_main_container {

  margin-top: 18px;
  width: 100%;
  height: 90%;
  float: left;
}

.user_main_container .userWrapper {
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 2%;
  float: left;
  padding: 12px;
  gap: 12px;
  width: 266px;
  height: 160px;
  left: 418px;
  top: 144px;
  background: #FFFFFF;
  border: 1px solid rgba(38, 37, 44, 0.1);
  box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
  border-radius: 8px;
}

.userWrapper .headerUser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 168px;
  height: auto;
}

.userWrapper .headerUser #name1 {
  font-family: 'Arial';
  font-style: normal;
  width: 150%;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: rgba(26, 32, 45, 0.9);
  padding: 0 10px;
}

.userWrapper .headerUser #userRole {
  margin-left: 10px;
  width: 168px;
  height: 1px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(26, 32, 45, 0.9)
}

.userWrapper .headerUser #borderUser {
  width: 244px;
  height: 0px;

  border: 1px solid rgba(38, 37, 44, 0.1);
}

.userWrapper .userInfoContainer {
  /* margin-top: 22px; */
  padding: 0px;
  gap: 4px;
  width: 244px;
  height: 44px;
}

.userInfoContainer .userProfileInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 244px;
  height: auto;
}

.userInfoContainer .mobileContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 244px;
  height: auto;
}

.mobileContainer #maildId {
  word-wrap: break-word;
  float: left;
  margin-top: 1px;
  width: 168px;
  height: auto;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

}

.mobileContainer #mails {
  margin-left: 10px;
  width: 60px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(26, 32, 45, 0.9)
}

.userProfileInfo #maildId {
  word-wrap: break-word;
  float: left;
  margin-top: 1px;
  width: 168px;
  height: auto;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

}

.userProfileInfo #mails {
  margin-left: 10px;
  width: 60px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(26, 32, 45, 0.9)
}

.column1 {
  display: flex;
  justify-content: space-between;
}

.headingService {
   justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  margin-top: 15px;
  width: 100%;
  height: 32px;
  left: 418px;
  top: 96px;
}

.headingService h1 {
  width: 334px;
  height: 32px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  color: #1A202D;
  font-size: 24px;
  line-height: 32px;
}

.filters {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 533px;
  height: 32px;
}

.filters .service-filter {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 16px;
  gap: 8px;
  width: 220px;
  height: 35px;
  background: #F7F7F7;
  border: 1px solid rgba(38, 37, 44, 0.1);
  border-radius: 20px;
}

.servicecenter_listContainer {

  margin-top: 25px;
  width: 100%;
  height: 90%;
  float: left;
}

.center_ticketContainer {

  margin-right: 2%;
  margin-bottom: 2%;
  float: left;
  padding: 12px;
  gap: 12px;
  width: 412px;
  height: 210px;
  left: 418px;
  top: 144px;
  background: #FFFFFF;
  border: 1px solid rgba(38, 37, 44, 0.1);
  box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
  border-radius: 8px;
  cursor: pointer;
}

.center_ticketContainer h2 {
  width: 100%;
  height: 24px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 24px;
}

#borderService {
  width: 100%;
  height: 0px;
  border: 1px solid rgba(38, 37, 44, 0.1);
}

.cdetils {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 100%;
  height: 24px;
}

#name2 {
  width: 195px;
  height: 20px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

#problemss {
  width: 278px;
  height: 48px;
  /* overflow: hidden; */
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.statusDetais {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-bottom: 3px;
  width: 100%;
  height: 20px;
}

#name3 {
  width: 180px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1A202D;
}

#name4 {
  overflow: hidden;
  width: 291px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: rgba(26, 32, 45, 0.9);

}

/* .rs-picker-toggle-wrapper{
    width: 230px !important;
    border-radius: 20px;
    background: #fff 0% 0% no-repeat padding-box !important;
      border: 1px solid #FFFFFF4D !important;
  }
  .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-radius: 20px;
    z-index: 0;
    height:35px;
    border: 1px solid rgba(38, 37, 44, 0.1)
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-radius: 20px;
    background: #F7F7F7 0% 0% no-repeat padding-box !important;
    border: none !important;
    box-shadow: none !important;
  }
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
    top: 3px !important;
  }
  
  .rs-picker-toggle .rs-picker-toggle-placeholder{
    color:#fff !important;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder::placeholder{
    color:#fff !important;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder:-ms-input-placeholder{
    color:#fff !important;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder::-ms-input-placeholder{
    color:#fff !important;
  }
  .rs-picker:not(.rs-picker-disabled):hover {
    border-color: #FFFFFF4D !important;
  }
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
    color:rgb(7, 1, 1) !important;
  }
  .rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover{
    border-color: #FFFFFF4D !important;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
    border-color: #FFFFFF4D !important;
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color:rgb(102, 78, 78) !important;
  }
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,.rs-picker-daterange .rs-picker-toggle.rs-btn.rs-btn-close{
  display: #fff !important;
  }
  .rs-picker-toggle-textbox,::placeholder{
    background: #f7f7f7 !important;
    color:#fff !important;
  } */


.searchWrapper {
  padding-left: 65px;
}

.dropbtnService {
  overflow: auto;
  top: 232px;
  left: 220px;
  width: 195px;
  height: 38px;
  padding: 0 15px;
  border: 1px solid #999999;
  border-radius: 100px;
  opacity: 1;
  color: #121212;
  background: #f7f7fa;
  font-size: 14px;
  outline: none;
}

pages3 i {
  padding: 15px;
  color: black;
  opacity: 1;
}

.service-filterDate .rs-picker-toggle-wrapper {
  width: 230px !important;
  border-radius: 20px;
  background: #fff 0% 0% no-repeat padding-box !important;
  border: 1px solid #FFFFFF4D !important;
}

.service-filterDate .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-radius: 20px;
  z-index: 0;
  height: 35px;
  border: 1px solid rgba(38, 37, 44, 0.1)
}

.service-filterDate .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-radius: 20px;
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  border: none !important;
  box-shadow: none !important;
}

.service-filterDate .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 3px !important;
}

.service-filterDate .rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #fff !important;
}

.service-filterDate .rs-picker-toggle .rs-picker-toggle-placeholder::placeholder {
  color: #fff !important;
}

.service-filterDate .rs-picker-toggle .rs-picker-toggle-placeholder:-ms-input-placeholder {
  color: #fff !important;
}

.service-filterDate .rs-picker-toggle .rs-picker-toggle-placeholder::-ms-input-placeholder {
  color: #fff !important;
}

.service-filterDate .rs-picker:not(.rs-picker-disabled):hover {
  border-color: #FFFFFF4D !important;
}

.service-filterDate .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  color: rgb(7, 1, 1) !important;
}

.service-filterDate .rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
  border-color: #FFFFFF4D !important;
}

.service-filterDate .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #FFFFFF4D !important;
}

.service-filterDate.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(0, 0, 0) !important;
}

.service-filterDate .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn.rs-btn-close {
  display: rgb(0, 0, 0) !important;
}

.service-filterDate .rs-picker-toggle-textbox,
::placeholder {
  background: #f7f7f7 !important;
  color: rgb(0, 0, 0) !important;
}

.service-filterDate.rs-anim-fade.rs-anim-in,
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  background: #000000 !important;
  color: rgb(2, 0, 0) !important;
}

.service-filterDate {
  border-radius: 100px;
  border: 1px solid #999999
}

.exportOptions {
  border-radius: 5px;
  width: 40px;
  height: 38px;
  border: 1px solid #999999;
}

.flexEnd {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

#selectBtnWrapper {
  display: none;
}

.selectBtn {
  background: #fff;
  border: 1px solid #1A202D66;
  border-radius: 4px;
  color: rgba(26, 32, 45, 0.8);
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 49px;
  padding: 5px 16px;
  text-transform: uppercase;
}

.selectBtnTwo {
  margin-right: 24px;
}

.selectTicketCheckBox {
  display: none;
  margin-right: 5px;
}

.replacementTabs {
  /* border: 2px solid black;
  border-radius: 4px;
  padding: 5px 0; */
  padding-left: 0;
}

.replacementTabs li {
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  margin-right: 20px;
}

.replacementTabs li.activeTab {
  padding: 0 5px;
  font-weight: 700;
  border: 1px solid black;
  border-radius: 4px;
  background: #307FE2;
  color: white;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 5px 16px;
  text-transform: uppercase;
}

.replacementTabs li.unselctActive {
  background: #fff;
  border: 1px solid #1A202D66;
  border-radius: 4px;
  color: rgba(26, 32, 45, 0.8);
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  /* margin-right: 49px; */
  padding: 5px 16px;
  text-transform: uppercase;
}



.actionsWrapper {
  position: relative;
  /* background-color: #fff; */
  display: flex;
  font-size: 0.9em;
  justify-content: right;
  align-items: center;
  padding: 2em;
  padding-left: 120px;
  /* box-shadow: 0px 4px 8px 0px #e9eef299;
    -webkit-box-shadow: 0px 4px 8px 0px #e9eef299;
    -moz-box-shadow: 0px 4px 8px 0px #E9EEF299;
  */
}

.actionsWrapper span {
  background: #fff;
  color: rgba(26, 32, 45, 0.8);
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 25px;
  padding: 5px 16px;
  text-transform: uppercase;
}


.actionsWrapper button {
  background: #307FE2;

  border-radius: 4px;
  color: #fff;
  ;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 25px;
  padding: 5px 16px;
  text-transform: uppercase;
}

.challan .header {
  font-weight: 500;
  margin: 0 0 20px;
  padding: 0px 0px 8px 12px;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  position: relative;
}

.challanInfo input {
  float: right;
  font-weight: 500;
  font-size: 15px;
  font-family: auto;
  background-color: #F7F7F7;
  border: 0.1px solid rgba(41, 41, 41, 0.352);
  border-radius: 6px;
  outline: none;
  padding: 4px;
  width: 50%;
  height: auto;

}

.challan #sendButton {
  margin: 20px 16px;
  align-items: center;
  background-color: #307FE2;
  color: #fff;
  border-radius: 4px;
  justify-content: center;
  letter-spacing: 1px;
  height: 45px;
  padding: 7px 1px;
  text-transform: uppercase;
  width: 130px;
  float: right;
}

#closeModalREP {
  background-color: rgb(255, 255, 255);
  height: 20px;
  margin-right: 15px;
  float: right;
}

#closeModalREP i {
  color: rgb(0, 0, 0);
}

.inventBTn {
  display: flex;
  justify-content: space-between;
}

.mainContentFiles {
  width: 100%;
}