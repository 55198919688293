.bss-main-container {
  border-top: 1px solid #595959;
  margin-top: 6%;
  width: 83%;
  padding: 1% 0 0 1.5%;
  float: right;
  border-left: 1px solid #595959;
  border-top-left-radius: 10px;
  padding-right: 3%;
  margin-bottom: 2%;
}

.bss-main-container .complaint-head {
  color: #fff;
  font-size: 26px !important;
  font-weight: 500;
}

.bss-main-container .bss-menu {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 9px;
  border-bottom: 1px solid grey;
  margin-bottom: 2%;
  display: flex;
  gap: 40px;
  padding-top: 3%;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.bss-main-container .bss-menu .selected {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
  width: 8%;
  display: flex;
  justify-content: center;
}

.bss-search-container {
  float: left !important;
  margin-top: 0;
  line-height: 40px;
  width: auto;
  border-bottom: 1px solid grey;
  margin-left: 0;
  margin-right: auto;
  width: 28%;
  /* margin-bottom: 2%; */
}

.bss-search-container input {
  width: 82%;
  outline: none;
}

.list-container-bss {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-y: scroll;
}

.list-bss-overflow {
  height: 43vh;
  overflow-y: scroll;
}
.list-container-bss ul {
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid rgb(253 253 253 / 10%);
  padding-bottom: 10px;
  padding-top: 10px;
}

.list-container-bss ul li {
  width: 25%;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.list-container-bss .ul-header-list li {
  width: 25%;
  color: white;
  font-weight: 500;
  font-size: 18px;
  color: #eb951f;
}

.list-container-bss .ul-header-list {
  margin-right: 2.5%;
}

.list-content-bss {
  padding-top: 12px;
}

.bss-search-add-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.bss-search-add-button button {
  border: none;
  border-radius: 10px;
  background: #eb951f;
  color: white;
  font-size: 16px;
  font-weight: 500;
  /* margin-bottom: 2%; */
}

.bss-search-add-button select {
  width: 25%;
  color: white;
  background: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding-left: 10px;
  outline: none;
}

.modal-header-ssb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-right: 0px;
  border-bottom: 1px solid gray;
}

.modal-header-ssb h2 {
  font-size: 28px;
  font-weight: 500;
  color: white;
  margin: 0px;
}

.sample-button {
  display: flex;
  justify-content: end;
  background-color: transparent;
  padding: 4%;
}

.sample-button button {
  background-color: transparent;
  color: #eb951f;
  font-size: 16px;
  font-weight: 500;
}

.upload-section-ssb {
  padding-left: 20px;
}

.upload-section-ssb button {
  width: 32%;
  padding: 10px;
  border-radius: 44px;
  background: orange;
  font-size: 18px;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  outline: none;
}

.upload-section-ssb-uploaded {
  padding-left: 20px;
  background: orange;
  width: 60%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 0px 10px;
  margin-left: 20px;
  color: white;
}

.upload-section-ssb-uploaded button {
  width: 100%;
  padding: 10px;
  background: transparent;
  font-size: 18px;
  font-weight: 500;
  color: white;
  /* display: flex; */
  justify-content: space-evenly;
  align-items: center;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none !important;
}
.upload-csv-button-ssb {
  background: #e5e5ea;
  border: 0px;
  width: 20%;
  border-radius: 10px;
  margin-top: 10%;
  margin-left: 5%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-weight: 500;
}

.header-name {
  display: flex;
  justify-content: space-between;
}

.header-name button {
  font-size: 16px;
  font-weight: 600;
  color: white;
  background:#eb951f;
  border-radius: 10px;
}
