.filter-row_sc,
.dispatch_inward_sc,
.bulk-rpu_sc {
    display: flex;
    justify-content: stretch;
    padding-left: 3%;
    padding-right: 7%;
    align-items: center;
}
  .dispatch_inward_service,
  .filter-row_sc,
  .bulk-rpu_sc {
     width: 100%;
    float: left;
    display: block;
  }


.ticketNumberInput_sc,
.selectStatus_sc,
.showProducts_sc {
  margin-left: 3%;
    width: 300px;
    float: left;
}

.dispatch_inward_service .ticket_head_sc,
.filter-row_sc .ticket_head_sc{
  font-size: 12px;
  color: #828282;
  margin-bottom: 3%;
  font-weight: 500;
  margin-top: 0;
}
.text-area-input_sc {
  width: 300px;
  height: 32px;
  opacity: 1;
  border: none;
  outline: none;
  color: white;
  background-color: #121212;
  font-size: 14px;
  border-bottom: 1px solid grey;
  resize: none;
}
.text-area-input_sc ::placeholder{
 color:rgb(192, 0, 0)
}

.selectStatus_sc .choose_type_sc,
.showProducts_sc .select_product_sc{
  font-size: 12px;
    color: #828282;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 0;
}

.dispatch_inward_service .dropdown{
    font-size:14px;
    padding-left:2%;
}
.selectStatus_sc {
  margin-left: 4%;
}
.filter-row_sc .dropdown,
.showProducts_sc .dropdown {
    padding: 6px 6px;
    height: auto;
    font-size: 14px;
    width: 225px;
    margin-left: -35px;
}

.only_movement_wrapper_sc {
  width: 100%;
  float: left;
  margin-top: 2%;
}

.row .only_movement_wrapper_sc .only-movement_sc {
  padding-left: 5%;
  width: 100%;
  float: left;
  padding-top: 0;
  margin-left: 1%;
}
.input-wrap-bulk {
  position: absolute;
  opacity: 0;
}

.warehouse,
.scanning {
    opacity: 0;
    position: absolute;
    z-index: 666;
    top: 27%;
    left: 2%;
}

.warehouse:hover,
.scanning:hover {
    cursor: pointer
}

.input-wrap-bulk+label,
#only_movement+label,
.warehouse+label,
.scanning+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.input-wrap-bulk+label:before,
#only_movement+label:before,
.warehouse+label:before,
.scanning+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 3px;
}

.input-wrap-bulk:hover+label:before,
#only_movement:hover+label:before,
.warehouse:hover+label:before,
.scanning:hover+label:before {
    background: transparent;
}

.input-wrap-bulk:focus+label:before,
#only_movement:focus+label:before,
.warehouse:focus+label:before,
.scanning:before+label:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.input-wrap-bulk:checked+label:before,
#only_movement:checked+label:before,
.warehouse:checked+label:before,
.scanning:checked+label:before {
    background: rgb(250, 159, 34);
    border: none;
}

.input-wrap-bulk:checked+label:after,
#only_movement:checked+.select-all-label:after,
.warehouse:checked+label:after,
.scanning:checked+label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 9px;
    background: rgb(250, 159, 34);
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
    transform: rotate(45deg);

}

.select-all-label {
  color: white;
  padding-left: 10px;
  font-size: 13px;
  margin-bottom: 0;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.ticket-summary_sc {
  display: flex;
  padding-left: 35px;
  padding-top: 29px;
  gap: 2%;
  color: white;
  width: 100%;
}

/* .lbl *[id^='css-']{
  color: white !important;
} */

.lbl .css-qbdosj-Input{
  color:white !important
}

.lkk .css-166bipr-Input {
  color:white !important
}


.list input::placeholder {
  color: #fff;
  background: none;
}
.maxDlimit{
  display: flex;
  justify-content: center;
}