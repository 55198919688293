.serviceCenterSidebar {
    margin-top: 1em;
    padding-left: 130px;
    margin-right: 55px;
    width: 315px;
}

.sideBarHeading {
    font-size: 0.7em;
    text-transform: uppercase;
}

.sideBarFilters {
    padding: 0;
    list-style-type: none;
}

.sideBarFilters li {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.sideBarFilters img {
    margin:0 15px;
    width: 20px;
}

.sideBarBtn {
    border-radius: 8px;
    background-color: #fff;
    padding: 12px 24px;
    font-size: 14px;
    width: 224px;
}

#complaintBtn {
    background-color: #307FE2;
    color: #fff;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin:45px 0 60px 0;
}

#active {
    border-radius: 8px;
    background-color: #fff;
    padding: 8px 0;
    width: 224px;
}

.numOfComplaints {
    float: right;
}
.mainContentFiles{
    
}