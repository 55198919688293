.date-fields-container {
  display: flex;
  flex-direction: row;
  /* Changed from column to row */
  gap: 20px;
  /* Increased gap for better spacing between elements */
  margin-bottom: 20px;
  align-items: flex-start;
  /* Aligns items at the top */
}

.date-fields-container input[type="date"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #595959;
  background-color: #000;
  color: #fff;
  min-width: 150px;
  color-scheme: dark;
  /* Added to ensure consistent width */
}

extended-warranty-container {
  display: flex;
  flex-direction: row;
  /* Changed from column to row */
  align-items: center;
  /* Centers items vertically */
  gap: 10px;
  /* Space between label and input */
}

.extended-warranty-container label {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  /* Prevents label from wrapping */
}

.extended-warranty-container input[type="number"] {
  padding: 8px 20px 8px 8px;
  /* Added more padding on the right for the spinners */
  border-radius: 4px;
  border: 1px solid #595959;
  background-color: #000;
  color: #fff;
  min-width: 190px;
}
