.flex-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    background: #fff;
}
.flex-table thead {
    color: #fff;
    background: #009485;
}
.flex-table thead tr,
.flex-table tbody tr {
    display: flex;
}
.flex-table tbody tr+tr {
    border-top: 1px solid #ccc;
}
.flex-table thead tr th,
.flex-table tbody tr td {
    display: flex;
    flex: 1;
    padding: .5em;

}
@media screen and (max-width: 640px) {
    .flex-table {
        border: 0;
    }
    .flex-table thead {
        display: none;
    }
    .flex-table tbody tr {
        flex-direction: column;
        margin: 1em;
        border: 1px solid #ccc;
    }
    .flex-table tbody tr td {
        flex-direction: column;
    }
    .flex-table tbody tr td+td {
        border-top: 1px solid #ccc;
    }
    .flex-table tbody tr td:before {
        display: flex;
        align-items: center;
        margin: -.5em -.5em .75em -.5em;
        padding: .5em;
        content: attr(data-label);
        color: #fff;
        background: #009485;
    }
}

.rpu_print #send_rModal{
    margin-top: 4%;
}
.rpu-print-cont td, .rpu-print-cont p{
font-size: 22px;
font-weight: 500;
}
.rpu_print_cta{
    background:#fff;
    color:#000;
    padding-left: 3%;
    margin-top: 0;
    width: 100%;
}
.rpu_print_cta button{
    margin: 2% 0;
}
.rpu_print .flex-table thead tr th, .rpu_print .flex-table tbody tr td{
    flex: auto;
}

.rpu_print .tr1{
    margin-top: 5%;
}
.rpu_print .svg{
    display: inline-block;
    transform: scale(1) translate3d(0px, 10px, 10px) !important;
    padding: 0;
}
.rpu_print .svg td{
    display: block;
    padding: 0;
}