.serviceCenterHeader {
 background-color: #fff;
 display: flex;
 font-size: 0.9em;
 justify-content: space-between;
 align-items: center;
 padding: 2em;
 padding-left: 120px;
 box-shadow: 0px 4px 8px 0px #E9EEF299;
 -webkit-box-shadow: 0px 4px 8px 0px #E9EEF299;
 -moz-box-shadow: 0px 4px 8px 0px #E9EEF299;
}

.serviceCenterHeader img {
    margin-right: 0.5em;
    width: 1.7em;
}

.bold {
    font-weight: bold;
}

/* .fa {
    margin-right: 0.5em;
} */

.columnThreeContainer {
    display: flex;
    align-items: center;
}

.columnThree {
    margin-right: 1em;
    align-items: center;
}

.columnThree img {
    width: 20px;
}

#profile {
    width: 40px;
}
.searchInputService{
    border-top: none;
    border-left: none;
    border-right: none;
    margin-right: 10px;
}
.serviceSearchButton{
    background: none;
}
.serviceSearch{
    margin-right: 40px;
}
.searchInputService::placeholder{
    background: none !important;
    color: #26252C80 !important;
    padding: 0;
}

#signout_dropdown {
    background: #fff;
    border: 1px solid #26252C1A;
    border-radius: 6px;
    display: none;
    position: absolute;
    padding: 1em;
    top: 10%;
    right: 3%;
    z-index: 9999;
}

#logoutBtn {
    background: none;
}

#logoutBtn:hover {
    font-weight: 700;
    text-decoration: underline;
}