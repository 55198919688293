.flexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.designation {
    color: #1A202DBF;
    font-size: 16px;
    font-weight: 700;
}

.modalHeading {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.modalContent {
    color: #1A202DBF;
    font-size: 14px;
    font-weight: 400;
}

p.modalContent {
    margin-bottom: 0;
}

#employeeName {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}

#handleModalBtn {
    background: none;
    border: none;
}

.searchWrapper {
    padding-left: 65px;
}

.serviceCenter {
    background-color: #F5FBFB;
    font-family: Arial, Helvetica, sans-serif;
    /* padding-bottom: 125px; */
}

.sc__main-content {
    display: flex;

}

/* 
.clearfix {
    overflow: auto;
} */

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.mainContent {
    padding-left: 65px;
    width: 100%
}

.heading {
    color: #1A202D;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 24px;
}

#newEmpBtn {
    background: none;
    border: 1px solid #3e80e27a;
    color: #3e7fe2;
    border-style: dashed;
    border-radius: 8px;
    height: 140px;
    padding: 20px;
    width: 100%;
}

#empBtnIcon {
    margin-bottom: 0;
}

#empBtnText {
    font-size: 18px;
    font-weight: 700;
}

.complaits_container {

    float: left;
    width: 100%;
    height: 90%;
    left: 418px;
    top: 180px;
}

.complaits_container .ticket-wrapper {
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    padding: 12px;
    gap: 12px;
    width: 295px;
    height: 156px;
    left: 418px;
    top: 144px;
    background: #FFFFFF;
    border: 1px solid rgba(38, 37, 44, 0.1);
    box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
    border-radius: 8px;
}

.complaits_container .ticket-wrapper #header1 {
    width: 198px;
    height: 24px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.complaits_container .ticket-wrapper #insideline {
    margin-top: 3px;
    width: 100%;
    height: 0px;
    border: 1px solid rgba(38, 37, 44, 0.1);
    border-top: none;
    border-left: none;
    border-right: none;

}

.product_details {
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: 85px;
}

.products_info .productname {

    width: 110px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.products_info #nm1 {
    text-align: right;
    margin-top: 1px;
    width: 141px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.products_info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 244px;
    height: 11px;
}

.user_main_container {
    margin-top: 18px;
    width: 100%;
    height: 90%;
    float: left;
}

.user_main_container .userWrapper {
    cursor: pointer;
    margin-right: 2%;
    margin-bottom: 2%;
    float: left;
    padding: 12px;
    gap: 12px;
    width: 266px;
    height: 160px;
    left: 418px;
    top: 144px;
    background: #FFFFFF;
    border: 1px solid rgba(38, 37, 44, 0.1);
    box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
    border-radius: 8px;
}

.userWrapper .headerUser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 168px;
    height: auto;
}

.userWrapper .headerUser #name1 {
    font-family: 'Arial';
    font-style: normal;
    width: 150%;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: rgba(26, 32, 45, 0.9);
    padding: 0 10px;
}

.userWrapper .headerUser #userRole {
    margin-left: 10px;
    width: 168px;
    height: 1px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(26, 32, 45, 0.9)
}

.userWrapper .headerUser #borderUser {
    width: 244px;
    height: 0px;

    border: 1px solid rgba(38, 37, 44, 0.1);
}

.userWrapper .userInfoContainer {
    /* margin-top: 22px; */
    padding: 0px;
    gap: 4px;
    width: 244px;
    height: 44px;
}

.userInfoContainer .userProfileInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 244px;
    height: 9px;
}

.userProfileInfo #maildId {
    float: left;
    margin-top: 1px;
    width: 170px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

}

.userProfileInfo #mails {
    margin-left: 10px;
    width: 60px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(26, 32, 45, 0.9)
}

.column1 {
    display: flex;
    justify-content: space-between;
}

.headingService {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    margin-top: 15px;
    width: 100%;
    height: 32px;
    left: 418px;
    top: 96px;
}

.headingService h1 {
    width: 334px;
    height: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: #1A202D;
    font-size: 24px;
    line-height: 32px;
}

.filters {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 533px;
    height: 32px;
}

.filters .service-filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 16px;
    gap: 8px;
    width: 220px;
    height: 35px;
    background: #F7F7F7;
    border: 1px solid rgba(38, 37, 44, 0.1);
    border-radius: 20px;
}

.servicecenter_listContainer {

    margin-top: 25px;
    width: 100%;
    height: 90%;
    float: left;
}

.center_ticketContainer {

    margin-right: 2%;
    margin-bottom: 2%;
    float: left;
    padding: 12px;
    gap: 12px;
    width: 412px;
    height: 210px;
    left: 418px;
    top: 144px;
    background: #FFFFFF;
    border: 1px solid rgba(38, 37, 44, 0.1);
    box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
    border-radius: 8px;
}

.center_ticketContainer h2 {
    width: 176px;
    height: 24px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
}

#borderService {
    width: 100%;
    height: 0px;
    border: 1px solid rgba(38, 37, 44, 0.1);
}

.cdetils {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 100%;
    height: 24px;
}

#name2 {
    width: 195px;
    height: 20px;

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

#problemss {
    width: 278px;
    height: 48px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.statusDetais {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    margin-bottom: 3px;
    width: 100%;
    height: 20px;
}

#name3 {
    width: 180px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1A202D;
}

#name4 {
    overflow: hidden;
    width: 291px;
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(26, 32, 45, 0.9);

}

.cnNUmber {
    display: flex;
    justify-content: space-between;
}

.challan .challanInfo label {
    /* display: block; */
    font-size: 14px;
    font-weight: 700;
    /* margin-bottom: 4px; */
    width: 100%;
    float: left;
}