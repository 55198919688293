body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body,
#app,
#app>div {
  height: 100%
}
body iframe{
  display:none !important;
}
:root {
  --gray-color: #828282;
  --font-size: 12px;
  --margin-bottom: 3%;
  --font-weight: 500;
}