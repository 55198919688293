#root {
  background: #121212;
  width: 100%;
  float: left;
}

.content .distributor-head {
  color: #fff;
  font-size: 26px !important;
  font-weight: 500;
}

.kd-box {
  background-color: #FA9F22;
}

.heads,
/* .product_details1, */
.history {
  overflow-y: auto;
  float: left;
  width: 100%;
  padding: 0 20px;
  height: 310px;
}

.heads,
.product_details1,
.history {
  overflow-y: auto;
  float: left;
  width: 100%;
  padding: 0 20px;
  height: 330px;
}

.product_details .ticket-status {
  width: 83px;
  height: 16px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #FA9F22;
}

.right-container {
  padding: 20px;
  float: right;
  width: 60%;
  margin-top: 0;
  min-height: 100%;
  height: auto;
  background: #000;
  border-radius: 10px;
  opacity: 1;
  border: 1px solid #eb951f;
}

.main-container .right-container {
  height: auto;
  padding: 20px 0 0;
  overflow-y: hidden;
}

.content .main-container .right-container .details h6 {
  margin-bottom: 0;
  margin-top: 2%;
}

.content .main-container .right-container .details span {
  font-size: 12px !important;
  font-weight: 300 !important;
  width: 80%;
}

.content .main-container .right-container .col.show .dropdown-menu {
  background: #000;
  height: 160px;
  padding: 15px;
  overflow-y: scroll;
  border: 1px solid #eb951f;
  top: 40% !important;
  left: -5% !important;
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item {
  color:#fff;
  font-size: 14px;
  margin-bottom: 5%;
  padding: 5px;
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item:hover>img {

  filter: invert(1);
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item img {
  width: 15%;
  margin-right: 10%;
}

.content.complaint-main .main-container .right-container .box {
  padding: 0 20px
}

.main-container .right-container .box .col {
  width: 20%;
  float: right;
  padding: 0;
  margin-top: 12%;
  margin: 0;
  margin-top: 2.2%;
}

.main-container .right-container .box .col #dropdownMenuButton {
  margin: 0;
  width: 100%;
  float: left;
}

/* #rd-box {
  float: left;
  width: 250px;
  height: auto;
  margin-bottom: 3%;
} */

/* #rd-box input {
  border: none;
} */

#rd-box button {

  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;
}

#rd-box i {
  font-size: 12px;
  margin-left: 0px;
}

#ed-box {
  float: left;
  width: 1000px;
  height: auto;
  margin-top: 0%;
  margin-bottom: 3%;
  margin-left: 4%;
}

#ed-box input {
  border: none;
}

#ed-box button {

  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;
}

#ed-box i {
  font-size: 12px;
  margin-left: 100px;
}



.cat {
  margin-top: 1%;
}

quantity {
  margin-top: -3%;

}

.lbl,
.lbl2,
.lbl3,
.lbl4,
.lbl5,
.lbl6,
.lbl7,
.lbl8,
.lbl9,

.lbl11,
.lbl12,
.gstin,
.prefix {
  width: 45%;
  margin: 0;
  float: left;
  padding-left: 5%;
  margin-bottom: 5%;
}



.lbl3 {
  width: 94%;
}

.lbl p,
.lbl2 p,
.lbl3 p,
.lbl4 p,
.lbl5 p,
.lbl6 p,
.lbl7 p,
.lbl8 p,
.lbl9 p,

.lbl11 p,
.lbl12 p,
.prefix p,
.gstin p {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  width: 72px;
  opacity: 1;
  margin-left: 0;
  padding-top: 15px;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.lbl7 p.second-child {
  margin-top: 2%;
}



.lbl10 input{
  width: 50%;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 3%;
  padding-left: 0;

}

.lbl3 input,
.lbl6 input,
.lbl7 input {
  padding-bottom: 1.5%;
}

.lbl7 input::placeholder,
.lbl8 input::placeholder,
.lbl9 input::placeholder,
/* .lbl10 input::placeholder */
.lbl11 input::placeholder {
  color: #fff;
}

.lbl10 input::placeholder{
  color: #fff;

}



.lbl i,
.lbl2 i,
.lbl9 i {
  margin-left: -40px;
  color: black;
}
.lbl10 i{
  margin-left: -40px;
  color: black;

}


.lbl12,
.gstin {
  width: 100%;
  float: left;
  text-align: left;
}

.suffix {
  width: 100%;
  float: left;
  padding-left: 5%;
  margin-bottom: 5%;
}

.lbl13.update_ticket {
  margin-left: 36px;
  margin-top: 2%;
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 5%;
}

.lbl13 button {
  margin-top:7%;
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
}

.lbl2 .dropbtn1111 {
  width: 40%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.lbl .dropbtn1111 {
  width: 90%;
  height: 40px;
  background-color: #000000 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.lbl2 .dropbtn1111 {
  width: 90%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.lbl4 .dropbtn1111 {
  width: 90%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}

.lbl3 input {
  width: 100%;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 3%;
  padding-left: 0;
}

.lbl3 {
  width: 45%;
}


.lbl6 input,
.lbl7 input {
  width: 117%;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 3%;
  padding-left: 0;
}

.lbl5,
.lbl6,
.lbl7 {
  width: 80%;
}

.lbl8 {
  width: 45%;
}

.lbl9 .dropbtn1111 {
  width: 100%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
}
@media screen and (-webkit-min-device-pixel-ratio:0){
  .lbl9 .dropbtn1111 {
    width: 104%;
    height: 40px;
    background-color: #121212 !important;
    border: none;
    border-bottom: 1px solid gray;
    color: #fff !important;
    font-size: 14px;
  }
}
.lbl10 .dropbtn1111 {
  width: 100%;
  height: 40px;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff !important;
  font-size: 14px;
  /* margin-left:5% */
}





.lbl11 button {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
}

#select_rModal {
  background-color: #000 !important;
  margin: 22% 0 15%;
  margin-left: 8px;
  width: 90%
}

#send_rModal {
  
  margin-left: 5px;
}

.callReminderDate input#date-complaint,
.callReminderDate input#appt {
  filter: invert(1);
  border: none;
  font-size: 14px;
  border-bottom: 1px solid #595959;
  width: 80%;
  float: left;
  padding-bottom: 3%;
}

.callReminder h2 {
  color: #000;
  font-size: 18px;
  background: #fff;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-weight: 500;
}

.callReminder .fa-times {
  float: right;
  margin-right: 7%;
}

.callReminderDate {
  padding: 2% 7%;
}

.callReminderDate label {
  color: #fff;
  font-size: 12px;
  width: 100%;
  float: left;
}

.scheduleCtaWrapper {
  padding: 0 7%;
  margin-top: 3%;
  width: 100%;
  float: left;
}

.scheduleCtaWrapper .callCta {
  width: 30%;
  float: left;
  border-radius: 20px;
  margin-right: 5%;
  font-size: 14px;
  font-weight: 500;
  padding: 1% 0;
  color: #fff;
}

.scheduleCtaWrapper .callCta.save {
  background: #eb951f;
}

.scheduleCtaWrapper .callCta.cancel {
  background: #000;
  border: 1px solid #595959;
  color: #fff;
}

.callReminder .footer {
  color: #eb951f;
  width: 100%;
  float: left;
  background: #fff;
  margin: 0 auto;
  position: absolute;
  bottom: -1px;
  /* border-top-left-radius: 20px; */
  font-weight: 500;
  font-size: 16px;
  padding: 0.5rem 2rem;
}

.callReminderDate .validity {
  color: #fff;
  font-size: 12px;
  width: 100%;
  float: left;
  margin-top: 5%;
}

.timeWrapper,
.dateWrapper {
  width: 45%;
  float: left;
  margin-right: 2.5%;
}

#errorDate {
  display: block;
  width: 100%;
  float: left;
  color: red;
  font-size: 12px;
  margin-top: 5%;
}



ul #menu2 {
  list-style-type: none;
  margin-top: 30px;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}

.wrapper {
  width: 100% !important;
  float: left;  
  padding: 10px;
  border: 1px solid white;
  padding-left: 1%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 2.5%;
}
.product_class{
  width:100%;float:left;padding:0 2%;
}
.product_details2 select{
  font-size:12px;
  margin:2% 0;
}
.rtl-complaint .product_details{
padding:0;
height: 225px;  
}
.rtl-complaint .product_details ul{
  width:100%;float:left;padding:0
}
.rtl-complaint .wrapper{
  border:1px solid grey
}
.rtl-complaint .box{
  padding: 0 2%;
}
.rtl-complaint .box1 h6{
  margin-bottom:0;
}
.rtl-complaint .box1{
  padding-bottom: 0 !important;
}
.rtl-complaint .loader{
  position: inherit;
    left: 35%;
    /* margin-top: 40%; */
    margin-top: 7%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.prblmdescription span 
{
  text-align: left;
  font: normal normal normal 11px Roboto;
  font-size: 11px !important;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
  font-weight: 400 !important;
}

.prblmdescription p 
{
  color: white;
  text-align: left;
  font: normal normal normal 12px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1;
  word-break: break-word;
}


