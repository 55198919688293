.headerNew {
    background-color: #fff;
    display: flex;
    font-size: 0.9em;
    align-items: center;
    padding: 2em;
    padding-left: 120px;
    box-shadow: 0px 4px 8px 0px #E9EEF299;
    -webkit-box-shadow: 0px 4px 8px 0px #E9EEF299;
    -moz-box-shadow: 0px 4px 8px 0px #E9EEF299;
}

.navigation {
    width: 77%;
}

.backHome {
    cursor: pointer;
}

.horizontal {
    height: 1px;
    margin: 20px 0;
    border-bottom: 1px solid #26252C1A;
}

.main-container-new {
    background-color: #F5FBFB;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 130px;
    padding-bottom: 5em;
}

label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
    width: 100%;
    /* float:left */
}

.navBtn {
    align-items: center;
    background-color: #307FE2;
    color: #fff;
    border-radius: 4px;
    justify-content: center;
    letter-spacing: 1px;
    height: 45px;
    padding: 7px 1px;
    text-transform: uppercase;
    width: 130px;
}

#prevBtn {
    background-color: #fff;
    color: #000;
    margin-right: 12px;
    border: 1px solid #1A202D66;
    width: 95px;
}

.complaintForm {
    background-color: #fff;
    border: 1px solid #26252C1A;
    padding: 40px;
    padding-bottom: 80px;
}

.complaintForm input, .complaintForm select {
    background-color: #F7F7F7;
    border: 0.1px solid rgba(41, 41, 41, 0.352);
    border-radius: 6px;
    outline: none;
    padding: 8px 16px;
    width: 100%;
}
.complaintForm select, .date_wrapper {
    padding: 10px 18px;
}

.complaintForm input:focus, .complaintForm select:focus {
    border: 1.5px solid #307FE2
}

.form-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.form-subheading {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.editBtn {
    background: none;
    border: 1px solid #1A202D66;
    border-radius: 4px;
    color: #000;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    justify-content: center;
    letter-spacing: 1px;
    padding: 8px 16px;
}

.editBtn img {
    margin-right: 10px;
}

.review {
    font-size: 14px;
    font-weight: 400;
}

.alignFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.row__one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    column-gap: 20px;
}

.row__one div{
    flex-grow: 1;
}

.row__two {
    display: flex;
    align-items: stretch;
    column-gap: 20px;
    margin-bottom: 30px;
}

.row__two>div {
    flex-grow: 1;
}

.row__three {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
}

.row__three>div{
    flex-grow: 1;
}

.row_4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row_4>div:nth-child(1) {
    width: 48%;
}

.row_4>div:nth-child(2) {
    width: 48%;
}

.row_4>div:nth-child(3) {
    width: 27.5%;
}
.row_4 div.date_wrapper{
    width:49.4%;
}




.row_8 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 18px;
}

.row_8>div:nth-child(1) {
    width: 33%;
}

.row_8>div:nth-child(2) {
    width: 33%;
}

.row_8>div:nth-child(3) {
    width: 33%;
}
.row_8 div.date_wrapper{
    width:49.4%;
}
.buttons {
    float: right;
}

.headings {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.primary-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}
.primary-heading1 {
    font-size: 14px;
    /* font-weight: 700; */
    line-height: 20px;
    word-break: break-word;
    width:65%
}


.reviewDetailsTable {
    width: 100%;
}

.reviewDetailsTable tr {
    width: 100%;
}

.reviewDetailsTable td {
    width: 33.33%;
}

.flexRowNew {
    display: flex;
    align-items: stretch;
    column-gap: 20px;
}
