/* Scoped styles for the contact form */
.contact-form-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
}

.contact-form-container label {
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
}

.contact-form-container input {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
}

.contact-form-container button {
    padding: 8px 12px;
    background-color: orange;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.contact-form-container button:hover {
    background-color: darkorange;
}

.contact-form-container button.round {
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 12px;
}

contact-form-container .confirmationModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
}

.form-group textarea {
    width:100%;
    height: 100px;
    resize: vertical;
    height:125px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
  }

 .confirmationModal .modalHeader {
    text-align: center;
    position: relative;
    padding: 30px;
    border-bottom: 1px solid black;
    width: 100%;
    background-color: black;
    color: white;
}

.confirmationModal .modalHeader #modal_head {
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 0;
}

.confirmationModal .modalHeader #closeModal {
    position: absolute;
    cursor: pointer;
    top: 5px;  
    right: 10px;
    background: rgb(255, 149, 0);
    border: none;
    border-radius: 50%; 
    width: 25px;  
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-form-container .confirmationModal .modalHeader #closeModal i {
    color: black;
}

.contact-form-container .confirmationModal .content {
    width: 100%;
    text-align: center;
    margin-top: 13px;  
    color: black;
}

.confirmationModal .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.confirmationModal .modal-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: orange;
    color: black;
    width: auto;
}

.confirmationModal .modal-buttons button:first-child {
    background-color: orange;
    color: black;
}

.confirmationModal .modal-buttons button:last-child {
    background-color: darkorange;
    color: black;
}

.confirmationModal .modal-buttons button.round {
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 12px;
}

.confirmationModal p {
    color: white; 
}