/* .addEmployeeForm {
    background-color: #fff;
    border: 1px solid #26252C1A;
    padding: 40px;
    padding-bottom: 80px;
} */

/* .row__oneNew {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
} */

.addEmployeeForm input, .addEmployeeForm select {
    background-color: #F7F7F7;
    border: 0.1px solid rgba(41, 41, 41, 0.352);
    border-radius: 6px;
    outline: none;
    padding: 8px 16px;
    width: 100%;
}

.addEmployeeForm input:focus, .addEmployeeForm select:focus {
    border: 1.5px solid #307FE2
}

/* .addEmployeeInput::placeholder {
    background: none !important;
    color: #26252C80 !important;
    padding: 0;
} */

/* #companyEmailInput::placeholder {
    float: right;
} */
