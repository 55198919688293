/* .active{
 margin-left: 20px;
  width: 74px;
  height: 21px;
  background: #4AC0B0 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  color: white;
  font-size: 12px;
  border: navajowhite;
  
}
.box h6{
  top: 306px;
    left: 232px;
    height: 19px;
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
}
.box{
  margin-top: -10px;
}
.box span{
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.box p{
  top: 366px;
  margin-top: 10px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.box2{
  margin-left: 640px;
margin-top: -87px;
  
}
.box2 h6{
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.box2 span{
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.box2 p{
margin-top: 12px;
height: 16px;
text-align: left;
font: normal normal normal 12px/16px Roboto;
letter-spacing: 0px;
color: #000000;
opacity: 0.5;
}
.box3{
  margin-left:860px;
  margin-top: -100px;
  color: #eb951f;
}
.box3 i{
  padding: 10px;
}

.box3 p{
    height: 19px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 14px;
    line-height: 19px;
    margin-left:110px;
    margin-top: 30px;
}
.pages{
  margin-top: 10px;
  float: left;
}
.pages p{
text-align: left;
font: normal normal normal 12px/16px Roboto;
letter-spacing: 0px;
color: #999999;
opacity: 1;
margin-left: 140px;
margin-top: -22px;
}
.pages span{
text-align: left;
font: normal normal normal 12px/16px Roboto;
letter-spacing: 0px;
color: #999999;
opacity: 1;
  

}
.pages button{
margin-left: 20px;
  color:black;
  width: 56px;
height: 33px;
border: 1px solid #CCCCCC;
border-radius: 6px;
opacity: 1;
}
.pages1{
  float: left;
  padding-left: 33px;
}
.pages1 button{
  color:black;
  border-radius: 5px;
  height: 33px;
  width: 33px;
  border: 1px solid #CCCCCC;
border-radius: 6px;
opacity: 1;
}
.pages1 i{
  padding: 15px;
/* color: black; */
/* opacity: 1;
}
.pages1 p{

font: normal normal normal 12px/16px Roboto;
letter-spacing: 0px;
color: #999999;
opacity: 1;
}
.footer{
  margin-top: 30px;
  margin-left: 220px;
} */
*/
/* ticket design*/
/* .content1{
  margin-left: 220px;
  padding-right: 40px;
} */
/* .div1{
  padding: 20px;
  margin-top: 20px;
width: 40%;
height: 100px;
background: #F2FFFD 0% 0% no-repeat padding-box;
border: 1px solid #4AC0B0;
border-radius: 10px;
opacity: 1;
}
.div1 .box h1{
text-align: left;
font-size: 14px;
line-height: 19px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top: -32px;
margin-left: 300px;
font-weight: 500;
}
.div2{
  margin-top: 10px;
  padding: 20px;
width: 40%;
height: 100px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #999999;
border-radius: 10px;
opacity: 1;
}
.div2 .box h1{
text-align: left;
font-size: 14px;
line-height: 19px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top: -32px;
margin-left: 300px;
font-weight: 500;
}
.div3{
  margin-top: 10px;
padding: 20px;

width: 40%;
height: 100px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #999999;
border-radius: 10px;
opacity: 1;
}
.div3 .box h1{
text-align: left;
font-size: 14px;
line-height: 19px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-left: 300px;
margin-top: -32px;
font-weight: 500;
} */
.div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #f2fffd 0% 0% no-repeat padding-box;
  border: 1px solid #4ac0b0;
  border-radius: 10px;
  opacity: 1;
}
ul#menu2 {
  list-style-type: none;
  margin-top: 60px;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}
ul#menu2 li {
  float: left;
}

ul#menu2 li a {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: black;
  opacity: 1;
}

ul#menu2 li a:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}
.div4 .box p {
  margin-left: 300px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}
.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}
.div4 .box .bt button i {
  margin: 5px;
}
.div5 {
  float: left;
}
.div5 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}
.div6 {
  float: right;
}
.div6 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}
.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
}
.div6 p i {
  padding-right: 8px;
}
.div6 img {
  width: 340px;
  height: 280px;
  margin-top: -5px;
}

/*manage users*/
.list {
  margin-top: 32px;
  /* margin-left: 220px; */
  padding-right: 30px;
}

.addColor {
  margin-top: 0px !important;
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
  padding-right: 0px !important;
}
.list i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
}
.list .inputclass {
  height: 34px;
  width: 100%;
  border: none !important;
  border-bottom: 1px solid grey !important;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.list .searchb1::placeholder {
  background: none;
  color: white;
}

.list button {
  height: 30px;
  float: right;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  width: 130px;
}
.colrow {
  overflow-y: scroll;
  margin-top: 20px;
  height: 400px;
  width: 83%;
  float: left;
}

.row {
  margin-top: 10px;
}
.colrow .row .col-sm {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
.colrow .row .col p {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0 !important;
  padding-top: 0 !important;
}
.colrow .row .col span {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}
.colrow .row .col h6 {
  margin-left: 120px;
  color: #eb951f;
  font-size: 14px;
  margin-top: -19px;
}

.colrow .row .col .dropdown.product_dropdown {
  margin-top: -20px !important;
  float: right;
  text-align: right;
  margin-right: 18%;
}
.colrow .row .col .user_dropdown {
  margin-top: -16.5% !important;
  margin-left: 0;
  background: none;
  float: right;
  text-align: right;
}
.colrow .row .col .user_dropdown .btn-link {
  padding-right: 0;
}
.dropdown-menu .dropdown-item i {
  padding: 12px;
}
.dropdown-menu .dropdown-item i.fa-check {
  padding-left: 4%;
  padding-right: 11%;
}
.w-25 {
  background-color: #eee;
  margin-left: 200px;
}
.t1 {
  font-size: 32px;
  font: normal normal medium 32px/43px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 320px;
}
.searchbar {
  border: none;
  margin-top: 32px;
  padding-right: 30px;
}
.searchbar input {
  height: 34px;
  width: 250px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background-color: #eee;
}
.cols {
  font-size: 16px;
  font-weight: 800;
  margin-left: 30px;
  width: 350px;
}
.cols .row .col p {
  font-size: 14px;
  font-weight: 400;
}
.cols .row .col span {
  font-size: 14px;
  font-weight: 100;
}
.right {
  position: absolute;
  margin-top: -170px;
  width: 75%;
  background: white;
  margin-left: -295px;
}
.list2 {
  margin-top: 0;
  text-align: left;
  font: normal normal medium 32px/43px Roboto;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  font-size: 32px;
  margin-left: 10px;
  width: 100%;
  float: left;
}
.list2.edit_msg {
  margin-left: 3.5%;
}
.modc .edit_close {
  margin-right: 25%;
  width: 6%;
  float: right;
  text-align: center;
  scroll-margin-top: 6%;
  margin-top: 5%;
}
#root .modc .modal-header .list2 span {
  font-size: 26px !important;
  font-weight: 500;
  color: #fff;
}
#root .modc .modal-header .product_close {
  width: 10%;
  float: right;
  margin-right: 0;
}
#root .modc .modal-header {
  margin-bottom: 0%;
}
.modc .form-group.mg-left2 .form-control.inputclass {
  width: 50% !important;
  height: auto;
  margin-left: 0;
}
.editMessage_close {
  margin-right: 30%;
  margin-top: 5%;
}
.form-row.mg-left.add_user .col-md-6.mb-4 input:focus,
.form-row.mg-left.add_user .col-md-6.mb-4 input:-internal-autofill-selected {
  box-shadow: none !important;
  background: #121212 !important;
}
.fas.fa-times {
  color: #fff;
}
.label {
  margin-top: 30px;
  margin-left: 40px;
}
.label p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.label input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.label span {
  color: red;
  margin-left: -160px;
}
.labels1 {
  margin-top: 30px;
  margin-left: 40px;
}
.labels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labels1 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labels2 {
  margin-top: 30px;
  margin-left: 40px;
}
.labels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labels2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.la3 {
  margin-left: 400px;
  margin-top: -265px;
}
.la3 i {
  margin-left: -30px;
}
.labeel2 {
  margin-left: 400px;
  margin-top: -262px;
}

.labeel2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labeel2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.labeel2 ::placeholder {
  color: black;
}

.la3 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.la3 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.la3 ::placeholder {
  color: black;
}
.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;
}
.labl1 {
  margin-left: 400px;
  margin-top: 30px;
}
.labl1 i {
  margin-left: -30px;
}

.labl1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labl1 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels1 {
  margin-left: 400px;
  margin-top: 30px;
}
.labeels1 i {
  margin-left: -30px;
}

.labeels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labeels1 select {
  margin-top: -20px;
  width: 300px;
  height: 60px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.labl2 {
  margin-left: 400px;
  margin-top: 30px;
}
.labl2 i {
  margin-left: -30px;
}

.labl2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labl2 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.labeels2 ::placeholder {
  color: black;
}
.labeels2 {
  margin-left: 400px;
  margin-top: 30px;
}
.labeels2 i {
  margin-left: -30px;
}

.labeels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.labeels2 select {
  margin-top: -20px;
  width: 300px;
  height: 50px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}
.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;
}
.addbtn {
  margin-top: 50px;
}
.addbtn button {
  height: 34px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 0px;
  font-size: 12px;
  width: 130px;
}
.pgg {
  margin-top: 300px;
}
.pgg button {
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 30px;
  width: 180px;
}
.fbox {
  margin-left: 220px;
  margin-top: 30px;
}
.fbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.sbox {
  margin-top: -55px;
  margin-left: 450px;
}
.sbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.sbox select {
  width: 195px;
  height: 32px;
  background: #eceaed 0% 0% no-repeat padding-box;
  border: 1px solid #4b384c;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}
.nbox {
  margin-top: -55px;
  margin-left: 680px;
}
.nbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.nbox select {
  width: 195px;
  height: 32px;
  background: #eceaed 0% 0% no-repeat padding-box;
  border: 1px solid #4b384c;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.actice2 {
  border-bottom: 2px solid #eb951f !important;
}
.actice2 a {
  color: #eb951f !important;
}
.rbox {
  margin-left: 700px;
  margin-top: -55px;
}
.rbox span {
  text-align: left;
  font: normal normal medium 24px/32px Roboto;
  letter-spacing: 0px;
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}
.rbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.rbox select {
  width: 195px;
  height: 32px;
  background: #eceaed 0% 0% no-repeat padding-box;
  border: 1px solid #4b384c;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.modc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 500px;
  margin-left: 51%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 600px;
  margin-top: 0;
}
.show {
  display: block;
}
.hide {
  display: none;
}

.c input {
  width: 18px;
  height: 18px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  opacity: 1;
}

.c1 button {
  color: #eb951f;

  height: 33px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
}

.row {
  margin-right: 0px !important;
}

.customrow {
  border-bottom: 1px solid #595959;
  width: 98%;
}
.a {
  color: #000000 !important;
}
.rightclass {
  float: right !important;
}

.moc {
  position: relative;
  display: inline-block;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 750px;
  pointer-events: auto;
  background-color: #121212;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: auto;
  margin-top: 55px;
  /* float: right; */
  margin-right: 0;
  margin-left: auto;
}
.Ap {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap span {
  margin-left: -210px;
  color: red;
}
.Ap1 {
  margin-left: 400px;
  margin-top: -35px;
}
.Ap1 span {
  margin-left: -210px;
  color: red;
}
.Ap2 {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap2 span {
  margin-left: -190px;
  color: red;
}
.Ap2 i {
  margin-left: 150px;
}
.Ap3 {
  margin-left: 400px;
  margin-top: -35px;
}
.Ap3 span {
  margin-left: -220px;
  color: red;
}
.Ap4 {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap4 span {
  margin-left: -170px;
  color: red;
}
.Ap5 {
  margin-left: 400px;
  margin-top: -35px;
}
.Ap5 span {
  margin-left: -210px;
  color: red;
}
.Ap6 {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap6 span {
  margin-left: -235px;
  color: red;
}
.Ap7 {
  margin-left: 400px;
  margin-top: -35px;
}
.Ap8 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap9 {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap9a {
  margin-left: 40px;
  margin-top: 30px;
}
.Ap10 {
  margin-left: 400px;
  margin-top: -355px;
}
.Ap10a {
  margin-left: 400px;
  margin-top: 30px;
}
.Ap11 {
  margin-top: 120px;
  padding-right: 590px;
  padding-bottom: 30px;
}
.Ep {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep h6 span {
  color: red;
}
.Ep input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep input::placeholder {
  color: black;
}
.prdc {
  margin-left: 30px;
  margin-top: 40px;
}
.prdc h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.prdc h6 span {
  color: red;
}
.prdc input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.prdc input::placeholder {
  color: black;
}
.Ep {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep h6 span {
  color: red;
}
.Ep input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep input::placeholder {
  color: black;
}
.Ep1 {
  margin-left: 400px;
  margin-top: -47px;
}
.Ep1 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep1 h6 span {
  color: red;
}
.Ep1 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep1 input::placeholder {
  color: black;
}
.Ep2 {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep2 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep2 h6 span {
  color: red;
}
.Ep2 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep2 input::placeholder {
  color: black;
}
.Ep2 i {
  margin-left: -20px;
}
.Ep3 {
  margin-left: 400px;
  margin-top: -47px;
}
.Ep3 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep3 h6 span {
  color: red;
}
.Ep3 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep3 input::placeholder {
  color: black;
}
.Ep4 {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep4 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep4 h6 span {
  color: red;
}
.Ep4 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep4 input::placeholder {
  color: black;
}
.Ep5 {
  margin-left: 400px;
  margin-top: -47px;
}
.Ep5 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep5 h6 span {
  color: red;
}
.Ep5 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep5 input::placeholder {
  color: rgb(250, 250, 250);
}
.Ep6 {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep6 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep6 h6 span {
  color: red;
}
.Ep6 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep6 input::placeholder {
  color: black;
}
.Ep7 {
  margin-left: 400px;
  margin-top: -47px;
}
.Ep7 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep7 h6 span {
  color: red;
}
.Ep7 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep7 input::placeholder {
  color: black;
}
.Ep8 {
  margin-top: 30px;
  margin-left: 40px;
}
.Ep8 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep8 h6 span {
  color: red;
}
.Ep8 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep8 input::placeholder {
  color: black;
}
.Ep9a {
  margin-left: 40px;
  margin-top: 40px;
}

.Ep9a h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep9a h6 span {
  color: red;
}
.Ep9a input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep9a input::placeholder {
  color: black;
}
.Ep9 {
  margin-left: 40px;
  margin-top: 40px;
}

.Ep9 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep9 h6 span {
  color: red;
}
.Ep9 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep9 input::placeholder {
  color: black;
}
.Ep10a {
  margin-left: 400px;
  margin-top: 40px;
}
.Ep10a h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep10a h6 span {
  color: red;
}
.Ep10a input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep10a input::placeholder {
  color: black;
}
.Ep10 {
  margin-left: 400px;
  margin-top: -488px;
}
.Ep10 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Ep10 h6 span {
  color: red;
}
.Ep10 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Ep10 input::placeholder {
  color: black;
}
.Ep11 {
  margin-left: 40px;
  padding-bottom: 30px;
}
.Ep11 button {
  height: 32px;
  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}
/* add group */
.Addgroup {
  margin-top: 32px;
  margin-left: 220px;
  padding-right: 30px;
}
.Addgroup i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
}
.Addgroup input {
  height: 34px;
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}
.Addgroup button {
  width: 180px;
  height: 40px;
  float: right;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
}
.rows {
  margin-top: -440px;
}
.rowcols {
  margin-left: 940px;
}
.Apg {
  margin-left: 40px;
  margin-top: 30px;
}

.Apg span {
  margin-left: -165px;
  color: red;
}
.Apg1 {
  padding-right: 530px;
  margin-top: 400px;
}
.Epg {
  margin-top: 30px;
  margin-left: 40px;
}
.Epg h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Epg h6 span {
  color: red;
}
.Epg input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Epg input::placeholder {
  color: black;
}

.Epg1 {
  margin-left: 40px;
  margin-top: 400px;
}
.Epg1 button {
  height: 32px;

  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}
.rc {
  margin-left: 300px;
  margin-top: -500px;
}
.Amg {
  margin-left: 40px;
  margin-top: 30px;
}
.Amg span {
  color: red;
  margin-left: -200px;
}
.Amg i {
  margin-left: 160px;
}
.Amg1 {
  margin-left: 40px;
  margin-top: 30px;
}
.Amg1 input {
  width: 600px;
}
.Amg2 {
  margin-left: 40px;
  margin-top: 30px;
}
.Amg2 input {
  width: 600px;
}
.Amg3 {
  padding-right: 580px;
  margin-top: 200px;
  padding-bottom: 30px;
}
.Emg {
  margin-top: 30px;
  margin-left: 40px;
}
.Emg input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}
.Emg i {
  margin-left: -30px;
}
.Emg input::placeholder {
  color: black;
}
.Emg span {
  color: red;
}
.Emg h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Emg1 {
  margin-left: 40px;
  margin-top: 30px;
}
.Emg1 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Emg1 input {
  width: 600px;
  border: none;
  border-bottom: 1px solid grey;
}
.Emg1 input::placeholder {
  color: black;
}
.Emg2 {
  margin-left: 40px;
  margin-top: 30px;
}
.Emg2 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}
.Emg2 input {
  width: 600px;
  border: none;
  border-bottom: 1px solid grey;
}
.Emg2 span {
  color: black;
}
.Emg3 {
  padding-bottom: 30px;
  margin-left: 40px;
  margin-top: 200px;
}
.Emg3 button {
  height: 32px;
  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}
.mo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 268px;
  margin-left: auto;
  margin-right: 0;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  height: 161px;
  margin-top: 65px;
}
.mo .header {
  padding: 13px 24px 6px 12px;
}
.list2 span i.fa-user-circle {
  color: #fff;
  float: left;
  width: 20%;
  font-size: 100%;
  float: left;
}
.list2 span p {
  color: #fff;
  margin: 0;
  font-size: 18px;
  margin-top: 0;
  font-weight: 400;
  width: 80%;
  float: left;
}
.list2 span .v {
  color: #eb951f;
  font-size: 12px;
}
.list2 span .v i {
  color: #eb951f;
  font-size: 12px;
  margin-left: 10px;
}

.menucolor {
  color: #fa9f22 !important;
  font-weight: 600 !important;
}

.noactive {
  color: #828282 !important;
}

.label_message {
  margin-top: -20px;
  width: 50%;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.label1_message {
  width: 90%;
}

.label2_message {
  width: 90%;
}

.log {
  margin-left: 30px;
  margin-top: -5px;
  color: #cf6679;
  cursor: pointer;
}
.log p {
  margin-left: 10px;
  /* margin-top: -3px; */
  margin-bottom: 0;
  font-size: 14px;
  float: left;
}

.titleshow {
  font: normal normal medium 32px/43px Roboto !important;
}

.inputclass {
  width: 100% !important;
  border-radius: 0px !important;
  background-color: #121212 !important;
}

.inputMarging {
  margin-left: 15px !important;
  margin-right: 15px;
}
.mg-left2 {
  margin-left: 40px !important;
  margin-right: 40px !important;
  margin-bottom: 40px !important;
}

.fas fa-times {
  color: #ffffff !important;
}
.productInput {
  background-color: #121212 !important;
}

.Ap11 button {
  background-color: #fa9f22 !important;
}

.fas fa-times {
  color: #fff !important;
}

.fa fa-search {
  color: #fff !important;
}
.btn-link {
  color: #fff !important;
}

#root div .content span {
  color: #fff;
  font-weight: 500;
}
#root div .content .add_product span {
  font-size: 26px !important;
}
.colrow {
  margin-top: 0 !important;
}
.colrow .row .col-sm {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #fff;
  padding-left: 4%;
}
.row.customrow .col {
  color: #595959;
  margin: 1% auto;
}
.row.customrow:first-child {
  margin-bottom: 1%;
  margin-top: 3%;
}
.row.customrow .col-sm {
  padding-left: 1.5%;
}
.row.customrow {
  margin-left: 23px;
  display: inline-block;
}
.close_icon {
  float: right;
  width: auto;
  margin-top: 3%;
  margin-right: 5%;
}
.colrow.grp .row.customrow:first-child {
  margin-top: 3%;
  height: auto;
}
.form-group.mg-left2 label {
  color: #828282;
  font-size: 12px;
}
.fas.fa-ellipsis-h {
  padding-top: 0 !important;
}
.detail_wrapper .modal.show .modal-dialog {
  width: 40%;
  float: left;
  max-width: 100%;
}
.modal.show .modal-dialog {
  width: 100%;
  float: left;
  max-width: 100%;
}
.list .moc .form-row.mg-left input,
.list .moc .form-row.mg-left .dropbtn1111 {
  width: 90%;
  height: 34px;
}
.list .moc .form-row.mg-left button {
  margin: 25% 0;
}
.moc .modal-header {
  margin-bottom: 1%;
}
.moc .form-row.mg-left .col-md-6 label {
  display: inline-block;
  margin-bottom: 0;
  line-height: 14px;
  font-size: 10px;
  color: #fff;
  height: auto;
}
.moc .form-row.mg-left {
  margin-bottom: 3% !important;
}
.row.customrow.product-col .col {
  font-size: 12px;
}
.colrow .row.customrow:first-child {
  display: inline-block;
  /* float: left; */
}
.colrow .row.customrow:first-child .col-sm {
  /* width: 20%; */
  float: left;
}
/* .colrow .row.customrow:first-child .col-sm:nth-child(2){
  width:18%;
}
.colrow .row.customrow:first-child .col-sm:nth-child(3){
  width:27%;
} */
.colrow.product .row.customrow:first-child .col-sm:nth-child(1),
.colrow.product .row.customrow:first-child .col-sm:nth-child(2),
.colrow.product .row.customrow:first-child .col-sm:nth-child(3),
.colrow.product .row.customrow:first-child .col-sm:nth-child(4),
.colrow.product .row.customrow:first-child .col-sm:nth-child(5) {
  width: 20%;
  padding: 0 1%;
}
.manage-product .row.customrow.product-col .col.warranty-col,
.manage-product .colrow.product .row.customrow .col-sm:nth-child(5) {
  width: 10%;
  display: flex;
  align-items: baseline;
  gap: 95px;
}

.manage-product .row.customrow.product-col .col.warranty-col .span {
  width: 70%;
  float: left;
}
.manage-product .row.customrow.product-col .col.warranty-col .col {
  width: 30%;
  float: right;
}
/* .colrow.product .row.customrow:first-child .col-sm:nth-child(6){
  width: 16.66%;
  padding: 0 1%;
} */
.manage-product .row.customrow.product-col .col {
  width: 20%;
}
.manage-product .row.customrow.product-col .col:first-child,
.manage-product .colrow.product .row.customrow .col-sm:first-child {
  width: 27% !important;
}
.manage-product .row.customrow.product-col .col.image-url span {
  display: block;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fa9f22;
}
/* .manage-product .row.customrow.product-col .col:nth-child(4){

} */
.modc .form-row.mg-left2 .col-md-6.mb-6 {
  padding: 0;
}
.modc .form-row.mg-left2 .col-md-6.mb-6 label,
.modc .form-group.mg-left2 label {
  display: inline-block;
  color: #828282;
  font-size: 12px;
  margin-bottom: 0.5rem;
  height: auto;
}
.colrow.message_wrapper .row.customrow {
  display: inline-block;
  /* float: left; */
}
.colrow.message_wrapper .row.customrow .col-sm,
.colrow.message_wrapper .row.customrow .col {
  width: 22%;
  float: left;
}
.add_msg_close {
  margin-right: 35%;
  margin-top: 4%;
}
.moc .form-row.mg-left.add_product_form:nth-child(2) .col-md-6:first-child {
  margin-top: 0;
}
.mdcolor {
  color: #ee3253 !important;
}
.manage-product,
.product-group,
.manage-message {
  width: 100%;
  float: left;
}

.product-group .content span,
.manage-message .content span {
  font-size: 26px !important;
}
.manage-product .content,
.product-group .content,
.manage-message .content {
  margin-bottom: 0%;
}
.manage-product .colrow.product,
.product-group .colrow.grp,
.manage-message .colrow.message_wrapper {
  border-left: 1px solid #595959;
  height: 330px;
}
.manage-product .colrow.product .row.customrow:first-child .col-sm,
.product-group .colrow.grp .row.customrow:first-child .col-sm,
.manage-message .colrow.message_wrapper .row.customrow:first-child .col-sm {
  padding: 0 2%;
  padding-left: 0;
}
.manage-product .row.customrow.product-col .col,
.product-group .row.customrow .col,
.manage-message .row.customrow .col {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
  margin: 0;
  text-transform: capitalize;
  overflow-wrap: break-word;
  padding: 1% 1;
}
.manage-product .dropdown-menu,
.product-group .dropdown-menu {
  background-color: #000;
  transform: translate3d(-19%, -60%, 0px) !important;
}
.list2.add_product span {
  font-weight: 500;
}
.manage-product .form-row.mg-left button {
  height: 34px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 6.5%;
  width: 150px;
}
.manage-product .content span.mdcolor {
  font-size: 17px !important;
}
.product-group .list2 {
  margin-left: 23px;
}
.product-group .modc {
  width: 50%;
  float: right;
}
.product-group .modc .edit_close {
  margin-top: 5%;
  margin-right: 6%;
}
.product-group .addbtn button {
  margin-left: 9.5%;
}
.product-group .modc .form-group.mg-left2 .form-control.inputclass {
  height: 33px;
}
.manage-message .dropdown-menu.show {
  background: #000;
  transform: translate3d(0, 0, 0px) !important;
  border-radius: 10px;
  top: -5em !important;
}
.manage-message .modc {
  width: 50%;
  float: right;
}
.manage-message .modc .form-row.mg-left2 .col-md-6.mb-6 {
  max-width: 80%;
  flex: 0 0 70%;
}
.manage-message .modc .form-group.mg-left2 .form-control.inputclass {
  width: 70% !important;
}
.manage-message .editMessage_close {
  margin-right: 10%;
  margin-top: 7%;
}
.colrow.message_wrapper .row.customrow {
  margin-top: 0;
}
.colrow.message_wrapper .row.customrow .col p {
  margin-bottom: 0;
}
.colrow.message_wrapper .row.customrow:first-child {
  margin-top: 3%;
}
.colrow.message_wrapper .row .col .dropdown {
  margin-top: -5.5% !important;
  float: right;
  text-align: right;
}
.manage-product .content .errorpd .errorShow,
.product-group .content .errorpd .errorShow,
.manage-message .content .errorpd .errorShow {
  font-size: 14px !important;
}

.product-component-main .list input[type="username"],
.group-component-main .list input,
.message-component-main .list input {
  box-shadow: none;
  outline: none;
  line-height: 1;
}
.product-component-main .list button {
  width: 149px;
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  display: none;
}
.product-component-main .moc,
.group-component-main .modc,
.message-component-main .modc {
  position: fixed;
  margin-left: 47%;
}
.message-component-main .modc {
  margin-top: 0;
}
.product-component-main #add_form {
  height: 700px;
  overflow-y: scroll;
}
.product-component-main .colrow.product {
  height: 500px;
}

.group-component-main .list button.btn-add-grp {
  width: 149px;
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  display: none;
}
.group-component-main .modal-dialog,
.message-component-main .modal-dialog,
.message-component-main .modal-dialog {
  margin: 0;
  margin-top: 6%;
}
/* .group-component-main .modc{
  height: 700px !important;
} */
.group-component-main .addbtn button {
  width: 149px;
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  margin-left: 6%;
  margin-top: 40%;
}
.group-component-main .colrow.grp {
  height: 500px;
}
.group-component-main .colrow .row .col .dropdown {
  float: right;
  margin-top: -5%;
}

.message-component-main .list .add-message-btn {
  width: 149px;
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
}

.message-component-main .list .add-message-btn {
  width: 149px;
  height: 30px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
}
.message-component-main .colrow.message_wrapper .row.customrow .col {
  width: 33.33%;
}
.message-component-main .colrow.message_wrapper {
  height: 500px;
}
.message-component-main
  .colrow.message_wrapper
  .row.customrow:first-child
  .col-sm {
  width: 33.33%;
}
canvas.message-component-main .modc .addbtn button {
  width: 149px;
  height: 45px;
  background: #fa9f22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  font: normal normal medium 16px/21px Roboto;
  letter-spacing: 0.02px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  margin-left: 6%;
  margin-top: 20%;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.1s ease-out;
  -o-transition: -o-transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  /* -webkit-transform: translate(0, 100%) rotate(180deg) !important;
      -ms-transform: translate(0, 100%) rotate(180deg) !important;
       -o-transform: translate(0, 100%) rotate(180deg) !important;
          transform: translate(0, 100%) rotate(180deg) !important; */
  -webkit-transform: translate3d(-125%, 0, 0) !important;
  transform: translate3d(-125%, 0, 0) !important;
  -ms-transform: translate3d(-125%, 0, 0) !important;
  -o-transform: translate3d(-125%, 0, 0) !important;
}
.modal.show .modal-dialog {
  -webkit-transform: translateY(50%) rotate(0deg) scale(1) !important;
  -ms-transform: translateY(50%) rotate(0deg) scale(1) !important;
  -o-transform: translateY(50%) rotate(0deg) scale(1) !important;
  transform: translateY(50%) rotate(0deg) scale(1) !important;
}
.detail_wrapper .modal.show .modal-dialog {
  -webkit-transform: translate(75%, 0%) rotate(0deg) scale(1, 1) !important;
  -ms-transform: translate(75%, 0%) rotate(0deg) scale(1, 1) !important;
  -o-transform: translate(75%, 0%) rotate(0deg) scale(1, 1) !important;
  transform: translate(75%, 0%) rotate(0deg) scale(1, 1) !important;
}
.edit-product-modal {
  height: 500px;
  overflow: auto;
  padding-bottom: 50px;
}
.edit-product-modal input,
.edit-product-modal select {
  outline: none;
}
.acolor {
  color: #eb951f;
}
.acolor:hover {
  color: #eb951f;
}
.acolor:active {
  color: #eb951f;
}
.edit-product-modal .form-row.mg-left .col-md-6.pro_name {
  margin-top: -2px;
}
.edit-product-modal .form-row.mg-left .col-md-6.troubleshoot,
.edit-product-modal .form-row.mg-left .col-md-6.warr {
  margin-top: -10px;
}
.no-result-text {
  padding-left: 23px;
}
.manage-product .content .list input[type="username"] {
  width: 300px;
}
.row customrow product-col .col {
  margin-top: 1%;
}

.messageContent {
  border: 1px solid green;
}
/* 
.list input::placeholder {
  color: #fff;
  background: none;
} */

.searchProduct {
  float: right;
  margin-top: 0;
  line-height: 40px;
  width: auto;
  border-bottom: 1px solid grey;
  margin-left: 0%;
  margin-right: 2%;
}

.searchProduct input {
  border: none;
  width: auto;
  font-size: 12px;
  color: #fff;
  margin-left: 0;
  background: #121212 !important;
}

.searchProduct input::placeholder {
  color: #fff;
  background: none;
}

/* .list input::placeholder {
  color: #fff;
  background: none;
} */

/* .list input::placeholder {
  color: #fff;
  background: none;
} */

.search_header_product {
  display: flex;
  justify-content: space-between;
}

.search_header_product span {
  font-size: 24px !important;
}

.add-new-pruduct-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-new-pruduct-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.product-name-continer {
  display: flex;
  justify-content: space-between;
}

.product-name-continer input::placeholder {
  background-color: transparent !important;
  color: gray !important;
}

.product-name-continer input {
  width: 32%;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid gray;
  color: white;
  padding: 0px 10px 5px 3px;
  color: white;
}

.product-name-continer select {
  width: 32%;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: white;
  gap: 10px;
  color: white;
}

.product-variant-continer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid #444343;
  padding: 20px;
  border-radius: 10px;
}

.product-variant-continer input::placeholder {
  background-color: transparent !important;
  color: gray !important;
}

.product-variant-continer input {
  width: 23%;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid gray;
  color: white;
  padding: 0px 10px 5px 3px;

  margin-bottom: 20px;
}

.product-variant-continer select {
  width: 23%;
  background-color: #121212 !important;
  border: none;
  border-bottom: 1px solid gray;
  color: white;
  gap: 10px;
  color: white;
  margin-bottom: 20px;
  background-color: #000;
}

.product-variant-continer .add-product-button {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;
}

.product-variant-continer .add-product-button button {
  width: 13%;
  background-color: #eb951f;
  color: white;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
}
.product-body {
  width: 100%;
  border: 1px solid gray;
  padding: 10px;
}

.product-body tr {
  width: 100%;
  display: flex;
}

.product-body td {
  width: 12%;
  color: white;
  padding: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.product-submit-add {
  display: flex;
  width: 100%;
  justify-content: end;
}

.product-submit-add button {
  width: 13%;
  background-color: #eb951f;
  color: white;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
}

.loader-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-control::placeholder {
  background-color: #121212 !important;
  color: white !important;
}
.add-color label {
  color: white;
}

.add-product-color {
  margin-top: 8%;
}

.modal-head-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-head-product i {
  color: white;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
}

.remove-product-button button {
  background-color: #000;
  border: 1px solid gray;
  padding: 3px 10px;
  border-radius: 5px;
  color: red;
  cursor: pointer;
}

.text-wrap {
  word-break: break-all;
  white-space: normal;
  text-align: center; /* Optional: to align the text centrally */
  height: auto !important;
  line-height: 20px !important;
}

.add-color-product {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.add-color-product button {
  border-radius: 8px;
  background: rgb(235, 149, 31);
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
}

.content-for-add-product {
  border-top: 1px solid #595959;
  /* margin-top: 6%; */
  width: 83%;
  padding: 1% 0 0 1.5%;
  float: right;
  padding-right: 3%;
  margin-bottom: 2%;
}

.back-button-product {
  display: flex;
  background: transparent;
  color: white;
  margin-bottom: 2%;
}

.button-channels {
  display: flex;
  justify-content: end;
}

.button-channels button {
  background-color: #eb951f;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
}

.channel-edit{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
