#root {
  background: #121212;
  width: 100%;
  float: left;
}

.sidebar {
  width: 17%;
  float: left;
  height: auto;
  top: auto;
  left: auto;
  margin-top: 6%;
  background: #121212;
}

/* .sidebar img {
  margin: 15px;
  width: 150px;
  margin-left: 20px;
} */

.sidebar-container {
  margin: 15px 20px;
  text-align: left;
  opacity: 1;
  background: #121212;
}

.sidebar-container a {

  display: block;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0 12px;
}

.sidebar-container span {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 15px;
  height: 18px;
  text-align: left;
  font-size: 13px;
  line-height: 50px;
  letter-spacing: 0px;
  color: #8e8e8e;
  opacity: 1;
  font-weight: 500;
}

.sidebar-container i {
  color: #8e8e8e;
}

.nav {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  margin-top: 2%;
}

.nav.dashboard {
  width: 50% !important;
  display: inline-block;
  margin: 0 auto;
  margin-top: 2%;
  right: 0 !important;
  margin-right: 0 !important;
}

.nav span {
  margin-left: 78%;
  color: white;
  float: right;
}

.nav .nav-logo {
  margin: 0;
  float: left;
  width: 10%;
  padding-left: 2%;
}

.nav .nav-logo img {
  width: 100%;
}

.nav span i {
  font-size: 19px;
  padding-right: 40px;
}

.content.complaint-main.read-only {
  border: 0 !important;
  margin-top: 2%;
  width: 100%;
  padding: 1% 0 0 1.5%;
  float: right;
  padding-right: 3%;
  margin-bottom: 2%;
  border-top: 1px solid #fff !important;
  border-radius: 0;
}

.content span {
  height: 43px;
  text-align: left;
  font-size: 32px;
  line-height: 43px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content .complaint-head {
  color: #fff;
  font-size: 26px !important;
  font-weight: 500;
}

ul#menu {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
  padding-left: 0%;
  margin-bottom: 3%;
}

ul#menu li {
  display: block;
  text-align: center;
  padding: 20px 9px 32px;
  text-decoration: none;
  height: 19px;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #999999;
  opacity: 1;
  font-weight: 500;
  float: left;
}

ul#menu li:hover {
  cursor: pointer;
}

ul#lim li {
  display: block;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  height: 19px;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #999999;
  opacity: 1;

}

ul#lim li:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

ul#lim {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  background: none;
  width: 390px;
  border-bottom: 1px solid grey;

}

ul#lim li {
  float: left;
}

.selectedMenu {
  color: #eb951f !important;
  border-bottom: 2px solid #eb951f;
}

.sidebar p {
  margin-left: 130px;
}

/* .dropdown h6 {
  top: 211px;
  left: 220px;
  width: 103px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
} */

/* #st-box {
  float: left;
  width: 180px;
  height: auto;
  margin-right: 30px;
  margin-bottom: 3%;
} */

/* #nd-box {
  float: left;
  width: 195px;
  height: 32px;
  margin-right: 15px;
  margin-bottom: 3%;
} */

/* #nd-box button {
  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;

} */

/* #nd-box i {
  margin-left: 110px;
  font-size: 12px;
} */


#rd-box {
  float: left;
  width: 250px;
  height: auto;
  margin-bottom: 3%;
}

/* #rd-box input {
  border: none;
} */

/* #rd-box button {

  width: 195px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid gray;
} */

#rd-box i {
  font-size: 12px;
  margin-left: 100px;
} *
/* 
#dd-box {
  margin-top: -2px;
  float: left;
  width: 12%;
} */

/* #dd-box button {
  background: #000;
  color: #fff;
  height: 34px;
  border: 1px solid gray;
  border-radius: 100px;
  opacity: 1;
  margin-left: 0;
  width: 90%;
  font-size: 14px;
} */

/* #dd-box .more6 button {
  margin-left: 8%;
} */

/* #dd-box button .fa-filter {
  margin-left: 12%;
  color: #fff;
} */

/* #st-box h6,
#nd-box h6,
#rd-box h6 {
  color: #999999;
  font-size: 14px;
} */

/* .dropbtn {
  top: 232px;
  left: 220px;
  width: 195px;
  height: 32px;
  padding: 0 15px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  outline: none;
} */

/* .dropbtn i {
  padding-left: 40px;
} */

/* .dropdown .dropdown {
  padding: 100px;
  margin-left: 120px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
} */

/* .dropdown h6 {
  margin-top: 30px;
} */
/* 
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 198px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
} */

/* .dropdown-content a {
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
} */


/* .dropdown:hover .dropdown-content {
  display: block;
} */

.read-only .search_title {
  color: #fff;
  text-align: left;
  float: left;
  width: 100%;
  margin: 3% 0;
  font-weight: 500;
  font-size: 20px;
}

.my_ticket_title {
  color: #fff;
  text-align: left;
  float: left;
  width: 100%;
  /* margin-top: 1%; */
  font-weight: 500;
  font-size: 20px;
}

.menu_wrapper {
  width: 100%;
  float: left;
}

.read-only .search {
  float: left;
  margin-top: 0;
  line-height: 40px;
  width: auto;
  border-bottom: 1px solid grey;
  margin-left: 0%;
  margin-right: 2%;
}

.search.searchServiceCenter {
  float: left;
  margin: 2% 0;
}

.search .fa-search {
  color: #fff;
  font-size: 12px;
}

.read-input .search input {
  border: none;
  width: auto;
  font-size: 14px;
  color: #fff;
  margin-left: 0;
  background: #121212 !important;
}

.search input:-internal-autofill-selected {
  background: #121212 !important;
}

.search input::placeholder {
  color: #fff;
  background: none;
}

.list-container {
  width: 100%;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 360px;
  padding-right: 2%;
  margin-right: 0;
}

.container6 {
  padding: 12px;
  margin: 10px 0;
  left: 220px;
  width: 100%;
  height: auto;
  float: left;
  background: #121212;
  border: 1px solid #595959;
  border-radius: 10px;
  opacity: 1;
}

#root .container6 .box .handclass span,
#root .container6 .box .box1 span,
#root .container6 .box .box2 span {
  font-size: 14px !important;
}

#root .container6 .box .handclass span.upg,
#root div .content .container7 .upg {
  color: rgb(235, 149, 31);
  width: 100%;
  line-height: 12px;
  float: left;
  margin-top: 3%;
  font-size: 11px !important;
  margin: 3% 0;
  height: auto;
}

.container7 {
  padding: 10px !important;
  margin: 10px !important;
  height: auto !important;
  left: 0 !important;
  width: 100% !important;
  float: left !important;
}

.container7:first-child {
  margin-top: 0;
}

.container7.activeColor {
  border: 1px solid #eb951f !important;
  background: #000 !important;
}

.container1 {
  padding: 20px;
  left: 220px;
  width: 1050px;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-left: -15px;
  margin-top: 20px;
}

.container2 {
  padding: 20px;
  left: 220px;
  width: 1060px;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
  margin-left: -15px;
  margin-top: 10px;

}

.Fno-result-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-result-text p {
  color: #fff;
  margin-top: 2%;
}

.active {
  margin-left: 0;
  top: 306px;
  left: 342px;
  width: 74px;
  height: 21px;
  border-radius: 100px;
  opacity: 1;
  color: #4AC0B1;
  font-size: 12px;
  border: #999;
  background: transparent;
}

.Et .active {
  margin-left: 0;
  float: left;
  padding-left: 0;
  left: 0;
  width: auto;
}

.box h6 {
  top: 306px;
  left: 232px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #fff;
}

.box {
  width: 100%;
  margin: 0 auto;
  float: left;
}

.box span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box p {
  top: 366px;
  left: 232px;
  margin-top: 7px;
  height: 16px;
  text-align: left;
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  font-weight: 300;
  margin-bottom: 0;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  height: auto !important;
  margin: 0 auto !important;
  width: 100% !important;
  float: left !important;
}

.read-only .box1 {
  width: 33%;
  float: left;
  margin: 0 auto;
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  padding: 0 0 10px 35px;
}

.box1 h6 {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
  height: inherit;
}

.box .box1 span {
  top: 335px;
  left: 545px;
  height: 38px;
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 12px !important;
  font-weight: 300;
}

.box2 {
  width: 25%;
  float: left;
  margin: 0 auto;
  padding-left: 35px;
}

.box2 h6 {
  top: 306px;
  left: 922px;
  height: 100% !important;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box2 span {
  top: 335px;
  left: 923px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.box2 p {
  top: 366px;
  left: 922px;
  height: 16px;
  text-align: left;
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1;
}

.box3 {
  width: 25%;
  float: left;
  margin: 0 auto;
  color: #eb951f;
  text-align: right;
  padding-right: 2%;
}

.box3 i {
  padding: 10px;
  padding-top: 0;
  font-size: 13px;
}

.box3 p {
  top: 363px;
  left: 1239px;
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  margin-left: 110px;
  margin-top: 30px;
  text-align: right;
}

.pages {
  margin-top: 10px;
  float: left;
}

.pages p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-left: 140px;
  margin-top: -22px;
}

.pages span {
  margin-left: 10px;

  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;


}

.pages select {
  margin-left: 20px;
  /* color:black; */
  width: 56px;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}



/* edit ticket */
.mod {
  position: relative;
  display: inline-block;
  /* float: right; */
  width: 760px;
  pointer-events: auto;
  background-clip: padding-box;
  background: #121212;
  border-radius: 0.3rem;
  outline: 0;
  height: 650px;
  margin-top: 65px;
  margin-right: 0;
  overflow: auto;
}

.Et {
  margin-top: 5%;
  margin-left: 20px;
  width: 100%;
  float: left;
}

.modal-header {
  margin-bottom: 5%;
}

.modal-header .Et span {
  color: #fff;
  padding-right: 3%;
  float: left;
  height: auto;
  line-height: 30px;
  font-size: 30px !important;
  font-weight: 500;
  padding-left: 5%;
  margin-bottom: 5%;
}

.close_assign {
  margin-right: 18%;
  margin-top: 5%;
}

.modal-header .Et span.s {
  padding-left: 3%;
  float: left;
  height: auto;
  border-right: none;
  border-left: 1px solid #fff;
  margin-bottom: 5%;
}

.lbl,
.lbl2,
.lbl3,
.lbl4,
.lbl5,
.lbl6,
.lbl7,
.lbl8,
.lbl9,
.lbl10,
.lbl11,
.lbl12 {
  width: 47%;
  margin: 0;
  float: left;
  padding-left: 5%;
  margin-bottom: 5%;
}

.lbl3,
.lbl6,
.lbl7 {
  width: 94%;
}

.lbl p,
.lbl2 p,
.lbl3 p,
.lbl4 p,
.lbl5 p,
.lbl6 p,
.lbl7 p,
.lbl8 p,
.lbl9 p,
.lbl10 p,
.lbl11 p,
.lbl12 p {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  width: 72px;
  opacity: 1;
  margin-left: 0;
  padding-top: 15px;
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.lbl7 p.second-child {
  margin-top: 2%;
}

.lbl input,
.lbl2 input,
.lbl3 input,
.lbl4 input,
.lbl5 input,
.lbl6 input,
.lbl7 input,
.lbl8 input,
.lbl9 input,
.lbl10 input,
.lbl11 input,
.lbl12 input {
  width: 100%;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #2D2E2D;
  opacity: 1;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  float: left;
  background: transparent;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 3%;
  padding-left: 0;
}

.lbl6 input {
  width: 46%;
}

.lbl3 input,
.lbl6 input,
.lbl7 input {
  padding-bottom: 1.5%;
}

.lbl7 input::placeholder,
.lbl8 input::placeholder,
.lbl9 input::placeholder,
.lbl10 input::placeholder,
.lbl11 input::placeholder {
  color: #fff;
}

.lbl i,
.lbl2 i,
.lbl9 i,
.lbl10 i {
  margin-left: -40px;
  color: black;
}

.lbl12 {
  width: 100%;
  float: left;
  text-align: left;
}

.lbl13.update_ticket {
  margin-left: 36px;
  margin-top: 2%;
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 5%;
}

.lbl13 button {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
}

/* more design */

.modf {
  background: #121212 !important;
  position: relative;
  display: inline-block;
  /* float: left; */
  width: 380px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
  height: 700px;
  margin: 0;
  /* float: right; */
}

.mofd {
  border-radius: 20px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 460px;
  background-color: white;
  margin-left: 420px;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  outline: 0;
  height: 700px;
  margin-top: 65px;
}

.modal i {
  font-size: 17px;
}

.modal i.fa-power-off {
  float: left;
}

.more4 {
  margin-top: 30px;
  margin-left: 30px;
  width: 100%;
  float: left;
}

.more4 h6 {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.more4 button {
  border-radius: 50px;
  border: 1px solid black;
  width: 198px;
  height: 32px
}

.more4 i {
  margin-left: 130px;
  font-size: 12px;
}

.more5 {
  margin-left: 30px;
  width: 100%;
  float: left;
  margin-top: 9%;
}

.more5 .dropdown,
.more4 .dropdown {
  border: none;
}

.more5 h6 {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.more5 button {
  border-radius: 50px;
  border: 1px solid black;
  width: 198px;
  height: 32px;

}

.more5 i {
  margin-left: 130px;
  font-size: 12px;
}

.more6 {
  margin-top: 40%;
  width: 100%;
  float: left;
}

.more6 button {
  width: 36% !important;
  height: 38px !important;
  color: white;
  background-color: #eb951f !important;
  font-size: 12px;
}

.warranty {
  margin-top: 9%;
  margin-left: 30px;
  width: 100%;
  float: left;
}

.warranty h6 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

#close_filters {
  margin-right: 10%;
}

.cont {
  margin-left: 40px;
  margin-top: 30px;
}

.cont h6 {
  color: grey;
}

.b1 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #D1D5DF80;
  border-radius: 10px;
}

.b1 p {
  color: #000000;
}

.b1 .p {
  margin-top: -12px;
  color: grey;
}

.b1 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

.b2 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #D1D5DF80;
  margin-top: 30px;
  border-radius: 10px;
}

.b2 p {
  color: #000000;
}

.b2 .p {
  margin-top: -12px;
  color: grey;
}

.b2 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

.cont1 {
  margin-top: 30px;
}

.cont1 h6 {
  color: grey;
}

.b3 {
  height: 100px;
  width: 380px;
  padding: 20px;
  background: #D1D5DF80;
  border-radius: 10px;
}

.b3 p {
  color: #000000;
}

.b3 .p {
  color: grey;
  margin-top: -12px;
}

.b3 h1 {
  font-size: 12px;
  color: grey;
  margin-top: -10px;
}

/* Attachment design*/
/* .content1 {
  margin-left: 220px;
  padding-right: 40px;
} */

.div1 {
  padding: 20px;
  margin-top: 20px;
  width: 40%;
  height: 100px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

.div1 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div2 {
  margin-top: 10px;
  padding: 20px;
  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div2 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div3 {
  margin-top: 10px;
  padding: 20px;

  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div3 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 300px;
  margin-top: -32px;
  font-weight: 500;
}

.main-container {
  margin-top: 10px;
  width: 100%;
  /* height: 400px;
  overflow-y: scroll; */
  float: left;
}


.left-container {
  float: left;
  width: 37%;
}

.right-container {
  padding: 20px;
  float: right;
  width: 60%;
  margin-top: 0;
  min-height: 100%;
  height: auto;
  background: #000;
  border-radius: 10px;
  opacity: 1;
  border: 1px solid #eb951f;
}

.main-container .left-container {
  overflow-y: scroll;
  padding: 0;
  padding-right: 10px;
  width: 38%;
}

.main-container .right-container {
  height: auto;
  padding: 20px 0 0;
  overflow-y: hidden;
}

.buton {
  margin-top: -35px;
}

.col {
  margin-right: 3%;
  width: 100%;
  float: left;
  padding: 0;
  margin-top: -6%;
}

.bbts {
  margin-left: 84%;
  border: none;
  border-radius: 20px;
  height: 30px;
  background: #eb951f;
  width: 100px;
  color: #fff;
  font-size: 14px;
}

.bbts i {
  padding-right: 10px;
}

.ppt {
  margin-left: 170px;
  margin-top: -25px;
}

.ppti {
  float: right;
  margin-top: -35px;
}

.div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

ul#menu2 {
  margin-top: 30px;
  list-style-type: none;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}

.mitem {
  margin-top: -5%;
}

ul#menu2 li {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: #fff;
  opacity: 1;

}

ul#menu2 li:hover {
  cursor: pointer;
}

.div4 h6 i {
  margin-left: 400px;
  font-size: 20px;
}

.div4 .box p {
  margin-left: 200px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}

.div4 .box .bt button i {
  margin: 5px;
}

.div5 {
  width: 50%;
  float: left;
}

.div5 h6 {
  margin-top: 20px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 {
  width: 50%;
  overflow-y: auto;
  float: right;
}

.div6 h6 {
  margin-top: 7px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  float: left;
}

.div6 h6 a {
  color: #eb951f;
}

.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
  width: 43%;
  float: right;
}

.div6 p i {
  padding-right: 8px;
}

.div6 img {
  width: 340px;
  height: 280px;
  margin-top: -5px;
  color: white;
}

/*product design*/
.product_details .first div span,
.product_details .second div span,
.product_details .third div span,
.product_details .fourth div span,
.heads .first div span,
.heads .second div span,
.heads .third div span,
.heads .fourth div span {
  text-align: left;
  font: normal normal normal 11px Roboto;
  font-size: 11px !important;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
  font-weight: 400 !important;
}

.product_details .first div p,
.product_details .second div p,
.product_details .third div p,
.product_details .fourth div p,
.heads .first div p,
.heads .second div p,
.heads .third div p,
.heads .fourth div p {
  text-align: left;
  font: normal normal normal 12px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1
}

.first,
.second,
.third {
  float: left;
}

/*conversation design*/
.heads,
.product_details,
.history {
  overflow-y: auto;
  float: left;
  width: 100%;
  padding: 0 20px;
  height: 330px;
}

.head {
  height: 100px;
  float: left;
  border-bottom: 1px solid rgb(198, 189, 189);
  width: 100%;
  max-height: 200px;
}

.head p {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.head.conversation_head p#created_at {
  font-size: 10px
}

.foot .f1 p {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.f2 {
  margin-top: -45px;
  margin-left: 30px;
}

.foot .f2 span {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 140px;
}

.f2 p {
  float: right;
  color: #4AC0B0;
}

.f2 button i {
  color: gray;
}

.foot .f2 button {
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid black;
  margin-left: 10px;
}

.foot .f1 i {
  color: gray;
  padding: 10px;
}

.f3 {
  margin-top: 60px;
}

.f3 span {
  font-size: 14px;
  color: gray;
}

.f3 i {
  margin: 20px;
}

/* comment */
.message {
  width: 100%;
  height: auto;
  float: left;
  margin: 20px 0;
}

.detail_wrapper .immo {
  position: relative;
  display: -ms-flexbox;
  display: inline-block;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  outline: 0;
  /* margin-top: 65px; */
}

.imgs img {
  width: 500px;
}

.message.other-user div {
  font-size: 14px;
  color: #fff !important;
  margin: 0 auto;
  padding: 15px 25px;
  background: url(/public/img/other-user-bubble.svg) no-repeat;
  width: auto;
  max-width: 50%;
  float: left;
  background-size: cover;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  min-width: 38%;
  max-width: 60%;
  padding: 2%;
}

.message .m {
  font-size: 14px;
  color: black;
}

.message.same-user {
  float: right;
}

.message.same-user div {
  background: #fff;
  float: right;
  background: url(/public/img/same-user-bubble.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 38%;
  max-width: 60%;
  padding: 2%;
}

.message.same-user div p {
  color: #000
}

.message.other-user div p {
  color: #fff;
  padding-left: 9%;
  word-wrap: break-word;
}

.comments-wrapper {
  width: 100%;
  float: left;
  background: #000;
}

.message p {
  margin: 0;
  font-size: 12px;
}

.message p#userName {
  font-weight: 500;
}

.message p#created_at {
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  font-weight: 300;
}

.message.same-user p#created_at {
  color: #000;
}

.heads1 {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 165px;
  height: auto;
  width: 100%;
  float: left;
  background: #000;
  padding-left: 5%;
}

.foots {
  width: 100%;
  float: left;
  margin-top: 3%;
}

.foots .ic p {
  margin-top: -20px;
  margin-left: 40px;
}

/* history */
.cm1 {
  border-bottom: 1px solid #999;
  margin-top: 3%;
}

.cm1 h1 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
}

.cm1 p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.cm2 h1 {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.cm2 p {
  font-weight: 600;
  font-size: 14px;
}

.abc {
  cursor: pointer;
  width: 3%;
  height: 100%;
  z-index: 99999;
}

.abc .fa-times {
  color: #fff;
}

.setwidth {
  width: 60%;
}

.read-only .handclass {
  cursor: pointer;
  width: 33%;
  float: left;
}

.wrapperClassName {
  border: 1px solid rgb(198, 189, 189) !important;
  background-color: #FFFFFF !important;
}

.content span {
  font-size: 14px !important
}

.error {
  display: none;
}

#user_search {
  background: transparent;
  margin-left: -15px;
  padding-left: 3%;
  font-size: 14px;
  font-weight: 500;
  margin-right: 30px;
}

#user_search:focus,
#user_search:-internal-autofill-selected {
  box-shadow: 0 !important;
  outline: none;
}

.user_search_icon {
  font-size: 13px;
  color: grey;
}

.container6 .box3 .mod .modal-header #close-editTicket {
  margin-right: 3%;
  margin-top: 2%;
}

.content .main-container .right-container .details h6 {
  margin-bottom: 0;
  margin-top: 2%;
}

.content .main-container .right-container .details span {
  font-size: 12px !important;
  font-weight: 300 !important;
  width: 80%;
}

.content .main-container .right-container .col.show .dropdown-menu {
  background: #000;
  height: 160px;
  padding: 15px;
  overflow-y: scroll;
  border: 1px solid #eb951f;
  top: 40% !important;
  left: -5% !important;
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item {
  color:#fff;
  font-size: 14px;
  margin-bottom: 5%;
  padding: 5px;
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item:hover>img {

  filter: invert(1);
}

.content .main-container .right-container .col.show .dropdown-menu .dropdown-item img {
  width: 15%;
  margin-right: 10%;
}

.read-only .search .add-user-btn-input {
  background: transparent;
  width: auto;
  outline: none;
}

/* .dropdown-item:focus, .dropdown-item:hover{
    background: #FA9F221A 0% 0% no-repeat padding-box;
    color: #fff;
  } */
.complaint-main .modf {
  position: fixed;
  right: 0;
}

.complaint-main .mod {
  position: fixed;
  margin-top: 0;
  height: 600px;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 3%;
}

.complaint-main .edit-modal {
  width: 600px;
}

.complaint-main .modal-dialog {
  margin: 0;
  margin-top: 6%;
}

.modal-dialog .mo {
  position: fixed;
  left: 80%;
  margin: 0;
  top: 3em;
  height: auto;
  padding-bottom: 15px;
  background: #000;
  box-shadow: 1px 1px 14px 3px rgb(20 20 20);
}

.modal-dialog .mo .header {
  background: #000;
  border-top-right-radius: 20px;
  border-bottom: 1px solid #595959;
}

.select-prod-comp .modf {
  position: fixed;
  top: 4em;
  left: 75%;
}

#root .complaint-main .container6 .box .box1 span {
  display: block;
  font-size: 12px !important;
  font-weight: 400;
  line-height: inherit;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  height: auto;
}

.box-message-tooltip {
  color: #000 !important;
}

#rd-box .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
#rd-box .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #fff !important;
  line-height: 22px !important;
  height: 22px !important;
}

.imageWidthHight {
  max-width: 100%;
  max-height: 320px;
}

.conversation_foot {
  width: 100%;
  background: #fff;
  float: left;
  margin-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

#root div .content .conversation_foot span.ql-picker-label {
  color: #000;
  float: left;
}

.ql-snow .ql-picker-label::before {
  transform: translateY(-9px);
}

#root div .content .conversation_foot .quill {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

#root div .content .conversation_foot .quill .ql-toolbar.ql-snow {
  order: 2;
  width: 100%;
  float: left;
}

#root div .content .conversation_foot .quill .ql-container.ql-snow {
  order: 1;
  width: 100%;
  float: left;
  height: 100px;
}

#root div .content .conversation_foot .f1 {
  width: 100%;
  float: left;
  margin-top: 2%;
}

#root div .content .conversation_foot .f1 p {
  padding: 0;
  margin: 0 3% 0 0;
  float: left;
}

#root div .content .conversation_foot .f1 p span {
  color: #000;
  height: auto;
  line-height: initial;
}

#root div .content .conversation_foot .f1 .conversation_reply {
  border-right: 1px solid #000;
  padding-right: 3%;
}

#root div .content .conversation_foot .f1 .conversation_reply .fa-share {
  padding: 0 10px;
}

#root div .content .conversation_foot form {
  width: 100%;
  float: left;
}

#root div .content .conversation_foot .f1 .to_customer {
  width: 50%;
  text-align: left;
  display: inline-block;
}

#root div .content .conversation_foot .f1 .to_customer span {
  margin-left: 5%;
  /* padding-left: 5%; */
  background: #ccc;
  padding: 1% 3%;
  overflow: hidden;
  border-radius: 10px;
}

#root div .content .conversation_foot .quill .ql-toolbar.ql-snow .ql-formats {
  height: auto;
  line-height: initial;
}

#root div .content .conversation_foot .quill .ql-toolbar.ql-snow .ql-formats .ql-picker-options {
  height: auto;
  line-height: inherit;
}

#root div .content .conversation_foot .quill .ql-toolbar.ql-snow .ql-formats .ql-picker-options span {
  color: #000;
}

#root div .content .conversation_foot div .btn-info {
  border-radius: 20px;
  /* height: 30px; */
  background-color: #eb951f !important;
  padding: 1.5% 0;
  width: 18%;
  margin: 3% 0 3% 1%;
}

.comments_foot {
  overflow: hidden;
}

.comments_foot .ic {
  width: 100%;
  overflow-y: scroll;
  float: left;
  height: 70px;
  display: contents;
}

.comments_foot .ic form .quill {
  display: inline-flex;
  width: 100%;
  float: left;
  border-top: 1px solid #ccc;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow {
  width: 10%;
  float: left;
  border: none;
  border-right: 1px solid #ccc;
  text-align: center;
  display: none;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats {
  height: auto;
  line-height: inherit;
  margin-right: auto;
  margin-left: auto;
  margin-top: 18%;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-image {
  padding: 0;
  background: url(/public/img/same-user-bubble.svg);
}

.comments_foot .ic form .quill .ql-container.ql-snow {
  border: none;
  padding: 0px 8px;
  max-height: 30px;
  overflow-y: scroll;
  margin: 10px 0;
  width: 80%;
  float: left;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-image svg {
  visibility: hidden;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-stroke {
  fill: #fff;
}

.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-video,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-link,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-bold,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-italic,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-underline,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-formats .ql-size.ql-picker,
.comments_foot .ic form .quill .ql-toolbar.ql-snow .ql-font.ql-picker {
  display: none;
}

.comment_reply {
  transform: translate3d(86%, -4em, 10px);
}

.comment_reply .btn-info {
  background: none !important;
  padding-left: 27px;
}

.comments_foot .ic .quill .ql-editor p {
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
}

.comments_foot .ic .quill .ql-editor {
  padding-top: 8px;
  padding-bottom: 0;
}

.content.complaint-main .main-container .right-container .box {
  padding: 0 20px
}

.comments_foot .ic .reply_wrapper {
  width: 90%;
  float: left;
  border-right: 1px solid #ccc;
}

.comments_foot .ic .comment_reply {
  width: 10%;
  float: right;
  transform: none;
  border-top: 1px solid #ccc;
}

.comments_foot .ic .comment_reply .btn-info {
  width: 100%;
  float: left;
  margin: 0 auto;
  padding: 14px 0px;
}

.head.conversation_head {
  max-height: 310px !important;
  height: 130px;
  padding-left: 20px;
  border: 0;
  height: 100%;
}

.complaint-main .mitem ul#menu2 {
  margin-top: 30px;
  width: 100%;
  padding-left: 0%;
}

.detail_wrapper {
  width: 100%;
  float: left;
  padding: 0 20px;
  height: 330px;
}

.close-search {
  background: none;
}

.close-search i {
  color: grey;
  font-weight: 600;
  font-size: 14px;
}

.history .date {
  text-align: left;
  font: normal normal normal 10px/16px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  font-weight: 300;
  margin-bottom: 0;
  margin: 0;
}

.history_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.history .last_worked {
  height: auto;
  margin: 0;
  font-size: 12px;
  line-height: inherit;
  margin-top: 5px;
  color: #fff;
}

.history .last_worked span {
  height: auto;
  line-height: inherit;
  font-size: 12px !important;
}

.history {
  margin-top: -10px;
}

@media only screen and (min-device-width:1400px) {}

.complaint-main .box1 h6 {
  font-size: 14px;
}

.complaint-main .box1 p {
  margin-top: 23px;
}

.complaint-main .box1 {
  padding-bottom: 0 !important;
}

.complaint-main .first {
  padding-right: 2%;
}

.complaint-main .second {
  width: 40%;
  padding-right: 2%;
}

.complaint-main .third {
  width: 30%;
}

.main-container .details {
  width: 63%;
  float: left;
}

.main-container .details span {
  height: 23px;
  float: left;
  overflow-y: hidden;
}

.main-container .right-container .box .col {
  width: 20%;
  float: right;
  padding: 0;
  margin-top: 12%;
  margin: 0;
  margin-top: 2.2%;
}

.main-container .right-container .box .col #dropdownMenuButton {
  margin: 0;
  width: 100%;
  float: left;
}

.ppti.abc.readOnly {
  margin-top: 0;
}

.complaint-main .loader {
  position: inherit;
  left: 35%;
  /* margin-top: 40%; */
  margin-top: 7%;
  display: flex;
  justify-content: center;
  width: 100%;
}

#rModal_head {
  color: #fff;
  font-weight: 500;
  padding-right: 3%;
  float: left;
  height: auto;
  line-height: 30px;
  font-size: 30px !important;
}

#close_rModal {
  background: #000;
  float: right;
}

#select_rModal {
  background-color: #000 !important;
  margin: 22% 0 15%;
}

#send_rModal {
  background-color: #eb951f;
  color: white;
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 180px;
}

.gc_wrapper {
  margin-bottom: 15px;
  width: 100%;
  float: left;
  margin: 0 0 8%;
}

.gc_wrapper label {
 
  font-size: 15px;
  color: #828282;
  width: 100%;
  float: left;
}

.error_add {
  text-align: left;
  width: 100%;
  float: left;
}

.modal-header .Et .error_add .errorShow {
  font-size: 10px !important;
  padding: 0;
  margin-bottom: 0;
}

.modal-header #close_rModal {
  padding-right: 6%;
}

@media only screen and (min-device-width:1400px) {
  .Et .active {
    margin-bottom: 8%;
  }
}

.product_details .ticket-status {
  width: 83px;
  height: 16px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #FA9F22;
}





/* Select search css */
.select-prod-comp .select-product-css,
.select-prod-comp .dropdown-wrap-problem {
  max-height: 32px;
  width: 100%;
}

.select-prod-comp .css-1s2u09g-control,
.select-prod-comp .css-1pahdxg-control,
.select-prod-comp .css-1s2u09g-control,
.select-prod-comp .css-1pahdxg-control {
  width: 100%;
  height: 32px;
  padding: 0 5px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  max-height: 32px;
  min-height: 32px;
}

.select-prod-comp .css-1s2u09g-control:hover,
.select-prod-comp .css-1pahdxg-control:hover,
.select-prod-comp .css-1s2u09g-control:hover,
.select-prod-comp .css-1pahdxg-control:hover {
  border: 1px solid #595959;
}

.select-prod-comp .css-1s2u09g-control .css-319lph-ValueContainer,
.select-prod-comp .css-319lph-ValueContainer {
  padding: 0 8px;
}

.select-prod-comp .css-1s2u09g-control .css-319lph-ValueContainer .css-14el2xx-placeholder,
.select-prod-comp .css-14el2xx-placeholder {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.select-prod-comp .css-1pndypt-Input,
.select-prod-comp .css-ackcql {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.select-prod-comp .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.select-prod-comp .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.select-prod-comp .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.select-prod-comp .css-1s2u09g-control .css-319lph-ValueContainer .css-6j8wv5-Input,
.select-prod-comp .css-6j8wv5-Input {
  margin: 0;
}

.select-prod-comp .css-1pahdxg-control .css-319lph-ValueContainer .css-6j8wv5-Input {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.select-prod-comp .css-1pahdxg-control,
.content-leaderboard .css-1pahdxg-control {
  box-shadow: none;
}

.content-leaderboard .css-6j8wv5-Input,
.select-prod-comp .css-6j8wv5-Input {
  color: #fff;
  font-weight: 500;
}

.select-prod-comp .css-319lph-ValueContainer {
  padding: 0 8px;
}

.select-prod-comp .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .right-grid  .dropdown-wrap-courier .css-tlfecz-indicatorContainer{
    height: 28px;
    color: #fff;
  } */
.select-prod-comp .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.select-prod-comp .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.select-prod-comp .css-6j8wv5-Input input,
.select-prod-comp .css-6j8wv5-Input input {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;

}

.select-prod-comp .css-319lph-ValueContainer .css-14el2xx-placeholder,
.select-prod-comp .css-14el2xx-placeholder {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
  font-size: 14px;

}

.select-prod-comp .css-26l3qy-menu {
  background: #000;
  color: #fff;
  border-radius: 10px;
}

.select-prod-comp .css-1gtu0rj-indicatorContainer {
  color: #ffffff !important;
}

.select-prod-comp .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
.select-prod-comp .css-1n7v3ny-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
  background: #FA9F221A 0% 0% no-repeat padding-box;
  color: #FA9F22;
}

.select-prod-comp .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:active,
.select-prod-comp .css-1n7v3ny-option:active {
  background: #FA9F221A 0% 0% no-repeat padding-box;
}

.select-prod-comp .css-yt9ioa-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
}

.css-1gtu0rj-indicatorContainer svg {
  color: #fff;
}

.select-prod-comp .loader {
  position: inherit;
  left: 35%;
  /* margin-top: 40%; */
  margin-top: 7%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.css-vwja0k {
  color: #FFFFFF !important;
}


.select-prod-comp .css-qc6sy-singleValue {
  color: #FFFFFF !important;

}

/* End Search Css */

.screening-label {
  color: #ffffff;
  opacity: 0.7;
  margin-left: 4%;
}

.screening-list {
  padding-top: 15%;
  padding-bottom: 10%;
}



.rpu-print-cont header .row {
  display: flex;
}

.rpu-print-cont .col {
  flex: 1;
}

.download_icon {
  width: 38%;
}

.download_icon .fa-download {
  padding-right: 7%;
}

.request_payment #select_pModal {
  margin-left:0px;
  font-size: 14px;
  color: #fff;
  /* margin: 5%; */
  padding: 2%;
}

.request_payment #rp_wrapper {
  margin-top: 1%;
  margin-bottom: 4%;
  background-color: #000!important;
  width: 100%;
  float: left;
  margin-bottom: 8%;
  width: 90%;
    margin-top: 1%;
    margin-bottom: 6%;
}

.qc_pass div {
  width: 100%;
  margin-top: 2%;
  float: left;
}

/* .qc_pass #select_rModal {
  margin: 0;
  background-color: #fff !important;
  margin-left: 5%;
} */

.qc_pass #send_rModal {
  margin-top: 8%
}

.qc_pass #rModal_head {
  margin-bottom: 8%;
}

.repair_payment h2#rModal_head {
  margin-bottom: 10%;
}

.repair_payment #send_rModal {
  float: left;
  margin-top: 10%;
}

.liveStatusHead {
  margin-bottom: 10%;
}

.liveStatus {
  width: 100%;
  float: left;
  margin-bottom: 5%;
}

.liveStatus p {
  color: #fff;
  font-size: 14px;
  font-weight: 500
}

.btn.btn-primary.reply-collapse {
  border: none;
  border-radius: 20px;
  /* height: 46px; */
  background: #eb951f !important;
  width: 23%;
  color: #fff;
  font-size: 14px;
  padding: 8px 0;
  margin-left: 3%;
  margin-bottom: 5%;
}

#coversation_body {
  border-bottom: 1px solid #fff;
  margin-bottom: 3%;
}

.commentInConv {
  background: #eb951f;
  padding: 2%;
  margin-bottom: 3%;
}

.commentInConv p {
  margin: 0;
}

#coversation_body.incoming {
  background: #fff;
  margin-bottom: 0;
  padding: 3%;
  border-color: #000;
  padding-bottom: 1%;
  width: 100%;
  float: left;
  margin-bottom: 4%;
}

#coversation_body.incoming p {
  color: #000;
  width: 100%;
  float: left;
  margin-bottom: 1%;
}

#coversation_body.incoming h3 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3%;
  float: left;
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

.selectProduct h2 {
  margin-bottom: 10%;
}

.selectProduct #send_rModal {
  float: left;
  margin-top: 5%;
}

.selectProduct select {
  background: #000;
  color: #fff;
  padding: 1%;
}

.problem_des {
  width: 100%;
  float: left;
}

.content .heads .problem_des span {
  text-align: left;
  font: normal normal normal 11px Roboto;
  font-size: 11px !important;
  letter-spacing: 0px;
  opacity: 0.5;
  font-weight: 400 !important;
}

.problem_des p {
  text-align: left;
  font: normal normal normal 12px Roboto;
  letter-spacing: 0px;
  color: #ccc;
  opacity: 1;
}

.select-product-css.dropbtn1111 div,
.select-product-css.dropbtn1111 #react-select-3-listbox,
.select-product-css.dropbtn1111 {
  background: #000 !important;
  color: #fff !important;
  grid-area: 1;
  font-size: 13px;
}

.select-product-css.dropbtn1111 div.css-1pahdxg-control,
.select-product-css.dropbtn1111 div.css-1pahdxg-control:hover {
  border-color: grey !important;
  box-shadow: none !important;
}

.select-product-css.dropbtn1111 .css-1okebmr-indicatorSeparator {
  display: none;
}

.zoom-icons {
  text-align: center;
  position: absolute;
  z-index: 9999;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 1% 0;
}

.zoom-icons .fas {
  color: #fff;
  margin: 0 2%;
  font-size: 25px;
}

.zoom-icons .fas:hover {
  cursor: pointer;
}

.modal-open .modal#immos1 {
  overflow: scroll;
  padding: 0;
  margin: 0;
  right: 0;
  padding-left: 3%;
  padding-right: 0px !important;
  float: left;
}

.modal-open .modal#immos1 .imgs img {
  overflow: scroll;
}

.callCount {
  top: -1.5em;
  font-size: 9px;
  left: -1em;
  font-weight: 500;
}

.dropdown-item .callCount {
  top: -1.2em;
  left: 0;
}

.copy_icon {
  position: absolute;
  left: 20%;
  top: -3%;
}

.copy_icon:hover {
  cursor: pointer;
}

.copy_icon .fa-copy {
  color: #fff;
  font-size: 13px;
}

.box {
  position: relative;
}

.list input::placeholder {
  color: #fff;
  background: none;
}