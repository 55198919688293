.sidebar-container-new{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0 20px;
    width: 15%;
    padding-top: 2%;
    padding-left: 2%;
}

.sidebar-container-new a{
    display: block;
    text-decoration: none;
    width: 92%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0 12px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.sidebar-container-new span{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 15px;
    height: 18px;
    text-align: left;
    font-size: 13px;
    line-height: 50px;
    letter-spacing: 0px;
    color: #8e8e8e;
    opacity: 1;
    font-weight: 500;
}
.sidebar-container-new i{
    color: #8e8e8e;
}


@media only screen and (max-width: 600px) {
    .sidebar{
        width: 100% !important;
    }
    .sidebar img{ 
      display: none;
    }
    
  }

