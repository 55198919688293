/* body {
    height: 100vh;
    margin: 0;
    padding: 10px;
    position: relative;
}
  
body::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px solid #dfdfdf;
} */

.leftContainer {
    float: left;
    overflow: flex;
    width: 70%;
    padding: 30px;
    align-self: center;
    justify-content: center;
    /* height: 100%; */
    /* background-color: red; */
    /* border: 1px solid #dfdfdf; */
}

.name {
    /* width: 189px; */
    height: 35px;
    margin: 0 0 38px 0;
    font-family: Roboto;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.rightContainer {
    float: right;
    width: 375px;
    /* height: 100%; */
    display: flex;
    /* margin: 10px; */
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 40px, 25px 40px 25px;
    border-left: 1px solid #dfdfdf;
}

.Rectangle {
    width: 140px;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    background-color: #8a83be;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.image {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.tick-image {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    object-fit: contain;
}

.title-text {
    width: 65px;
    margin: 10px 0 10px 0px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.count-text {
    margin: 0 32px 0 0;
    font-family: Roboto;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}