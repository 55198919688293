/* .tickets-container{ 
   
    
padding: 0px;
gap: 16px;
position: absolute;
width: 900px;
height: 844px;
left: 418px;
top: 144px;
}
.ticket-wrapper2{
   
    float:left;
    margin-bottom: 2%;
    box-sizing: border-box;
    padding: 20px;
    gap: 12px;
    width: 410px;
    height: 205px;
    background: #FFFFFF;
    margin-right: 10px;
    border: 1px solid rgba(38, 37, 44, 0.1);
    box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
   
}
.ticket-wrapper2 .ticket-header #nm{
    width: 176px;
    height: 24px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1A202D;
   }
   .ticket-wrapper2 .ticket-header #border{
    width: 363px;
    height: 0px;
    
    border: 1px solid rgba(38, 37, 44, 0.1);
   }
   .compliantdetils{
    padding: 0px;
    gap: 4px;
    width: 396px;
    height: 40px;
}
.compliantdetils span {
    width: 114px;
height: 20px;

font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;



color: #1A202D;

}
.ticket-wrapper2  #border{
    width: 363px;
    height: 0px;
    
    border: 1px solid rgba(38, 37, 44, 0.1);
   }
ss
   .compliantdetils #tt1{
    width:198px;

    height: 20px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    
    
    text-align: right;
   }
.ticket-status{
    
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;

width: 396px;
height: 68px;
}
.ticket-status span{
    width: 99px;
    height: 20px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
   } 

 .ticket-status #tt2{
    width:198px;

    height: 20px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    
    
    text-align: right;
    
   } */
   /* .ticket-status .statusName span{
    width: 150px;
    height: 20px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;} */

/* .ticket-status .statusName{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 244px;
    height: 20px;
}
.ticket-status .statusName h6{
    width:198px;

height: 20px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;


text-align: right;


} 




.ticket-wrapper{ 
    width:30%;
    float:left;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.06);
    margin-right: 2%;
    padding: 2%;
    border-radius: 10px;
    margin-bottom: 2%;
}

.ticket-wrapper p {
    width: 198px;
height: 10px;

font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
} */

/* 
.product-details {
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 4px;

width: 244px;
height: 68px;

}
.ticket-wrapper .border{
    width: 244px;
height: 0px;

border: 1px solid rgba(38, 37, 44, 0.1);
}

.product-details .productName span{
    width: 150px;
    height: 20px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;}

.product-details .productName{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 244px;
    height: 20px;
}
.product-details .productName .pn1  {
    width: 99px;
height: 20px;

font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
}

.product-details .productName h6{
    width:198px;

height: 20px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;


text-align: right;


}

 
  .fiter-container{

    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 24px;

position: absolute;
width: 891px;
height: 32px;
left: 418px;
top: 96px;
 }
 .fiter-container #allText{
    width: 334px;
height: 32px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
letter-spacing: -0.25px;
color: #1A202D;
 }
 .fiter-container .filters{
    
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 12px;

width: 533px;
height: 32px;
 }
 .service-filter{
    box-sizing: border-box;

display: flex;
flex-direction: row;
align-items: center;
padding: 4px 8px 4px 16px;
gap: 8px;

width: 154px;
height: 30px;

background: #F7F7F7;
border: 1px solid rgba(38, 37, 44, 0.1);

 }  */
 /* .users-Profile{
    border: 1px solid green;
    padding: 0px;
    gap: 16px;
    position: absolute;
    width: 900px;
    height: 844px;
    left: 367px;
    top: 125px;
}
#name-user
{
    position: absolute;
width: 170px;
height: 32px;
left: 370px;
top: 70px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
}
.users-Profile .usersProfile_tickets_wrapper{
    float:left;
  padding: 20px;
gap: 12px;
width: 284px;
height: 172px;
margin:6px;
background: #FFFFFF;
border: 1px solid rgba(38, 37, 44, 0.1);
box-shadow: 0px 9.46px 23.65px rgba(233, 238, 242, 0.4);
border-radius: 8px;
}
.usersProfile_tickets_wrapper .header{
    border:1px solid green;
    align-items: center;
    padding: 0px;
    gap: 12px;
    
    width: 244px;
    height: 64px;
}
.usersProfile_tickets_wrapper img{
    box-sizing: border-box;

    width: 64px;
    height: 64px;
    
    
    border: 2px solid #CDD8DE;
} */
/* .usersProfile_tickets_wrapper #nm2 {
    padding-left: 10px;
    width: 81px;
    height: 24px;
    
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;


color: #1A202D;
    
}
.usersProfile_tickets_wrapper #nm3 {
    width: 168px;
height: 20px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
} */