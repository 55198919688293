::-webkit-input-placeholder,
::-webkit-textarea-placeholder {
    /* Edge */
    background: #121212;
}

:-ms-input-placeholder,
:-ms-textarea-placeholder {
    /* Internet Explorer 10-11 */
    background: #121212;
}

/* ::placeholder {
    background: #121212 !important;
} */

.bulk-container {
    display: flex;
    height: 100%;
}

.sub-header-bulk {
    display: flex;
    /* flex-direction:  row; */
}

.ticketNumberInput,
.selectStatus,
.showProducts {
    width: 300px;
    float: left;
}

.selectStatus {
    width: 250px;
}

.filter-row,
.dispatch_inward,
.bulk-rpu {
    display: flex;
    justify-content: stretch;
    padding-left: 3%;
    padding-right: 7%;
    align-items: center;
}

.dispatch_inward {
    width: 100%;
    float: left;
    display: block;

}
.dispatch_inward .dropdown, .bulk-rpu .dropdown{
    font-size:14px;
    padding-left:2%;
}
.filter-row .choose_type,
.filter-row .transfer_to,
.dispatch_inward .choose_type,
.dispatch_inward .ticket_head,
.showProducts .select_product,
.showAllProducts .list,
.bulk-rpu .ticket_head,
.bulk-rpu .list {
    font-size: 12px;
    color: #828282;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 0;
}

.showAllProducts .select-product-css,
.showAllProducts .css-1s2u09g-control,
.showAllProducts .css-1pahdxg-control,
.showAllProducts #react-select-2-listbox,
.showAllProducts .select-product-css div,
.showAllProducts #react-select-2-listbox div:hover,
.showAllProducts .showAllProducts #react-select-2-listbox div:focus {
    background-color: #000;
    color: #fff;
    box-shadow: none;
    border-color: grey
}

.showAllProducts .select-product-css .css-qc6sy-singleValue {
    grid-area: 1;
}

.filter-row .dropdown,
.showProducts .dropdown {
    padding: 7px 10px;
    height: auto;
    font-size: 14px;
    width: 225px;
}

.showAllProducts {
    width: 225px;
    float: left;
}

.filter-row div:nth-child(2) {
    margin-left: 1%;
    position: relative;
}

.main-content .filter-row div.transfer_to,
.filter-row div.status {
    margin-bottom: 0;
    margin-left: 1%;
    margin-top: 0;
}

.selectStatus {
    margin-left: 4%;
}

.only_movement_wrapper {
    width: 100%;
    float: left;
    margin-top: 2%;
}

.filter-row div.transfer_to .dropdown {
    margin-top: 3%;
}

.row .only_movement_wrapper .only-movement {
    padding-left: 5%;
    width: 100%;
    float: left;
    padding-top: 0;
}

.row .transfer {
    margin-left: 4%;
    margin-top: 4%;
}

.filter-row div.transfer_from div,
.filter-row div.status div {
    font-size: 12px;
    color: #828282;
    margin-bottom: 3%;
    font-weight: 500;
}

.filter-row div:nth-child(3) {
    margin-left: 1%;
    /* margin-top: 2%; */
    position: relative;
}

.main-content {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #595959;
    margin-top: 6%;
    width: 83%;
    /* padding: 2%; */
    float: right;
    border-left: 1px solid #595959;
    border-top-left-radius: 10px;
    padding-right: 3%;
}

.white-color {
    color: white;

}

.heading-bulk {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 500;
    padding-left: 34px;
    padding-top: 20px;
}


.sub-header-bulk {
    letter-spacing: 0.01px;
    color: #FA9F22;
    padding-left: 34px;
    font-size: 19px;
    font-weight: 600;
    border-bottom: 1px solid grey;
    margin-left: 3%;
    padding-top: 20px;
    padding-left: 3px;
    padding-bottom: 0%;
    margin-bottom: 2%;
}

.sub-header-bulk li {
    padding: 0;
    text-decoration: none;
    height: auto;
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0.01px;
    color: #999999;
    opacity: 1;
    font-weight: 500;
    float: left;
    margin-right: 2%;
    margin-bottom: 0px;
    padding-bottom: 1%;
    list-style: none;
    cursor: pointer;
    border-radius: 0;
}

.dropdown {
    color: white;
    width: 195px;
    height: 32px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF4D;
    border-radius: 100px;
    opacity: 1;
}

.transfer {
    margin-left: 3%;
    margin-top: 3%;
}

.transferButton {
    width: 134px;
    height: 36px;
    background: #FA9F22 0% 0% no-repeat padding-box;
    border-radius: 60px;
    opacity: 1;
    border: none;
    color: #fff;
    font-size: 14px;
}

.text-area-input {
    width: 300px;
    height: 32px;
    opacity: 1;
    border: none;
    outline: none;
    color: white;
    background-color: #121212;
    font-size: 14px;
    border-bottom: 1px solid grey;
    resize:none
}

.text-area-input::placeholder {
    background: none !important;
    color: #fff !important;
}

.enter-ticket-module {
    padding-left: 3%;
    padding-top: 3%;
    display: flex;
}

.enter-ticket-module .ticket_head {
    font-size: 12px;
    color: #828282;
    margin-bottom: 1%;
    font-weight: 500;
}

.ticket-list {
    width: 98%;
    height: 100px;
    border: 1px solid grey;
    border-radius: 10px;
    opacity: 1;
    color: white;
    display: flex;
    margin-bottom: 1%;
    justify-content: flex-start;
    padding: 20px;
    /* background: #FA9F221A 0% 0% no-repeat padding-box;
border: 1px solid #FA9F22; */

}

.ticket-list .ticket_info {
    float: left;
    text-align: left;
    width: 25%;
}

.ticket-list .ticket_info .ticketId {
    float: left;
    color: #fff;
    margin-left: 14px;
    margin-top: 2px;
    height: 19px;
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
}

.ticket-list .ticket_info .productName {
    color: #fff;
    font-weight: 500;
    line-height: 25px;
    width: 100%;
    font-size: 14px !important;
    float: left;
    margin-left: 14px;
}

.ticket-list .productInformation {
    width: 25%;
    float: left;
    border-left: 1px solid grey;
    padding-left: 4%;
}

.ticket-list .productInformation .productProblem {
    height: auto;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 5px;
}

.ticket-list .productInformation .probleDescription {
    font-size: 12px;
    opacity: 0.5;
    font-size: 12px !important;
    text-align: left;
    letter-spacing: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.ticket-list-module {
    display: flex;
    flex-direction: column;
    /* padding-left: 3%;
    padding-top: 4%; */
    /* max-height : 300px; */
    overflow: auto;
    padding-left: 2%;
}

.left-arrow {

    width: 7px;
    height: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 0.6;
}

.pagination .btn {
    background-color: transparent !important;
    opacity: 0.8;
    color: white;
}

.pagination .btn:hover {
    color: black;
    background-color: #FA9F22;
}

.select-all-tab {
    padding-top: 20px;
    padding-left: 32px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.select-all-tab input {
    zoom: 1.5;
    vertical-align: middle;
}

.select-all-label {
    color: white;
    padding-left: 10px;
    font-size: 13px;
    margin-bottom: 0;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.input-wrap-bulk {
    position: absolute;
    opacity: 0;
}

#only_movement {
    opacity: 0;
}

.warehouse,
.scanning {
    opacity: 0;
    position: absolute;
    z-index: 666;
    top: 27%;
    left: 2%;
}

.warehouse:hover,
.scanning:hover {
    cursor: pointer
}

.input-wrap-bulk+label,
#only_movement+label,
.warehouse+label,
.scanning+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.input-wrap-bulk+label:before,
#only_movement+label:before,
.warehouse+label:before,
.scanning+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 3px;
}

.input-wrap-bulk:hover+label:before,
#only_movement:hover+label:before,
.warehouse:hover+label:before,
.scanning:hover+label:before {
    background: transparent;
}

.input-wrap-bulk:focus+label:before,
#only_movement:focus+label:before,
.warehouse:focus+label:before,
.scanning:before+label:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.input-wrap-bulk:checked+label:before,
#only_movement:checked+label:before,
.warehouse:checked+label:before,
.scanning:checked+label:before {
    background: rgb(250, 159, 34);
    border: none;
}

.input-wrap-bulk:checked+label:after,
#only_movement:checked+.select-all-label:after,
.warehouse:checked+label:after,
.scanning:checked+label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 9px;
    background: rgb(250, 159, 34);
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
    transform: rotate(45deg);

}

.only-movement {
    padding-left: 10%;
    padding-top: 3%;
}

.only-movement input[type=checkbox] {
    background: #121212;
    vertical-align: middle;
}


.only-movement-warehouse input[type=checkbox] {
    background: #121212;
    vertical-align: middle;
}

.ticket-summary {
    display: flex;
    padding-left: 35px;
    padding-top: 29px;
    gap: 2%;
    color: white;
    width: 100%;
}

.pagination {
    display: flex;
    justify-content: center;
}

.empty-data {
    color: #999999;
    padding-left: 3%;
    margin-top: 5%;
    font-size: 23px;
}

.filter-row .ticketNumberInput .ticket_head {
    font-size: 12px;
    color: #828282;
    margin-bottom: 3%;
    font-weight: 500;
}

.date-heading {
    color: var(--gray-color);
    font-size: var(--font-size);
    margin-bottom: var(--margin-bottom);
    font-weight: var(--font-weight);
}

.acc_selected {
    padding-left: 4.5%;
    margin-top: 1%;
    width: 34%;
}

.acc_selected div {
    font-size: 12px;
    color: #828282;
    margin-bottom: 3%;
    font-weight: 500;
}

.acc_selected textarea {
    background-color: #121212;
    border: none;
    border-bottom: 1px solid grey;
    width: 100%;
    color: #fff;
}

.showAllProducts .select-product-css {
    background-color: transparent;
}

.showAllProducts .css-1s2u09g-control,
.showAllProducts .css-1pahdxg-control,
.showAllProducts .css-1s2u09g-control div {
    border-radius: 100px !important;
    background: none;
}

.showAllProducts .select-product-css div#react-select-2-placeholder {
    grid-area: 1;
}

.showAllProducts .select-product-css div.css-319lph-ValueContainer,
.showAllProducts .select-product-css div.css-tlfecz-indicatorContainer,
.showAllProducts .select-product-css div.css-1hb7zxy-IndicatorsContainer,
.css-1gtu0rj-indicatorContainer,
.css-ackcql {
    border-radius: 100px !important;
}


/* End */

.dispatch-loader {
    position: absolute;
    left: 47%;
    top: 40%;
}

.activebm {
    color: #FA9F22 !important;
    border-bottom: 1px solid #FA9F22 !important;
}

.bulk_rpu_response {
    margin-top: 4%;
    margin-left: 3%;
    display: flex;
}
.acc_selected #addNoteDispatch ::placeholder{
    background: black;
    color:white;
}
 
#fontgood h6 {
    font-size: 12px !important;
    color: #828282 !important;
    margin-bottom: 3% !important;
    font-weight: 500 !important;
    margin-top: 0 !important;

}

#fontgood {
    float: left;
    width: 23%;
    height: 32px;
    margin-top: 4px;
    margin-right: 15px;
    margin-bottom: 3%;
  }