.rtlComplaintHead {
    color: #000;
    font-weight: bold;
    text-align: center;
    padding-top: 4%;
}

.rtl_logo_wrapper,
.rtl_account {
    width: 50%;
    float: left;
}

.rtl_logo_wrapper img {
    float: left;
    width: 35px;
}

.rtl_logo_wrapper p {
    float: left;
    font-weight: 600;
    margin-left: 2%;
    margin-bottom: 0;
    margin-top: -5px;
}

.rtl_logo_wrapper span {
    float: left;
    margin-left: 2%;
    font-size: 14px;
    margin-top: -5px;
}

.complaintRegistration .header {
    width: 100%;
    float: left;
    padding: 1% 5%;
}

.rtl_account {
    text-align: right;
}

.rtl_account .fa-user-circle {
    font-size: 32px;
}

.rtl_account.crProfile .fa-user-circle {
    color: #000;
    margin-top: 2%;
}

.noComplaintsWrapper {
    width: 100%;
    float: left;
    text-align: center;
    vertical-align: middle;
    margin-top: 5%;
}

.noComplaintsWrapper h3 {
    font-weight: 500;
    margin-top: 1%;
}

.newComplaint .newComplaintCTA {
    background: #307FE2;
    color: #fff;
    width: 220px;
    padding: 11px 0;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 1%;
}

.noComplaintsWrapper .fa-plus,
.newComplaintCTA .fa-plus {
    font-weight: 600;
    font-size: 13px;
    margin-right: 10px;
}

.complaintTracking {
    width: 100%;
    float: left;
    padding-left: 5%;
    padding-right: 5%;
}

.complaintTracking h3 {
    float: left;
    font-weight: 500;
    font-size: 24px;
}

.complaintTracking .newComplaint {
    float: right;
}

.complaintList {
    width: 100%;
    float: left;
    border: 1px solid rgb(0 0 0 / 10%);
    margin-top: 2%;
    border-radius: 5px;
    padding: 1% 2%;
}

.complaintList p {
    float: left;
    margin: 0 1% 0 0;
    font-size: 14px;
    font-weight: 500;
}

.activeList {
    color: #307FE2;
    border-bottom: 1px solid #307FE2;
}

.statusWrapper {
    width: 100%;
    float: left;
    margin: 3% 0 2%;
}

.ticketWrapper {
    width: 20%;
    float: left;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.06);
    margin-right: 2%;
    padding: 2%;
    border-radius: 10px;
    margin-bottom: 2%;
}

.ticketWrapper h3 {
    width: 100%;
    float: left;
}

.ticketWrapper p {
    font-weight: 300;
    padding: 0;
    margin: 0;
}

.ticketWrapper p:nth-child(2) {
    padding-left: 12%;
}

.ticketWrapper p span {
    font-weight: 500;
}

.ticketInProgress,
#ticketInfoWrapper {
    width: 100%;
    float: left;
}

#ticketInfoWrapper {
    margin: 4% 0;
}

.ticketInProgress p,
.closedTciket p {
    padding: 3% 9%;
    margin-top: 6%;
    border-radius: 6px;
    font-weight: 400;
}

.ticketInProgress p {
    border: 1px solid #307FE2CC;
    background: rgba(48, 127, 226, 0.1);
}

.closedTciket p {
    border: 1px solid #24B907;
    background: rgba(36, 185, 7, 0.1);
}

.backHome,
.continueWrapper {
    font-weight: 500;
    width: 20%;
    float: left;
}

.backHome .fa-arrow-left {
    margin-right: 1%;
}

.navigation {
    width: 75%;
    text-align: center;
    float: left;
    padding: 1% 0;
    border-radius: 20px;
    border: 1px solid rgba(38, 37, 44, 0.1);
    margin: 0 12%;
}

.navigationWrapper {
    width: 60%;
    float: left;
}

.navigationName {
    width: 26%;
    font-size: 14px;
    float: left;
    font-weight: 500;
    color: rgba(38, 37, 44, 0.8);

}

.navigationName span {
    font-size: 14px;
    color: #307FE2;
    border: 1px solid #307FE2;
    border-radius: 25px;
    margin-right: 10px;
    padding: 3px 7px;
}

.nextArrow {
    width: 10%;
    float: left;
    color: rgba(38, 37, 44, 0.5);
}

.header.pd {
    box-shadow: none;
}

button.continueProductDetails,
button.continueShippingDetails {
    width: 58%;
    background: #307FE2;
    color: #fff;
    /* page-break-after: auto; */
    padding: 7px 0px;
    border-radius: 5px;
    font-weight: 500;
}

.lineItemWrapper {
    width: 100%;
    float: left;
    margin-bottom: 2%;
}

.lineItemWrapper .wrapper label {
    font-weight: 500;
}

.lineItemWrapper .wrapper:nth-child(1),
.lineItemWrapper .wrapper:nth-child(2),
.lineItemWrapper .wrapper:nth-child(4),
.lineItemWrapper .wrapper:nth-child(5) {
    width: 15% !important;
    float: left;
    margin-right: 2%;
}

.productDetailsWrapperInside .lineItemWrapper .wrapper {
    padding: 0;
}

.lineItemWrapper .wrapper:nth-child(3) {
    width: 8% !important;
    float: left;
    margin-right: 2%;
}

.lineItemWrapper .wrapper:nth-child(6) {
    width: 18% !important;
    float: left;
    margin-right: 0;
}

.removeLineItem {
    text-align: right;
    margin-top: 49px;
    color: rgba(38, 37, 44, 0.4);
    font-size: 21px;
    width: 4%;
}

.wrapper .css-1s2u09g-control {
    background: #f7f7f7;
    border: none;
    min-height: 38px;
    height: 38px;
}

/* .wrapper .css-1s2u09g-control .css-14el2xx-placeholder{
    color: #f7f7f7 !important;
} */

.wrapper .itemQuantity {
    width: 100%;
    float: left;
    border: none;
    background: #f7f7f7;
    height: 38px;
    border: 1px solid #ccc !important;
    border-radius: 5px;
}

.wrapper .itemQuantity::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    background: #f7f7f7 !important;
    color: black !important;
    opacity: 0.50;
    /* Firefox */
}

.wrapper .date-complaint-rtl {
    width: 100%;
    float: left;
    border: none;
    background: #f7f7f7;
    height: 38px;
    padding: 1px 13px;
    border: 1px solid #ccc !important;
    border-radius: 5px;
    /* pointer-events: none; */
}

.wrapper textarea {
    width: 100%;
    background: #f7f7f7;
    border: none;
    height: 38px;
    min-height: 38px;
}

.wrapper textarea::placeholder {
    background: #f7f7f7 !important;
    color: black !important;
    opacity: 0.50;
    /* Firefox */
}

.productDetailsWrapper,
.productDetailsWrapperInside {
    width: 100%;
    float: left;
}

.productDetailsWrapper {
    padding: 0 5%;
}

.productDetailsWrapper h2 {
    font-size: 26px;
    font-weight: 500;
}

.productDetailsWrapperInside {
    border: 1px solid rgba(38, 37, 44, 0.1);
    padding: 2%;
    margin-top: 2%;
    border-radius: 5px;
}

.productDetailsWrapperInside h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom:5%;
}

.productDetailsWrapperInside p {
    font-size: 16px;
    border-bottom: 1px solid rgba(38, 37, 44, 0.1);
    margin-bottom: 2%;
    padding-bottom: 2%;
}

.rtl-button-section {
    margin-top: 4%;
}

.rtl-button-section button {
    font-weight: 500;
    color: #307FE2;
    text-align: left;
    background: #fff !important;
    border: none !important;
}

.rtl-button-section .fa-plus {
    color: #307FE2;
    margin-right: 4%;
}

#done {
    color: #307FE2
}

span.done {
    background: #307FE2;
    padding: 2px 4px;
}

span.done .fa-check {
    color: #fff;
    font-size: 12px;
    transform: translate(0px, -1px);
}

/* .shippingInfo h3{
} */
.shippingWrapper .field:first-child,
.shippingWrapper .field:nth-child(2) {
    width: 33%;
    float: left;
    margin-bottom: 2%;
}

.shippingWrapper .field:nth-child(3),
.shippingWrapper .field:nth-child(4) {
    width: 50%;
    float: left;
    margin-bottom: 2%;
}

.shippingWrapper .field:nth-child(6),
.shippingWrapper .field:nth-child(7),
.shippingWrapper .field:nth-child(5) {
    width: 33%;
    float: left;
}

.shippingWrapper .field input,
.shippingWrapper .field .dropdown {
    width: 97%;
    background: #ffffff;
    border: none;
    height: 38px;
    min-height: 38px;
    border-radius: 5px;
    color: #000;
    border: 1px solid #ccc !important
}

.shippingWrapper .field label {
    font-weight: 500;
}

.defaultAddCheck {
    width: 100%;
    float: left;
    margin-top: 2%;
}

.defaultAddCheck input {
    margin-right: 1%;
    width: 20px;
    height: 20px;
}

.defaultAddCheck label {
    font-weight: 400;
    margin-bottom: 0;
    margin: -7px auto 0;
    transform: translateY(-4px);
    width:90%;
}

.backToProductDetails,
.backToShipping {
    width: 40%;
    /* background: #307FE2; */
    color: #000;
    /* page-break-after: auto; */
    padding: 7px 0px;
    border-radius: 5px;
    font-weight: 500;
    margin-right: 5%;
    border: 1px solid #ccc;
}

.confirmShipping,
.confirmReview {
    width: 55%;
    background: #307FE2;
    color: #fff;
    padding: 7px 0px;
    border-radius: 5px;
    font-weight: 500;
}

.reviewLineItem:nth-child(1) {
    width: 30%;
    float: left;
}

.reviewLineItem:nth-child(3) {
    width: 15%;
    margin-right: 5%;
    float: left;
}

.reviewLineItem:nth-child(2) {
    width: 5%;
    float: left;
    text-align: center;
    margin-right: 5%;
}

.reviewOuterWrapper {
    position: relative;
    width: 100%;
    float: left;
}

.reviewWrapper {
    width: 90%;
    float: left;
    border-radius: 5px;
    padding: 12px 15px;
    margin-bottom: 2%;
    border: 1px solid #ccc;
}

.reviewLineItem:nth-child(4) {
    width: 40%;
    float: left;
}

.reviewLineItem h5 {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-bottom: 7px;
}

.reviewLineItem span {
    font-size: 15px;
    font-weight: 500;
    color: rgba(38, 37, 44, 0.6);
    word-break: break-word;
}

.reviewLineItem span.issue_name {
    color: #000;
}

.reviewTicketWrapper {
    width: 65%;
    float: left;
    margin-right: 5%;
}

.shippingAddWrapper {
    width: 30%;
    float: left;
    border: 1px solid rgba(38, 37, 44, 0.1);
    padding: 2%;
    margin-top: 2%;
    border-radius: 5px;
}

.shippingAddWrapper h5 {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgba(38, 37, 44, 0.1);
    margin-bottom: 2%;
    padding-bottom: 2%;
}

.rtl_account.crProfile .modal-dialog .mo {
    background: #fff;
}

.rtl_account.crProfile .modal-dialog .mo .header {
    border-top-left-radius: 20px;
    background: #fff;
}

.rtl_account.crProfile .list2 span p {
    color: #000;
}

.rtl_account.crProfile .log {
    margin-left: 61px;
}

.rtl_account.crProfile .nav.dashboard {
    margin-top: 1%;
}

.rtl_dispatch_inward {
    margin-top: 2%;
}

.reviewRemove,
.editLineItem {
    width: 10%;
    float: right;
    text-align: right;
    top: 37%;
    position: absolute;
    right: 0;
}

.editLineItem {
    right: 5%;
}

.reviewRemove .fa-times,
.editLineItem .fa-pencil-alt {
    color: #ccc;
}

.edit-complaint h1 {
    font-weight: 500;
    width: 90%;
    float: left;
}

.closeEditProduct {
    width: 10%;
    float: left;
    text-align: right;
}

.editFormWrapper,
.editFormWrapper .row {
    width: 100%;
    float: left;
    margin: 0 auto;
}

.editFormWrapper .row {
    margin-bottom: 6%;
    padding-left: 0;
}

.editFormWrapper .row .col-sm-5 {
    padding-left: 0;
}

.editFormWrapper .row .col-sm-5.purchase_date_wrapper {
    padding-left: 15px;
    padding-right: 0;
}

.editFormWrapper .row .col-sm-2 {}

.editFormWrapper {
    margin-top: 5%;
}

.editFormWrapper label {
    width: 100%;
    float: left;
    font-weight: 500;
}

.editFormWrapper .css-1s2u09g-control,
.editFormWrapper .itemQuantity,
.editFormWrapper .date-complaint-rtl,
.editFormWrapper .problemDescription {
    background: #f7f7f7;
    border: none;
    /* min-height: 45px; */
    height: 45px;
    float: left;
}

.editFormWrapper .itemQuantity {
    width: 100%;
    float: left;
    padding: 0 17%;
}

.editFormWrapper .date-complaint-rtl,
.editFormWrapper .select-issue-rtl,
.editFormWrapper .select-category-rtl,
.editFormWrapper .css-1s2u09g-control,
.editFormWrapper .problemDescription,
.edit_complaint_review,
.save_wrapper {
    width: 100%;
    float: left;
}

.editFormWrapper .problemDescription {
    height: 75px;
    min-height: 75px;
    padding: 2%;
}

.edit_complaint_review {
    margin-bottom: 2%;
}

.edit_complaint_review p {
    position: relative;
    font-weight: 500;
}

.edit_complaint_review p::after {
    content: "";
    position: absolute;
    display: block;
    background: #ccc;
    width: 80%;
    height: 0.5px;
    top: 13px;
    left: 20%;
}

.save_wrapper .saveLineItem {
    width: 22%;
    background: #307FE2;
    color: #fff;
    padding: 7px 0px;
    border-radius: 5px;
    font-weight: 500;
}

.select-category-rtl .css-ackcql {
    color: #000 !important;
}

.productDetailsWrapperInside .css-vwja0k {
    color: #000 !important
}

.loaderBeforeFetchTicket {
    margin: 0 auto;
    margin-top: 45%;
}

.wrapper .problemDescription
{
    border:1px solid #ccc;
    border-radius: 5px;
}