ul#menuBar {
  list-style-type: none;
  margin-top: 0;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}

.noResult {
  color: #fff
}

.select-warranty-prod.select-product-css,
.select-product-css.select-channel-warranty {
  max-height: 100%;
}

/* .select-warranty-prod.select-prod-comp .css-26l3qy-menu,
.select-product-css.select-channel-warranty .css-26l3qy-menu {
  margin-top: 12%;
  position: relative;
} */

.select-warranty-prod.select-product-css .css-1s2u09g-control,
.select-warranty-prod.select-product-css .css-1pahdxg-control,
.select-product-css.select-channel-warranty .css-1s2u09g-control,
.select-product-css.select-channel-warranty .css-1pahdxg-control {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

/* #nd-box.prodSelection {
  height: auto;
  margin-bottom: 0;
} */

.dropdown-wrap-problem {
  max-height: 100%;
  width: 240px;
  margin-right:3%;
}

/* .select-warranty-prod.select-prod-comp .css-g1d714-ValueContainer {
  margin-top: 5px;
} */

/* .select-prod-comp .css-1s2u09g-control{
  height: 100% !important;
    min-height: 100% !important;
    max-height: 100%;
    display: inline-block;
} */

/*  */
.notifyReport {
  height: 40px;
  background-color: #df870e;
  border-radius: 24px;
  width: 21%;
  margin-right: 1px;
  float: right;
  margin-top: 3%;
}

.bbtn {
  color: white;
  font-weight: 400;
}

.ttxxtt {
  color: white;
  font-weight: 400;
}

.downloadservicecenter {
  float: left;
  margin-top: 3%;
  width: 15%;
}

/* .downloadservicecenter button{
  width:90%;
  float:left;
  margin: 0 auto;
  padding: 1% 0;
    font-weight: 500;
}  */
/* input[type="username"] {
  width: 300px;
}
input[type="username"],.list input {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  line-height: 1;
}

input {
  height: 34px;
  width: 215px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background: transparent;
  margin-left: -15px;
  padding-left: 3%;
  font-size: 14px;
  font-weight: 500;
} */

#datebox {
  float: left;
  width: 195px;
  height: 32px;
  margin-top: 2%;
  margin-right: 15px;
  margin-bottom: 3%;
  margin-left: 15px;
}

/*
.select-prod-comp h6 {
  color: #999999;
  font-weight: 300;
  font-size: 14px;
  margin-left: 12px
} 
.select-prod-comp .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color:rgb(255, 255, 255) !important;
}
*/

.filterwrapper{
  display: flex;
  margin-top: 1%;
}

.date12 .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle{
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-radius: 20px;
  z-index: 0;
  height: 36px;
}

.date12 .rs-picker-toggle-wrapper {
  height: 38px;
}

.date12 .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  margin-top: 2px;
}

.date12 .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  margin-top: 2px;
}

/* .list input::placeholder {
  color: #fff;
  background: none;
} */

.dropdown-wrap-problem h6{
  color: #999999;
  font-size: 14px;
}

.prodSelection {

  margin-right: 20px
}

#datebox h6{
  color: #999999;
  font-size: 14px;
}
.select-prod-comp .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color:rgb(255, 255, 255) !important;
}

.date12 .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(224, 224, 224) !important;
}

#datebox .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(224, 224, 224) !important;
}
