/* .active {
  margin-left: 20px;
  width: 74px;
  height: 21px;
  background: #4AC0B0 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  color: white;
  font-size: 12px;
  border: navajowhite;
}

.box h6 {
  top: 306px;
  left: 232px;
  height: 19px;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
}

.box {
  margin-top: -10px;
}

.box span {
  top: 335px;
  left: 232px;
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box p {
  top: 366px;
  margin-top: 10px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.box2 {
  margin-left: 640px;
  margin-top: -87px;
}

.box2 h6 {
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box2 span {
  height: 21px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.box2 p {
  margin-top: 12px;
  height: 16px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.box3 {
  margin-left: 860px;
  margin-top: -100px;
  color: #eb951f;
}

.box3 i {
  padding: 10px;
}

.box3 p {
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  margin-left: 110px;
  margin-top: 30px;
}

.pages {
  /* display:none; */
  /* margin-top: 10px;
  float: left;
} */

/* .pages p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-left: 140px;
  margin-top: -22px;
}

.pages span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;

}

.pages button {
  margin-left: 20px;
  color: black;
  width: 56px;
  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.pages1 {
  /* display: none; */
  /* float: left;
  padding-left: 33px;
} */

/* .pages1 button {
  color: black;
  border-radius: 5px;
  height: 33px;
  width: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
} */

/* .pages1 i {
  padding: 15px;
 
  opacity: 1;
}

.pages1 p {

  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.footer {
  margin-top: 40px;
  margin-left: 220px;
} */



.div1 {
  padding: 20px;
  margin-top: 20px;
  width: 40%;
  height: 100px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

.div1 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div2 {
  margin-top: 10px;
  padding: 20px;
  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div2 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -32px;
  margin-left: 300px;
  font-weight: 500;
}

.div3 {
  margin-top: 10px;
  padding: 20px;

  width: 40%;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 1;
}

.div3 .box h1 {
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 300px;
  margin-top: -32px;
  font-weight: 500;
}

.div4 {
  padding: 20px;
  float: right;
  width: 59%;
  margin-top: -320px;
  height: 504px;
  background: #F2FFFD 0% 0% no-repeat padding-box;
  border: 1px solid #4AC0B0;
  border-radius: 10px;
  opacity: 1;
}

ul#menu2 {

  list-style-type: none;
  margin-top: 30px;
  overflow: hidden;
  background: none;
  border-bottom: 1px solid grey;
}

ul#menu2 li {
  float: left;
}

ul#menu2 li a {
  display: block;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-family: Roboto;
  letter-spacing: 0.01px;
  color: black;
  opacity: 1;
}

ul#menu2 li a:hover {
  color: #eb951f;
  border-bottom: 1px solid #eb951f;
}

.div4 .box p {
  margin-left: 300px;
  margin-top: -75px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
}

.div4 .box span {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.div4 .box .bt button {
  width: 107px;
  margin-left: 110px;
  border-radius: 50px;
  border: 1px solid black;
}

.div4 .box .bt button i {
  margin: 5px;
}

.div5 {
  float: left;
}

.div5 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 {
  float: right;
}

.div6 h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.div6 p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 220px;
  margin-top: -28px;
}

.div6 p i {
  padding-right: 8px;
}

.div6 img {
  width: 340px;
  height: 280px;
  margin-top: -5px;
}  */


.list {
  margin-top: 32px;
 
  padding-right: 30px;

}

.list i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
}

.list input {
  height: 34px;
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.list .form-row.mg-left input {
  margin-left: 0;
  width: 100%;
}

/* .list .form-row.mg-left input:focus{
  border: none !important;
  } */
.list button {
  height: 30px;
  float: right;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  width: 130px
}

.colrow {
  overflow-y: scroll;
  margin-top: 20px;
  height: 300px;
  /* margin-left: 240px; */
}

.row {

  margin-top: 10px;
}

.colrow .row .col-sm {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}

.colrow .row .col p {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  margin-left: 40px;
}

.colrow .row .col span {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.colrow .row .col h6 {
  margin-left: 120px;
  color: #eb951f;
  font-size: 14px;
  margin-top: -19px;

}

.colrow .row .col .dropdown {
  margin-top: -40px;
  margin-left: 70px;
  background: none;
}

.dropdown-menu .dropdown-item i {
  padding: 12px;
}

.w-25 {
  background-color: #eee;
  margin-left: 200px;
}

.t1 {
  font-size: 32px;
  font: normal normal medium 32px/43px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 320px;
}

.searchbar {
  border: none;
  margin-top: 32px;
  padding-right: 30px;
}

.searchbar input {
  height: 34px;
  width: 250px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  background-color: #eee;
}

.cols {
  font-size: 16px;
  font-weight: 800;
  margin-left: 30px;
  width: 350px;
}

.cols .row .col p {
  font-size: 14px;
  font-weight: 400;
}

.cols .row .col span {
  font-size: 14px;
  font-weight: 100;
}

.right {
  position: absolute;
  margin-top: -170px;
  width: 75%;
  background: white;
  margin-left: -295px;
}

.label {
  margin-top: 30px;
  margin-left: 40px;
}

.label p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.label input {
  width: 300px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.labels1 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labels1 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.labels2 {
  margin-top: 30px;
  margin-left: 40px;
}

.labels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labels2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;

}

.la3 {
  margin-left: 400px;
  margin-top: -265px;
}

.la3 i {
  margin-left: -30px;
}

.labeel2 {
  margin-left: 400px;
  margin-top: -262px;
}

.labeel2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeel2 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeel2 ::placeholder {
  color: black;
}

.la3 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.la3 input {
  margin-top: -20px;
  width: 300px;
  height: 53px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.la3 ::placeholder {
  color: black;
}

.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;

}

.labl1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labl1 i {
  margin-left: -30px;
}

.labl1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl1 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels1 {
  margin-left: 400px;
  margin-top: 30px;
}

.labeels1 i {
  margin-left: -30px;
}

.labeels1 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels1 select {
  margin-top: -20px;
  width: 300px;
  height: 60px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labl2 {
  margin-left: 400px;
  margin-top: 30px
}

.labl2 i {
  margin-left: -30px;
}

.labl2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labl2 select {
  margin-top: -20px;
  width: 300px;
  height: 57px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.labeels2 ::placeholder {
  color: black;
}

.labeels2 {
  margin-left: 400px;
  margin-top: 30px
}

.labeels2 i {
  margin-left: -30px;
}

.labeels2 p {
  text-align: left;
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.labeels2 select {
  margin-top: -20px;
  width: 300px;
  height: 50px;
  color: black;
  border: none;
  border-bottom: 1px solid grey;
}

.sign i {
  color: #eb951f;
  margin-left: 320px;
  margin-top: 40px;

}

.addbtn {
  margin-top: 350px;
}

.addbtn button {
  height: 30px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
  float: left;
  margin-left: 6.5%;
  width: 130px
}

.fbox {
  margin-left: 220px;
  margin-top: 30px;
}

.fbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.sbox .dropdown select,
.rbox .dropdown select {
  background: #000;
}

.sbox {
  margin-top: -55px;
  margin-left: 450px
}

.sbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.sbox select,
.rbox select {
  width: 195px;
  height: 32px;
  color: #fff;
  border: 1px solid #4B384C;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
  font-size: 14px;
}

.nbox {
  margin-top: -55px;
  margin-left: 680px
}

.nbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.nbox select {
  width: 195px;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #4B384C;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.actice2 {
  color: #eb951f !important;
  border-bottom: 1px solid #eb951f;
}

.rbox {
  margin-left: 700px;
  margin-top: -85px;
}

.rbox span {
  text-align: left;
  font: normal normal medium 24px/32px Roboto;
  letter-spacing: 0px;
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

.rbox span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.rbox button {
  width: 195px;
  height: 32px;
  background: #ECEAED 0% 0% no-repeat padding-box;
  border: 1px solid #4B384C;
  border-radius: 100px;
  opacity: 1;
  padding: 5px;
}

.rbox input {
  margin-left: 20px;
  width: 145px;
  border: none;
}

.rbox i {
  margin-left: 100px;
}

.modd {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 450px;
  margin-left: 92%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  height: 600px;
  margin-top: 55px;
}

.modc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 760px;
  margin-left: 31%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  height: 600px;
  margin-top: 0;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.c input {
  width: 18px;
  height: 18px;
  background: #FA9F22 0% 0% no-repeat padding-box;
  opacity: 1;
}

.c1 button {
  color: #eb951f;

  height: 33px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  opacity: 1;
}

.row {
  margin-right: 0px !important;
}

.customrow {
  border-bottom: 1px solid #595959;
  width: 98%;
}

.a {
  color: #000000 !important;
}

.rightclass {
  float: right !important;
}

.moc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 760px;
  margin-left: 160px;
  pointer-events: auto;
  background-color: #121212;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  height: auto;
  margin-top: 55px;
}

.Ap {
  margin-left: 40px;
  margin-top: 30px;
}

.Ap span {
  margin-left: -210px;
  color: red;
}

.Ap1 {
  margin-left: 400px;
  margin-top: -35px;
}

.Ap1 span {
  margin-left: -210px;
  color: red;
}

.Ap2 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap2 span {
  margin-left: -190px;
  color: red;
}

.Ap2 i {
  margin-left: 150px;
}

.Ap3 {
  margin-left: 400px;
  margin-top: -35px;
}

.Ap3 span {
  margin-left: -220px;
  color: red;
}

.Ap4 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap4 span {
  margin-left: -170px;
  color: red;
}

.Ap5 {
  margin-left: 400px;
  margin-top: -35px;
}

.Ap5 span {
  margin-left: -210px;
  color: red;
}

.Ap6 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap6 span {
  margin-left: -235px;
  color: red;
}

.Ap7 {
  margin-left: 400px;
  margin-top: -35px;
}

.Ap8 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap9 {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap9a {
  margin-left: 40px;
  margin-top: 50px;
}

.Ap10 {
  margin-left: 400px;
  margin-top: -452px;
}

.Ap10a {
  margin-left: 400px;
  margin-top: 50px;
}

.Ap11 {
  margin-top: 120px;
  padding-right: 590px;
}

.Ep {
  margin-top: 30px;
  margin-left: 40px;
}

.Ep h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep h6 span {
  color: red
}

.Ep input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep input::placeholder {
  color: black;
}

.Ep1 {
  margin-left: 400px;
  margin-top: -47px;
}

.Ep1 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep1 h6 span {
  color: red
}

.Ep1 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep1 input::placeholder {
  color: black;
}

.Ep2 {
  margin-top: 30px;
  margin-left: 40px;
}

.Ep2 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep2 h6 span {
  color: red
}

.Ep2 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep2 input::placeholder {
  color: black;
}

.Ep2 i {
  margin-left: -20px;
}

.Ep3 {
  margin-left: 400px;
  margin-top: -47px;
}

.Ep3 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep3 h6 span {
  color: red
}

.Ep3 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep3 input::placeholder {
  color: black;
}

.Ep4 {
  margin-top: 30px;
  margin-left: 40px;
}

.Ep4 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep4 h6 span {
  color: red
}

.Ep4 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep4 input::placeholder {
  color: black;
}

.Ep5 {
  margin-left: 400px;
  margin-top: -47px;
}

.Ep5 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep5 h6 span {
  color: red
}

.Ep5 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep5 input::placeholder {
  color: black;
}

.Ep6 {
  margin-top: 30px;
  margin-left: 40px;
}

.Ep6 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep6 h6 span {
  color: red
}

.Ep6 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep6 input::placeholder {
  color: black;
}

.Ep7 {
  margin-left: 400px;
  margin-top: -47px;
}

.Ep7 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep7 h6 span {
  color: red
}

.Ep7 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep7 input::placeholder {
  color: black;
}

.Ep8 {
  margin-top: 30px;
  margin-left: 40px;
}

.Ep8 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep8 h6 span {
  color: red
}

.Ep8 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep8 input::placeholder {
  color: black;
}

.Ep9a {
  margin-left: 40px;
  margin-top: 40px;
}

.Ep9a h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep9a h6 span {
  color: red
}

.Ep9a input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep9a input::placeholder {
  color: black;
}

.Ep9 {
  margin-left: 40px;
  margin-top: 40px;
}

.Ep9 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep9 h6 span {
  color: red
}

.Ep9 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep9 input::placeholder {
  color: black;
}

.Ep10a {
  margin-left: 400px;
  margin-top: 40px;
}

.Ep10a h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep10a h6 span {
  color: red
}

.Ep10a input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep10a input::placeholder {
  color: black;
}

.Ep10 {
  margin-left: 400px;
  margin-top: -488px;
}

.Ep10 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Ep10 h6 span {
  color: red
}

.Ep10 input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Ep10 input::placeholder {
  color: black;
}

.Ep11 {
  margin-left: 40px;
}

.Ep11 button {
  height: 32px;
  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}

/* add group */
.Addgroup {
  margin-top: 32px;
  margin-left: 220px;
  padding-right: 30px;
}

.Addgroup i {
  height: 14px;
  color: #828282 0% 0% no-repeat padding-box;
  opacity: 1;
}

.Addgroup input {
  height: 34px;
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.Addgroup button {

  width: 180px;
  height: 40px;
  float: right;
  background: #FA9F22 0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  border: none;
  color: white;
}

.rows {
  margin-top: -440px;
}

.rowcols {
  margin-left: 940px;
}

.Apg {
  margin-left: 40px;
  margin-top: 30px;
}

.Apg span {
  margin-left: -165px;
  color: red;
}

.Apg1 {
  padding-right: 530px;
  margin-top: 400px;
}

.Epg {
  margin-top: 30px;
  margin-left: 40px;
}

.Epg h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Epg h6 span {
  color: red
}

.Epg input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Epg input::placeholder {
  color: black;
}

.Epg1 {
  margin-left: 40px;
  margin-top: 400px;
}

.Epg1 button {
  height: 32px;
  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}

.rc {
  margin-left: 300px;
  margin-top: -500px;
}

.Amg {
  margin-left: 40px;
  margin-top: 30px;
}

.Amg span {
  color: red;
  margin-left: -200px;
}

.Amg i {
  margin-left: 160px;

}

.Amg1 {
  margin-left: 40px;
  margin-top: 30px;
}

.Amg1 input {
  width: 600px;
}

.Amg2 {
  margin-left: 40px;
  margin-top: 30px;
}

.Amg2 input {
  width: 600px;
}

.Amg3 {
  padding-right: 530px;
  margin-top: 200px;
}

.Emg {
  margin-top: 30px;
  margin-left: 40px;
}

.Emg input {
  width: 300px;
  border: none;
  border-bottom: 1px solid grey;
}

.Emg i {
  margin-left: -30px;
}

.Emg input::placeholder {
  color: black;
}

.Emg span {
  color: red;
}

.Emg h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Emg1 {
  margin-left: 40px;
  margin-top: 30px;
}

.Emg1 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Emg1 input {
  width: 600px;
  border: none;
  border-bottom: 1px solid grey;
}

.Emg1 input::placeholder {
  color: black;
}

.Emg2 {
  margin-left: 40px;
  margin-top: 30px;
}

.Emg2 h6 {
  font: normal normal normal 10px/13px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: grey;
}

.Emg2 input {
  width: 600px;
  border: none;
  border-bottom: 1px solid grey;
}

.Emg2 span {
  color: black;
}

.Emg3 {
  margin-left: 40px;
  margin-top: 200px;
}

.Emg3 button {
  height: 32px;
  width: 150px;
  background-color: #eb951f;
  border-radius: 50px;
  color: white;
  border: none;
  font-size: 12px;
}

.mo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 268px;
  margin-left: auto;
  margin-right: 0;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 20px;
  outline: 0;
  height: 161px;
  margin-top: 55px;
}

.menucolor {
  color: #FA9F22 !important;
  font-weight: 600 !important;
}

.noactive {
  color: #828282 !important;
}

.lbtn {
  display: inline-block;
  margin-right: 5%;
  width: 17%;
}

.dbtn {
  display: inline-block;
  width: 17%;
}

.ebtn {
  /* margin-left: 240px; */
  background-color: black;
  margin-top: 30px;
  height: 80px;
  width: 32%;
  border-radius: 20px;
}

.ebtn1 {
  padding: 15px;
}

.ebtn1 h6 {
  color: lightgray;
}

.ebtn1 p {
  font-size: 18px;
  color: white;
}

.ebtn2 {
  color: lightgray;
  margin-top: -100px;
  margin-left: 140px;
}

.ebtn3 {
  color: white;
  margin-left: 140px;
}

.ebtns {
  margin-left: 0;
  float: right;
}

.ebtns button {
  color: white;
  background-color: #FA9F22;
  border: none;
  border-radius: 30px;
  height: 45px;
  width: 110px;
  font-size: 16px;
}

ul#menu li {
  margin-right: 20px;
}

.sidebar-leaderboard {
  margin-top: 0 !important;
}

.actice2-border {
  border-bottom: 2px solid #eb951f;
}

.nav-highligh {
  background: #FA9F221A 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.content-leaderboard {
  border-top: 1px solid #595959;
  margin-top: 6%;
  width: 83%;
  padding: 2%;
  float: right;
  border-left: 1px solid #595959;
  border-top-left-radius: 10px;
  padding-right: 3%;
}

.content-leaderboard h3 {
  line-height: 1;
  font-weight: 900;
  color: #fff;
  font-family: 'Roboto';
}

.dropdown-bar h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.content-leaderboard .dropdown-wrap {
  padding: 2% 0 2% 0 !important;
}

.content-leaderboard .dropdown-wrap .report_download.downloadReport {
  border: none;
}

.content-leaderboard .dropdown-wrap .report_download.downloadReport.edit-user-btn {
  position: relative;
}

.content-leaderboard .dropdown-wrap.leaderBoard {
  display: inline-flex;
  width: 100%;
  float: left;
  gap: 5%;
}

.summary-wrap {
  background: #000;
  padding: 15px 10px;
  border-radius: 15px;
  display: inline-block;
  width: 40%;
}

.summary-wrap p {
  font: normal normal 600 14px/19px Roboto;
  color: #828282;
  font-weight: 500;
  margin-bottom: 5px;
}

.summary-wrap h3 {
  margin-bottom: 0;
}

.summary-wrap .total {
  border-right: 1px solid #828282;
  padding-left: 5%;
  display: inline-block;
  width: 33.3%;
}

.summary-wrap .table-wrap {
  display: inline-flex;
  width: 66.6%;
  padding: 0 15px;
  vertical-align: bottom;
}

.summary-wrap .table-wrap table {
  width: 100%;
}

.summary-wrap .table-wrap table thead th {
  font: normal normal normal 12px/17px Roboto;
  color: #828282;
  padding-left: 5%;
}

.summary-wrap .table-wrap table tbody td {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5%;
}

.export-button {
  width: 60%;
  display: inline-block;
  vertical-align: bottom;
}

.main-table {
  padding: 2% 0 1.5% 0;
}

.main-table table {
  width: 100%;
}

.main-table table tbody {
  display: block;
  height: 250px;
  overflow: auto;
}

.main-table table thead,
.main-table table thead tr,
.main-table table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.main-table table thead th {
  letter-spacing: 0.5px;
  font-size: 16px;
  color: #fff;
  padding: 0;
  font-weight: 700;
  padding: 2% 0;
}

.main-table table thead th:nth-child(1),
.main-table table tbody td:nth-child(1) {
  width: 20%;
  /* max-width: 19% !important; */
}

/* .main-table table thead th:nth-child(1){
  width: 17%;
  } */
.main-table table thead th:nth-child(2),
.main-table table tbody td:nth-child(2) {
  width: 10%;
}

.main-table table thead th:nth-child(3),
.main-table table tbody td:nth-child(3) {
  width: 10%;
}

/* .main-table table thead th:nth-child(2), .main-table table thead th:nth-child(3){
  width: 9%;
  } */
.main-table table thead th:nth-child(4),
.main-table table tbody td:nth-child(4) {
  width: 10%;
}

.main-table table thead th:nth-child(5),
.main-table table tbody td:nth-child(5) {
  width: 10%;
}

.main-table table thead th:nth-child(6),
.main-table table tbody td:nth-child(6) {
  width: 40%;
}

.main-table table tbody td {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding: 2% 0;
}

.main-table table tbody td:last-child {
  padding-right: 1%;
}

.main-table table tbody tr {
  border-bottom: 1px solid #8282825e;
}

.main-table table thead tr {
  border-bottom: 1px solid #8282825e;
}

.main-table table thead {
  width: 97%;
}

.progress-wrap p {
  margin-bottom: 5px;
  color: #FA9F22;
  font-size: 16px;
  font-weight: 500;
}

.progress-wrap .progress {
  width: 100%;
  height: 10px;
  position: relative;
  background: #FA9F221A !important;
  border-radius: 20px !important;
}

.progress .progress_inner {
  position: absolute;
  width: 75%;
  height: 100%;
  background-color: #FA9F22 !important;
  background: -webkit-linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background: linear-gradient(to left, #FA9F22 0%, #FA9F22 30%, #FA9F22 45%, #FA9F22 50%, #FA9F22 60%, #FA9F22 70%, #FA9F22 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 5.25s;
  animation-duration: 5.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
  border-radius: 10px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: 100% 0
  }

  100% {
    background-position: -100% 0
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0
  }

  100% {
    background-position: -100% 0
  } 
}



/* Select search css */
.content-leaderboard .problem-product-courier {
  width: 100%;
  padding: 0 8px 0 0;
  background: #000;
  color: #fff;
  font-size: 14px;
  float: left;
  border-radius: 21px;
}

.content-leaderboard .css-1s2u09g-control,
.content-leaderboard .css-1pahdxg-control,
.content-leaderboard .css-1s2u09g-control,
.content-leaderboard .css-1pahdxg-control {
  width: 100%;
  height: 32px;
  padding: 0 5px;
  border: 1px solid #595959;
  border-radius: 100px;
  opacity: 1;
  color: #fff;
  background: #000;
  font-size: 14px;
  max-height: 32px;
  min-height: 32px;
}

.content-leaderboard .css-1s2u09g-control:hover,
.content-leaderboard .css-1pahdxg-control:hover,
.content-leaderboard .css-1s2u09g-control:hover,
.content-leaderboard .css-1pahdxg-control:hover {
  border: 1px solid #595959;
}

.content-leaderboard .css-1s2u09g-control .css-319lph-ValueContainer,
.content-leaderboard .css-319lph-ValueContainer {
  padding: 0 8px;
}

.content-leaderboard .css-1s2u09g-control .css-319lph-ValueContainer .css-14el2xx-placeholder,
.content-leaderboard .css-14el2xx-placeholder {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.content-leaderboard .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-leaderboard .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-leaderboard .css-1pndypt-Input input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-leaderboard .css-1pndypt-Input {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.content-leaderboard .css-1s2u09g-control .css-319lph-ValueContainer .css-6j8wv5-Input,
.content-leaderboard .css-6j8wv5-Input {
  margin: 0;
}

.content-leaderboard .css-1pahdxg-control .css-319lph-ValueContainer .css-6j8wv5-Input {
  color: #ffffff;
  font: normal normal medium 12px/16px Roboto;
  font-family: 'Roboto';
  font-weight: 500;
}

.content-leaderboard .css-1pahdxg-control,
.content-leaderboard .css-1pahdxg-control {
  box-shadow: none;
}

.content-leaderboard .css-6j8wv5-Input,
.content-leaderboard .css-6j8wv5-Input {
  color: #fff;
  font-weight: 500;
}

.content-leaderboard .css-319lph-ValueContainer {
  padding: 0 8px;
}

.content-leaderboard .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

/* .right-grid .dropdown-wrap-courier .css-tlfecz-indicatorContainer{
  height: 28px;
  color: #fff;
  } */
.content-leaderboard .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.content-leaderboard .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  height: 28px;
  color: #fff;
}

.content-leaderboard .css-6j8wv5-Input input,
.content-leaderboard .css-6j8wv5-Input input {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;

}

.content-leaderboard .css-319lph-ValueContainer .css-14el2xx-placeholder,
.content-leaderboard .css-14el2xx-placeholder {
  color: #ffffff !important;
  font: normal normal normal 14px/19px Roboto !important;
  font-size: 14px;

}

.content-leaderboard .css-26l3qy-menu {
  background: #000;
  color: #fff;
  border-radius: 10px;
}

.content-leaderboard .css-1gtu0rj-indicatorContainer {
  color: #ffffff !important;
}

.content-leaderboard .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
.content-leaderboard .css-1n7v3ny-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
  background: #FA9F221A 0% 0% no-repeat padding-box;
  color: #FA9F22;
}

.content-leaderboard .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:active,
.content-leaderboard .css-1n7v3ny-option:active {
  background: #FA9F221A 0% 0% no-repeat padding-box;
}

.content-leaderboard .css-yt9ioa-option {
  padding: 5px 12px;
  font: normal normal normal 14px/19px Roboto;
}

.css-1gtu0rj-indicatorContainer svg {
  color: #fff !important;
}

.date-leaderboard {
  height: 32px;
  max-height: 32px;
  min-height: 32px;
}

.date-manifest {
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  margin-top: 20px;
}

/* Report Page Main Table */

.report-main-table .main-table table thead th:nth-child(1),
.report-main-table .main-table table tbody td:nth-child(1) {
  width: auto;
}

.report-main-table .main-table table thead th:nth-child(2),
.report-main-table .main-table table tbody td:nth-child(2) {
  width: auto;
}

.report-main-table .main-table table thead th:nth-child(3),
.report-main-table .main-table table tbody td:nth-child(3) {
  width: auto;
}

.report-main-table .main-table table thead th:nth-child(4),
.report-main-table .main-table table tbody td:nth-child(4) {
  width: auto;
}

.report-main-table .main-table table thead th:nth-child(5),
.report-main-table .main-table table tbody td:nth-child(5) {
  width: auto;
}

.report-main-table .main-table table thead th:nth-child(6),
.report-main-table .main-table table tbody td:nth-child(6) {
  width: auto;
}

.report-main-table .main-table table tbody {
  display: block;
  height: 330px;
  overflow: auto;
}

.content-leaderboard .loader {
  position: inherit;
  left: 35%;
  /* margin-top: 40%; */
  margin-top: 7%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.col-sm-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 19.3% !important;
}

.content-leaderboard .css-1s2u09g-control,
.content-leaderboard .css-1pahdxg-control {
  display: flex;
  float: left;
  padding: 1%;
}

.css-g1d714-ValueContainer {
  display: inline-flex;
  float: left;
}

.content-leaderboard .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer,
.css-ackcql {
  float: right;
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  color: red !important;
}

.fetchChannelList {
  color: #fff;
}

.select-product-css .content-leaderboard .css-6j8wv5-Input {
  display: none;
}

.customReportWrapper {
  display: flex;
  margin-top: 3%;
}

.customReportWrapper1 {
  width: 100%;
  float: left;
  margin: 4% 0;
}

.channel-list h6,
.product-name-wrapper h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.customReportWrapper1 .product-name-wrapper {
  width: 100%;
  float: left;
}

.product-name-wrapper .css-g1d714-ValueContainer {
  padding: 6px 0;
}

.product-name-wrapper label {
  color: #fff;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
}

.product-name-wrapper .css-tlfecz-indicatorContainer {
  float: left;
}

.select-all-wrapper {
  margin-top: 6%;
  margin-left: 0;
  width: auto;
  float: left;
}

.customReportWrapper1 .select-all-wrapper {
  margin-left: 0;
  margin-top: 22px;
  width: 100%;
}

.select-all-wrapper #selectAll,
.select-all-wrapper #selectAllProducts {
  float: left;
}

.select-all-wrapper label {
  float: left;
  margin-left: 0%;
  transform: translate(5px, -2px);
  font-style: italic;
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
  width:auto;
}

.channel-list .css-b62m3t-container {
  float: left;
  width: 100%;
}

.channel-list .css-1s2u09g-control,
.channel-list .css-1pahdxg-control,
.product-name-wrapper .css-1s2u09g-control,
.product-name-wrapper,
.product-name-wrapper .css-1pahdxg-control,
.category .category.css-b62m3t-container {
  transition: none;
  height: 100%;
  min-height: 36px;
  max-height: 100%;
  padding: 0;
  border-radius: 17px;
}

.category .category .css-1s2u09g-control,
.category .category .css-1pahdxg-control {
  height: auto !important;
  max-height: 100%;
  border-radius: inherit;
}

.category .category.css-b62m3t-container {
  height: auto;
}

.channel-list .css-1s2u09g-control {
  border-radius: 20px;
  padding: 0;
}

.channel-list,
.customReportWrapper .dbtn,
.product-name-wrapper,
.category {
  margin-right: 3%;
  width: 20%;
  float: left;
}

.category .label {
  margin: 0;
}

.category .label p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-bottom: 7px;
}

#product-group.category {
  width: 100%;
  padding: 0 8px 0 0;
  background: #000;
  color: #fff;
  font-size: 14px;
  border-radius: 21px;
}

.select-channel .css-g1d714-ValueContainer svg,
.select-channel .css-1hwfws3 svg.css-8mmkcg,
#product-group.category .css-g1d714-ValueContainer svg,
#product-group.category .css-xb97g8 svg {
  color: #000 !important;
}

.css-12jo7m5 {
  font-weight: 500;
}

.data-fields-wrapper {
  width: 100%;
  float: left;
  margin-bottom: 0;
  margin-top: 2%;
}

.data-fields-wrapper .data-col {
  float: left;
  width: 20%;
  margin-bottom: 1%;
}

.data-fields-wrapper .data-col label {
  color: #fff;
  margin-left: 4%;
  font-size: 14px;
}

.data-fields-wrapper .field-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.data-fields-wrapper .data-col .field-checkbox+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 3px;
}

/* .data-fields-wrapper .data-col .field-checkbox + label:after{
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  background: rgb(250, 159, 34);
  width: 2px;
  height: 2px;
  -webkit-box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
  box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  } 
  .data-fields-wrapper .data-col .field-checkbox:hover + label:before{
  background: transparent;
  }
  */

.data-fields .field-checkbox {
  opacity: 0;
  position: absolute;
  z-index: 666;
  top: 27%;
  left: 2%;
}

.data-fields .data-fields-wrapper .data-col .field-checkbox {
  position: relative;
  top: 0;
  left: 0;
}

.data-fields-wrapper .data-col .field-checkbox:before+label:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.data-fields .field-checkbox:checked+label:before {
  background: rgb(250, 159, 34);
  border: none;
}

.data-fields .field-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 9px;
  background: rgb(250, 159, 34);
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
  transform: rotate(45deg);
}

.data-fields {
  margin-top: 4%;
}

.data-fields h5 {
  font-weight: 500;
}

.data-fields p {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
}

.product-name-wrapper .css-qc6sy-singleValue,
.data-fields h5,
.data-fields p,
.custom_report_popup p {
  color: #fff;
}

.customReportWrapper .error_add,
.data-fields .error_add {
  margin-top: 0.5rem;
  margin-left: 1%;
}

.channel-list .css-xb97g8 {
  background: #e6e6e6 !important;
}

.transferButton.downloadReport {
  font-weight: 500;
  font-size: 12px;
}

.submitCustomReport {
  width: 100%;
  float: left;
}

.custom_report_popup h2#rModal_head {
  width: 100%;
  float: left;
  margin-bottom: 7%;
  font-size: 25px !important;
  text-align: center;
}

.custom_report_popup h6 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  color: #828282;
  margin-bottom: 0;
}

.custom_report_popup p {
  font-size: 14px;
}

.data-fields .error_add {
  margin-bottom: 2%;
}

.custom_report_popup .date-wrapper {
  width: 100%;
  float: left;
}

.custom_report_popup .date-wrapper:hover {
  cursor: pointer;
}

.custom_report_popup .dateRange {
  float: left;
}

.custom_report_popup .edit_date {
  font-size: 12px;
  color: #eb951f;
  float: right;
  text-align: right;
  margin-top: 5%;
  font-weight: 500;
}

.custom_report_popup #close_rModal {
  width: 45%;
  height: 36px;
  background: transparent;
  border-radius: 60px;
  opacity: 1;
  color: #828282;
  font-weight: 500;
  border: 1px solid #828282;
  font-size: 14px;
}

.download-report {
  margin-top: 10%;
}

.download-report .download_report {
  width: 45%;
  font-weight: 500;
}

.custom_report_popup .edit-date-popup {
  position: absolute;
  top: 45%;
  right: 0;
}

.select-channel .css-12jo7m5 {
  font-weight: 500;
}

.product-name-wrapper #react-select-3-listbox,
.product-name-wrapper .css-26l3qy-menu {
  margin-top: 15px;
}

.product-name-wrapper .css-b62m3t-container {
  min-width: 20%;
  float: left;
  max-width: 100%;
}

.data-fields .select-all-wrapper {
  margin: 1% 0;
}

.data-fields .select-all-wrapper .select-all-data,
.category .select-all-wrapper #selectAllCat,
.category .select-all-wrapper .category label {
  float: left;
}

.date-leaderboard.cr-datepicker {
  height: 100%;
  min-height: 33px;
  max-height: 100%;
}

.product-name-wrapper .css-26l3qy-menu ::-webkit-scrollbar {
  width: 7px;
}

.product-name-wrapper .css-26l3qy-menu ::-webkit-scrollbar-thumb {
  background: #fff;
}

#hideDiv,
.hideDiv {
  display: none !important;
}

.closeCustomPopup {
  width: 100%;
  float: left;
}

.closeCustomPopup .fa-times {
  float: right;
}

.channel-list {
  margin-right: 0;
  width: 30%;
}

.category {
  width: 30%;
}

.select-product-css .css-xb97g8:hover {
  background: #fff !important;
}

.select-product-css .css-tj5bde-Svg,
.select-product-css .css-1hwfws3 svg {
  color: #fff;
}

.select-product-css .css-tlfecz-indicatorContainer svg {
  color: #fff
}

.product-name-wrapper .css-12jo7m5 {
  font-weight: 500
}

.content-leaderboard .select-product-css .css-1s2u09g-control,
.content-leaderboard .select-product-css .css-1pahdxg-control {
  color: #fff !important;
  padding-left: 3%;
}

.sla_breached,
.priority {
  float: left;
  width: 20%;
  margin-top: 5%;
  display: inline-block;
}

.sla_breached label,
.priority label {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 4px;
  margin-left: 5%;
  float: left;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  width:auto;
}

.sla_breached input[type=checkbox],
.priority input[type=checkbox] {
  float: left;
}

.list input::placeholder {
  color: #fff;
  background: none;
}


.downloadReport .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: rgb(224, 224, 224) !important;
}
