.container{
  top: 0px;
  left: 0px;
  width: 100%;
  height: 768px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .container {
    height: auto;
    padding: 20px;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .loginImage {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .loginImage img {
    max-width: 100%;
    height: auto;
  }

  .form-controlll {
    width: 100% !important;
  }

  .login-form {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }
}
.left {
	top: 0px;
left: 0px;
width: 683px;
height: 768px;
position: fixed;
}
    
.right {
      
  top: 207px;
  left: 833px;
  width: 380px;
  height: 354px;
  float: right;
  position: fixed;
  }
  .line{
    top: 299px;
left: 833px;
width: 380px;
height: 63px;
text-align: left;
font: normal normal normal 16px/21px Roboto;
letter-spacing: 0px;
color: #828282;
opacity: 1;
margin-top: 20px;
  }
  .login{
    font-weight: 500;
    top: 207px;
  left: 833px;
  width: 80px;
  height: 43px;
  text-align: left;
  font-size: 32px;
  line-height: 43px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  }

  .rectangle-user {
    top: 290px;
    left: 833px;
    width: 380px;
    height: 36px;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #828282;
    border: none;
    border-bottom: groove;
    margin-top: 40px;
    padding-left: 8px;
    min-width: 120px; 
    /* Ensures a minimum width of 200px */
  }
  

  .loginForm::placeholder{
     background: black !important;
     color:white !important;

  }
  
  .user-icon{
    margin-left: -19px;
    cursor: pointer;
    
  }
  .rectangle-pass{
    top: 366px;
  left: 833px;
  width: 380px;
  height: 36px;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-family:Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  border:none;
  border-bottom: groove;
  margin-top:40px;
  outline: none;
  }
  .fa-eye.password-icon, .fa-eye-slash.password-icon {
    color:#fff;
  }
  .forget-pass{
    top: 412px;
  left: 1117px;
  width: 96px;
  height: 16px;
  float: right;
  font-size: 12px;
  line-height: 16px;
  font-family:Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: 10px;
  }
  .forget-pass a{
    color: #fa9f22;
  }
    .password-icon{
      margin-left: -20px;
      cursor: pointer;
    }
    .checkbox{
      top: 445px;
    left: 836px;
    width: 18px;
    height: 18px;
    background: #2D2E2D 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top:43px px;
    }
    .rem{
      margin-top: 10px;
    }
    .rememberme{
      color: #fff;
      transform: translateY(-2px);
      font-weight: 100;
      top: 442px;
    left: 865px;
    width: 108px;
    height: 21px;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    font-family:Roboto;
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 40px;
    margin-left: 11px;
    }
    .buttonLogin{
      top: 516px;
    left: 833px;
    width: 250px;
    height: 45px;
    background: #fa9f22;
    border-radius: 60px;
    opacity: 1;
    border:none;
    margin-top: 38px;
    margin-bottom: 207px;
    }
    .buttonLogin:hover{
      cursor: pointer;
      background: #FA9F22;
    }
    /* .loginText{
      top: 528px;
    left: 936px;
    width: 44px;
    height: 21px;
    margin-top: 12px;
    margin-left: 100px;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    font-family:Roboto;
    letter-spacing: 0.02px;
    color: #FFFFFF;
    opacity: 1;
    font-weight: 500;
  
    } */
    
    .create{
      font-weight: 500;
      top: 239px;
      left: 833px;
      width: 313px;
      height: 43px;
      text-align: left;
      line-height: 43px;
      font-size: 32px;
      font-family:  Roboto;
      letter-spacing: 0px;
      color: #fff;
      opacity: 1;
      }
      .password{
        background: transparent;
        top: 322px;
   left: 833px;
   width: 380px;
   height: 36px;
   opacity: 1;
   text-align: left;
   line-height: 21px;
   font-size: 16px;
   font-family:  Roboto;
   letter-spacing: 0px;
   color: #828282;
   opacity: 1;
   border:none;
   border-bottom: groove;
   margin-top: 40px;
   margin-bottom: 40px;
      } 
   .confirmpassword{
     top: 398px;
   left: 833px;
   width: 380px;
   height: 36px;
   text-align: left;
   font-size: 16px;
   line-height: 21px;
   font-family:Roboto;
   letter-spacing: 0px;
   color: #828282;
   opacity: 1;
   border:none;
   border-bottom: groove;
   margin-bottom: 50px;
   
   }
   .button{
    float:left;
    top: 484px;
  left: 833px;
  width: 250px;
  height: 45px;
  background: #fa9f22;
  border-radius: 60px;
  opacity: 1;
  border:none;
  margin-bottom: 239px;
  }
   
  .button:hover{
    cursor: pointer;
    background: #FA9F22;
  }

    .E-mail-Address {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
    }
    
    

    
    .bottom {
      width: 440px;
      position: absolute;
      bottom: 0;
    }

    .a:hover {
      text-decoration: underline;
    }

@media(max-width: 576px){

    
  
    
    .form-controlll
          {width: 100% !important ;}
    }
      
    .box1{
          padding:10px 0px 10px 35px;
          width: 100%;
          height: auto;
          overflow-x: hidden;
          box-sizing: border-box;
          }
    
    ._app{
          background-color: #41cb52;
         }  
      
    .nav1 
       {list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;}
      
    .nav2 {
        float: right;}
      
    .nav2 .nav3 {
        display: block;
        text-align: center;
        padding: 3px 18px;
        color: #707070 !important;
        text-decoration: none;
        border: 1px solid #707070;
        border-radius: 40px;
        margin-left: 20px;
        font-size: 14px;
        height: 31px;
        padding-right: 18px !important;
        padding-left: 18px !important;}
      
    .nav33 
       {display: block;
        text-align: center;
        padding: 5px ;
        color:white !important;
        text-decoration: none;
        border: 1px solid white;
        border-radius: 20px;
        margin-left: 20px;
        font-size: 14px;
        width: 197px;
        height: 34px;}
    
    .nav333
        {position: relative;
        bottom: 2px;}
    
    @media (min-width: 992px)
      {
       .navbar-expand-lg .navbar-nav .nav-link 
            {padding-right: 1.5rem;
              padding-left: 1.5rem;}
      }
    @media (max-width: 767px)
      {
        .nav2 
            {float: right;
            margin-bottom: 15px;}
      }
    
    .nav2 .nav3:hover 
        {background-color: #01de5a;
        color: #fff;
        border-color: #01de5a;}
    
 
    
    
        
    .bg-light 
          {background-color: white !important;}
    
    
    
    
    
    .signupClass
          {font-size: 15px;
            padding-left: 33px;
            margin-top: 23px;}
    
    .form-controlll {
    width: 100%;
    outline: none;
    max-width: 100%;
}
    
    .loginImage
          {margin-top: 80px;}

.login-container .rem .checkbox{

}
.loginOtp .modalHeader{
  text-align: center;
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #000;
  width: 100%;
  float: left;
  padding-bottom: 10px;
  color: #fff;

}
.loginOtp .modalHeader #rModal_head{
  color: #000;
  font-size: 18px !important;
  text-align: center;
  padding: 0;
  float: none;
}
.loginOtp .modalHeader #close_rModal{
  position: absolute;
    cursor: pointer;
    top: 40%;
    right: 10px;
    background: #fff;
}
.loginOtp .content{
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 13px;  
  border: none;
}
.loginOtp .modalHeader #close_rModal .fa-times{
color:#000;  
}
#enterMailMobile::placeholder{
background:rgb(0, 0, 0) !important;
color:#ccc !important;  
}
#InputLoginOtp::placeholder{
  background:#fff !important;color:#ccc !important;  
  }
#InputLoginOtp{
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  text-indent: 0;
  width: 90%;
  float: left;
  margin: 20px 5%;
}
.loginOtp .otp-btn-wrapper{
width:50%;float:left;
padding: 0 10% ;
}
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .loginOtp .otp-btn-wrapper {
    transform: translateX(-34px);
  }
}
.loginOtp .rended-otp-button{
background-color: #eb951f;
color: white;
border-radius: 50px;
border: none;
height: 50px;
width: 180px;
box-shadow: none;
margin: 0;
}

.container {
  top: 0px;
  left: 0px;
  width: 1366px;
  height: 768px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1
}

.container.login-container {
  display: flex;
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 auto;
  float: left;
  padding: 0;
  background-color: #000;
  height: 100vh !important;
  max-width: 100%;
}

.login-container .login_img {
  position: relative;
  height: auto;
  width: 50%;
  float: right;
  overflow: hidden;
}

.login-container .login_img img {
  float: right;
  max-width: 100%;
}

@media (max-width: 768px) {
  .login-container {
    display: block; /* Stack elements vertically */
  }

  .login-container .login_img {
    width: 100%;
    position: abso;
    bottom: 0;
    overflow: hidden; /* Prevents overflow */
  }

  .login-container .login_img img {
    width: 100%;
    height: auto; /* Adjusts height according to the image ratio */
    object-fit: contain; /* Ensures the image fits without being cut */
  }
}

.login-container .login-form {
  width: 50%;
  float: left;
  position: relative;
  height: auto;
  left: 0;
  top: 0;
  padding-left: 11%;
  margin: 0 auto;
  padding-top: 13%;
}
@media (max-width: 768px) {
  .login-container .login-form {
    height: 50%;
    transform: translateX(23%);
    padding-left: 0;
    width: 95%;
  }
}
.login-container .pass1,
.pass1 .password,
.pass1 .confirmpassword,
.login-container .pass2,
.pass2 .password,
.pass2 .confirmpassword {
  width: 100%;
  float: left;
  background: transparent;
}

.pass1 .password-icon {
  color: #999;
  margin-top: 15%;
}

.pass2 .password-icon {
  color: #999;
  margin-top: 0;
}

.login-container .logo {
  position: absolute;
  left: 5%;
  text-align: left;
  top: 5%
}

.login-container .logo img {
  width: 10%;
}

.loginForm {
  width: 63%;
}

@media (max-width: 768px) {
  .loginForm {
    transform: translateY(84px); /* Shifts the button 24px to the right */
    
  }
}

.left {
  top: 0px;
  left: 0px;
  width: 683px;
  height: 768px;
  position: fixed;
}

.right {
  top: 37%;
  left: 84%;
  width: 380px;
  height: 354px;
  float: right;
}

.line {
  top: 299px;
  left: 833px;
  width: 380px;
  height: 63px;
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: 20px;
}

.login {
  font-weight: 500;
  top: 207px;
  left: 833px;
  width: 80px;
  height: 43px;
  text-align: left;
  font-size: 32px;
  line-height: 43px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}
@media (max-width: 768px) {
  .login {
    transform: translateX(80%); /* Moves the element 50% of its own width to the right */
    /* transform: translateY(20%); */
    text-align: center;         /* Centers text within the element */
    left: 0;                    /* Resetting left to avoid conflicting with transform */
  }
}
.rectangle-user,
.rectangle-user:focus {
  background: rgb(0, 0, 0);
  top: 290px;
  left: 833px;
  width: 100%;
  height: 36px;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  border: none;
  border-bottom: groove;
  margin-top: 40px;
  padding: 0;
  outline: none;
}

.rectangle-user:-internal-autofill-selected {
  background-color: #000 !important;
}

.user-icon {
  margin-left: -19px;
  cursor: pointer;
  color: #fff;
}

.rectangle-pass {
  background: #000;
  top: 366px;
  left: 833px;
  width: 100%;
  height: 36px;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  border: none;
  border-bottom: groove;
  margin-top: 40px;
  outline: none;

}

.forget-pass {
  top: 412px;
  left: 1117px;
  width: 96px;
  height: 16px;
  float: right;
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  margin-top: 10px;
}

.forget-pass a {
  color: #fa9f22;
}

.password-icon {
  margin-left: -20px;
  cursor: pointer;
}

.checkbox {
  top: 445px;
  left: 836px;
  width: 18px;
  height: 18px;
  background: #2D2E2D 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 43px px;
}

.rem {
  margin-top: 10px;
}

.rememberme {
  color: #fff;
  transform: translateY(-2px);
  font-weight: 100;
  top: 442px;
  left: 865px;
  width: 108px;
  height: 21px;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-family: Roboto;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 40px;
  margin-left: 11px;
}

.buttonLogin {
  top: 516px;
  left: 833px;
  width: 125px;
  height: 35px;
  background: #fa9f22;
  border-radius: 60px;
  opacity: 1;
  border: none;
  margin-top: 38px;
  margin-bottom: 207px;
  margin-right: 20px;
}
@media (max-width: 768px) {
  .buttonLogin {
    transform: translateX(45px); /* Shifts the button 24px to the right */
    left: 0;                     /* Reset left to avoid conflict */
    margin-right: 0;             /* Optional: Adjust margins if needed */
  }
}

.buttonLogin:hover {
  cursor: pointer;
  background: #FA9F22;
}

.loginText {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: .02px;
  float: left;
  height: 100%;
  text-align: center !important;
  vertical-align: middle;
  padding-top: 4%;
  color: #fff;
}

.create {
  font-weight: 500;
  top: 239px;
  left: 833px;
  width: 313px;
  height: 43px;
  text-align: left;
  line-height: 43px;
  font-size: 32px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.password {
  background: transparent;
  top: 322px;
  left: 833px;
  width: 380px;
  height: 36px;
  opacity: 1;
  text-align: left;
  line-height: 21px;
  font-size: 16px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  border: none;
  border-bottom: groove;
  margin-top: 40px;
  margin-bottom: 40px;
}

.confirmpassword {
  top: 398px;
  left: 833px;
  width: 380px;
  height: 36px;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #828282;
  opacity: 1;
  border: none;
  border-bottom: groove;
  margin-bottom: 50px;

}

.button {
  float: left;
  top: 484px;
  left: 833px;
  width: 250px;
  height: 45px;
  background: #fa9f22;
  border-radius: 60px;
  opacity: 1;
  border: none;
  margin-bottom: 239px;
}

.button:hover {
  cursor: pointer;
  background: #FA9F22;
}

.E-mail-Address {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}




.bottom {
  width: 440px;
  position: absolute;
  bottom: 0;
}

.a:hover {
  text-decoration: underline;
}

@media(max-width: 576px) {




  .form-controlll {
    width: 100% !important;
  }
}

.box1 {
  padding: 10px 0px 10px 35px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

._app {
  background-color: #41cb52;
}

.nav1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav2 {
  float: right;
}

.nav2 .nav3 {
  display: block;
  text-align: center;
  padding: 3px 18px;
  color: #707070 !important;
  text-decoration: none;
  border: 1px solid #707070;
  border-radius: 40px;
  margin-left: 20px;
  font-size: 14px;
  height: 31px;
  padding-right: 18px !important;
  padding-left: 18px !important;
}

.nav33 {
  display: block;
  text-align: center;
  padding: 5px;
  color: white !important;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 20px;
  margin-left: 20px;
  font-size: 14px;
  width: 197px;
  height: 34px;
}

.nav333 {
  position: relative;
  bottom: 2px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (max-width: 767px) {
  .nav2 {
    float: right;
    margin-bottom: 15px;
  }
}

.nav2 .nav3:hover {
  background-color: #01de5a;
  color: #fff;
  border-color: #01de5a;
}





.bg-light {
  background-color: white !important;
}





.signupClass {
  font-size: 15px;
  padding-left: 33px;
  margin-top: 23px;
}

.form-controlll {
  width: 120%;
  outline: none;
}

.loginImage {
  margin-top: 80px;
}

.ReactModal__Content {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 0 auto;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 2%;
}