.complaintDetailContainer {
    background-color: #fff;
    border: 1px solid #26252C1A;
    padding: 40px;
    padding-bottom: 80px;
    font-size: 14px;
}

.complaintDetailContainer {
    border-right: 1px solid #26252C1A;
    width: 100%;
}

.customerDetailContainer {
    width: 30%;
}

.complaintNumber {
    color: #1A202D;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}

.date {
    float: right;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.details-container {
    display: flex;
    justify-content: space-between;
}

#issueResolveDropdown {
    background-color: #F7F7F7;
    border: 0.1px solid rgba(41, 41, 41, 0.352);
    border-radius: 6px;
    outline: none;
    padding: 8px 16px;
    width: 350px;
}

#issueResolveDropdown:focus {
    border: 1.5px solid #307FE2
}

.remarks {
    border: 0.1px solid rgba(41, 41, 41, 0.352);
    border-radius: 6px;
    background-color: #f7f7f7;
    padding: 8px 16px;
    outline: none;
    resize: none;
    width: 100%;
}

.loaderNew {
    padding-top: 10%;
    padding-left: 40%;
}
.productDetailContainer{
    width:85%
}