/* Mobile-specific styles for product dropdown */
@media screen and (max-width: 768px) {
  .select-prod-comp {
    width: 100%;
    margin-bottom: 15px;
    position: relative; /* Added to ensure proper dropdown positioning */
  }

  .select-prod-comp .select-product-css,
  .select-prod-comp .dropdown-wrap-problem {
    max-height: none;
    width: 100%;
    min-width: 200px;
  }

  .select-prod-comp .css-1s2u09g-control,
  .select-prod-comp .css-1pahdxg-control {
    min-height: 40px;
    border-radius: 8px;
  }

  .select-prod-comp .css-26l3qy-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto; /* Ensure proper scroll behavior */
    margin-top: 5px; /* Added to give space above dropdown */
  }

  /* Ensure proper dropdown item styling */
  .select-prod-comp .css-4ljt47-MenuList {
    background: #000;
  }

  .select-prod-comp .css-1n7v3ny-option,
  .select-prod-comp .css-9gakcf-option {
    padding: 8px 12px;
    font-size: 14px;
  }

  /* Ensure value container is properly styled */
  .select-prod-comp .css-319lph-ValueContainer {
    padding: 2px 8px;
  }

  /* Placeholder text styling */
  .select-prod-comp .css-14el2xx-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}
