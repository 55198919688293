/* Mobile-specific styles for complaints component */
@media screen and (max-width: 768px) {
  .heads {
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 60px;
  }
  .product_details1 .fifthbox {
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 60px;
  }
  /* Container that wraps the input fields, making it horizontally scrollable */
  .select-prod-comp {
    overflow-x: visible;
    white-space: normal;
    margin-bottom: 15px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }

  /* Main container styles */
  .container {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Problem description area styles */
  .product_details1 {
    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
  }

  /* Form fields container */
  .form-fields-container {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 15px;
  }

  /* Input field styles */
  .first,
  .second,
  .third {
    flex: 0 0 auto;
    min-width: 200px;  /* Ensure a minimum width for each field */
    max-width: 100%;  /* Prevents field from exceeding container width */
    margin-bottom: 15px;
  }

  /* Ensure input fields are properly spaced */
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;  /* Make sure input fields take up all available space within their container */
    margin-bottom: 10px;
  }

  /* Adjust label positioning */
  .lbl, .lbl2, .lbl3, .lbl4, .lbl5, .lbl6, .lbl7, .lbl8, .lbl9, .lbl10, .lbl11, .lbl12 {
    display: block;
    margin-bottom: 5px;
  }
}
